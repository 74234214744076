import moment from "moment";
import { useCallback, useState } from "react";
import {
  Button,
  Col,
  Container,
  ListGroup,
  Offcanvas,
  OverlayTrigger,
  Pagination,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdClose, MdDelete, MdEdit } from "react-icons/md";
import { GlobalResponseSet } from "../../../api/template";
import { AddIcon, SearchIcon } from "../../../components/icons/icons";
import TextInput, { TextInputWithRef } from "../../../components/input/input";
import Text from "../../../components/text/text";

export default function ResponsesTab({}) {
  const form = useForm<{
    responses: Array<GlobalResponseSet>;
  }>({
    defaultValues: {
      responses: Array.from({ length: 10 }).map((_, i) => ({
        id: i,
        name: "Response " + i,
        updatedAt: moment().unix(),
        responses: [
          {
            id: 0,
            text: "hi",
          },
        ],
      })),
    },
  });

  const formArrayField = useFieldArray({
    control: form.control,
    name: "responses",
  });

  const [selectedSetIndex, setSelectedSetIndex] = useState<number>(-1);

  const [showResponseOffCanvas, setShowResponseOffCanvas] = useState(false);

  const responseCanvasFormInitState = {
    id: 0,
    name: "Untitled",
    responses: [],
    updatedAt: moment().unix(),
  };
  const responseCanvasForm = useForm<GlobalResponseSet>({
    defaultValues: responseCanvasFormInitState,
  });

  const responseCanvasFieldArray = useFieldArray({
    control: responseCanvasForm.control,
    name: "responses",
  });
  const watchForm = form.watch("responses");

  const closeOffcanvas = () => {
    setShowResponseOffCanvas(false);
  };

  const [searchText, setSearchText] = useState<string>("");
  const filteredResponseSets = useCallback(() => {
    return form
      .getValues("responses")
      .filter((response) => response.name.includes(searchText));
  }, [searchText]);

  return (
    <Container>
      <Row className="mt-3">
        <Col xs="12" md>
          <Text mode="Heading">Global Response Sets</Text>
        </Col>
        <Col xs="12" md className="d-md-flex justify-content-md-end">
          <Button
            className="w-sm-100"
            onClick={() => {
              setShowResponseOffCanvas(true);
            }}
          >
            <AddIcon /> Create Global Response Set
          </Button>
        </Col>
      </Row>
      <Row className="mt-3 align-items-center justify-content-between">
        <Col xs="auto">
          <TextInput
            placeholder="Search"
            startIcon={<SearchIcon size={20} />}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Col>
        <Col xs="auto">
          <Stack direction="horizontal">
            <Text fontSize="0.8rem">{`1 - ${watchForm.length} of ${watchForm.length} results`}</Text>
            <OverlayTrigger
              trigger="click"
              placement="left-start"
              rootClose
              overlay={
                <Popover className="qs-dropdown">
                  <span
                    style={{
                      fontSize: "0.8rem",
                      padding: "1rem",
                    }}
                  >
                    Results per page
                  </span>
                  <div>25</div>
                  <div>50</div>
                  <div>100</div>
                </Popover>
              }
            >
              <span className="animated-btn p-1">
                <BsThreeDotsVertical
                  style={{
                    fontSize: "1rem",
                  }}
                />
              </span>
            </OverlayTrigger>
          </Stack>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <ListGroup>
            <ListGroup.Item className="table-header-item">
              <Row>
                <Col>
                  <Text className="table-header-text">Name</Text>
                </Col>
                <Col>
                  <Text className="table-header-text">Last modified</Text>
                </Col>
                <Col>
                  <Text className="table-header-text">Responses</Text>
                </Col>
                <Col xs="auto">
                  <span className="animated-btn">
                    <BsThreeDotsVertical size={20} />
                  </span>
                </Col>
              </Row>
            </ListGroup.Item>
            {filteredResponseSets().map(
              ({ id, name, updatedAt, responses }, i) => (
                <ListGroup.Item className="elevated-item" key={i}>
                  <Row className="align-items-center">
                    <Col>
                      <Text>{name}</Text>
                    </Col>
                    <Col>
                      <Text>
                        {moment.unix(updatedAt).format("DD MMM YYYY")}
                      </Text>
                    </Col>
                    <Col>
                      <Text>{responses?.length}</Text>
                    </Col>
                    <Col xs="auto">
                      <OverlayTrigger
                        key={i}
                        rootClose
                        trigger="click"
                        placement="left-start"
                        overlay={
                          <Popover className="qs-dropdown">
                            <div
                              className="d-flex align-items-center"
                              onClick={() => {
                                document.body.click();
                                setShowResponseOffCanvas((prev) => {
                                  responseCanvasForm.reset({
                                    id,
                                    name,
                                    responses,
                                    updatedAt,
                                  });
                                  setSelectedSetIndex(i);

                                  return !prev;
                                });
                              }}
                            >
                              <MdEdit size={20} className="me-2" /> Edit
                            </div>
                            <div
                              className="d-flex align-items-center"
                              onClick={() => {
                                document.body.click();
                                formArrayField.remove(i);
                              }}
                            >
                              <MdDelete size={20} className="me-2" />
                              Delete
                            </div>
                          </Popover>
                        }
                      >
                        <span
                          className="animated-btn"
                          onClick={(e) => {
                            // document.body.click();
                            e.stopPropagation();
                          }}
                        >
                          <BsThreeDotsVertical size={20} />
                        </span>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )
            )}
          </ListGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="justify-content-end d-flex">
          <Pagination>
            <Pagination.First />
            <Pagination.Item>1</Pagination.Item>
            <Pagination.Item>2</Pagination.Item>
            <Pagination.Item>3</Pagination.Item>
            <Pagination.Item>4</Pagination.Item>
            <Pagination.Last />
          </Pagination>
        </Col>
      </Row>

      <Offcanvas
        show={showResponseOffCanvas}
        onHide={() => {
          closeOffcanvas();
        }}
        placement="end"
        onExited={() => {
          responseCanvasForm.reset(responseCanvasFormInitState);
          setSelectedSetIndex(-1);
        }}
      >
        <Offcanvas.Header
          style={{
            backgroundColor: "var(--bs-gray-200)",
          }}
        >
          <span
            className="animated-btn"
            onClick={() => {
              closeOffcanvas();
            }}
          >
            <MdClose size={20} />
          </span>
          <Offcanvas.Title>Response Set</Offcanvas.Title>
          <span className="animated-btn">
            <BsThreeDotsVertical size={20} />
          </span>
        </Offcanvas.Header>
        <Offcanvas.Body className="overflow-hidden p-0 d-flex flex-column">
          <div
            style={{
              backgroundColor: "var(--bs-gray-200)",
            }}
            className="p-3 border-bottom"
          >
            <Controller
              control={responseCanvasForm.control}
              name="name"
              render={({ field }) => (
                <TextInputWithRef
                  titleInput={true}
                  titleInputIcon={<MdEdit size={20} className="ms-2" />}
                  titleProps={{
                    className: "fs-4",
                  }}
                  {...field}
                />
              )}
            />

            <TextInputWithRef
              placeholder="Search"
              startIcon={<SearchIcon size={20} />}
              wrapperClassName="mt-4"
            />
          </div>
          <div className="flex-grow-1 overflow-auto">
            {responseCanvasFieldArray.fields?.map((response, i) => (
              <Controller
                key={i}
                control={responseCanvasForm.control}
                name={`responses.${i}.text`}
                render={({ field }) => (
                  <TextInputWithRef
                    id={`response-${selectedSetIndex}-${i}`}
                    refWrapperClassName="border-bottom w-100 p-2"
                    titleProps={{
                      className: "d-flex justify-content-between",
                    }}
                    titleInput={true}
                    titleStackClassName="justify-content-between"
                    titleInputIcon={
                      <span
                        className="animated-btn ms-2"
                        onClick={() => {
                          responseCanvasFieldArray.remove(i);
                        }}
                      >
                        <MdDelete size={20} />
                      </span>
                    }
                    {...field}
                  />
                )}
              />
            ))}
          </div>
          <div className="border-top px-3 py-2">
            <Button
              variant="light"
              className="text-primary bg-white fw-bold border-0 d-flex align-items-center"
              onClick={() => {
                responseCanvasFieldArray.append({
                  text: "New Response",
                });
              }}
            >
              <AddIcon size={20} />
              Add response
            </Button>
          </div>
          <Stack
            direction="horizontal"
            className="border-top p-3"
            style={{
              backgroundColor: "var(--bs-gray-200)",
            }}
          >
            <Button
              onClick={() => {
                let values = responseCanvasForm.getValues();

                if (selectedSetIndex > -1) {
                  form.setValue(`responses.${selectedSetIndex}`, values);
                } else {
                  formArrayField.append(values);
                }
                closeOffcanvas();
              }}
            >
              Save and apply
            </Button>
            <Button
              className="hover-btn ms-3"
              onClick={() => {
                closeOffcanvas();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
}
