import {AxiosError} from "axios";
import axiosInstance from "./axiosInstance";
import {QuestionType} from "./template";

declare module Inspection {
  interface IInspection {}
  type STATUS = "Completed" | "In Progress";
  type FetchInspection = {
    id: string;
    title: string;
    action: string;
    docNumber: number;
    score: string;
    conducted: string;
    completed: string;
    templateTitle: string;
    status: STATUS;
    itemsCompleted: string;
    location: string;
    owner: string;
    lastEditedBy: string;
    startedAt: string;
    updatedAt: string;
  };

  type FetchInspectionDetailsResponse = {
    template: string;
    status: STATUS;
    score: number;
    itemsCompleted: string;
    location: string;
    owner: string;
    lastEditedBy: string;
    started: string;
    updated: string;
  };

  type InspectionSubmitPayload = {
    id: string;
    title: string;
    pages: Array<{
      id: string;
      title: string;
      questions: Array<Partial<QuestionType>>;

      sections: Array<{
        id: string;
        questions: Array<
          | {
              id: string;
              mediaPaths: Array<string>;

              multiOptionValues: Array<{
                id: string;
              }>;
              value: any;
              notes: string;
            }
          | any
        >;
      }>;
    }>;
    mediaUniqueId: string;
    templateId: string;
  };

  type UploadInspectionMediaPayload = {
    base64: string;
    fileName: string;
  };

  type FetchInspectionResponseQuestionType = {
    id: string;
    title: string;
    value: string;
    notes: string;
    sequence: number;
    isAnswered: boolean;
    mediaPaths: Array<string>;
    type: QuestionType["type"];
    multiOptions: Array<{
      id: string;
      title: string;
      selected: boolean;
    }>;
  };
  interface FetchInspectionResponse {
    id: string;
    templateTitle: string;
    title: string;
    reportPath: string;
    submittedBy: string;
    createdBy: string;
    pages: Array<{
      id: string;
      title: string;
      sequence: number;
      questions: Array<FetchInspectionResponseQuestionType>;
      sections: Array<any>;
    }>;
    logoPath: string;
    templateImage?: {
      base64: string;
      fileName: string;
      url: string;
    };
  }
}

module Inspection {
  export function FetchAllInspections(
    success: (inspections: Array<FetchInspection>) => void,
    error: (error: string) => void
  ) {
    axiosInstance
      .get("/comply-tsfm/api/auditorInspection")
      .then(({data}) => {
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function FetchInspectionDetails(
    id: string,
    success: (inspections: FetchInspectionDetailsResponse) => void,
    error: (error: string) => void
  ) {
    axiosInstance
      .get(`/comply-tsfm/api/auditorInspection/${id}/details`)
      .then(({data}) => {
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function UploadInspectionMedia(
    mediaUniqueId: string,
    data: UploadInspectionMediaPayload,
    success: (mediaURL: string) => void,
    error: (error: string) => void
  ) {
    axiosInstance
      .put(
        `/comply-tsfm/api/auditorInspection/${mediaUniqueId}/questionMedia`,
        data
      )
      .then(({data}) => {
        success(data?.path);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export async function UploadInspectionMediaAsync(
    mediaUniqueId: string,
    data: UploadInspectionMediaPayload
  ) {
    return axiosInstance.put(
      `/comply-tsfm/api/auditorInspection/${mediaUniqueId}/questionMedia`,
      data
    );
  }

  export function SubmitInspection(
    data: InspectionSubmitPayload,
    success: (message: string) => void,
    error: (error: string) => void
  ) {
    axiosInstance
      .post("/comply-tsfm/api/auditorInspection", data)
      .then(({data}) => {
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function FetchInspectionById(
    id: string,
    success: (inspection: IInspection) => void,
    error: (error: string) => void
  ) {
    axiosInstance
      .get(`/comply-tsfm/api/auditorInspection/${id}`)
      .then(({data}) => {
        success(data);
      })
      .catch((err: AxiosError<any>) => {
        error(err.response?.data?.message);
      });
  }
}

export default Inspection;
