import { useState } from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MdComputer, MdPictureAsPdf } from "react-icons/md";
import { TemplateReportPayload } from "../../../../api/template";
import Text from "../../../../components/text/text";
import ReportPDFPreview from "../../components/report-pdf-preview";
import ReportWebPreview from "../../components/report-web-preview";
import TemplateLayoutSelector from "../../components/template-layout-selector";

export default function TemplateReportLayout({}) {
  const [previewMode, setPreviewMode] = useState<"WEB" | "PDF">("PDF");
  const form = useForm<TemplateReportPayload>({
    defaultValues: {
      title: "Untitled layout",
      showActions: true,
      showFlaggedItems: true,
      showScores: true,
      showMediaSummary: true,
      showInspectionResults: true,
      status: "Complete",
    },
  });

  return (
    <Container className="h-100" fluid>
      <Row className="h-100 ">
        <Col md="12" xl="9" className="d-flex flex-column h-100 overflow-auto ">
          <Stack
            className="mt-5 px-4 justify-content-between"
            direction="horizontal"
          >
            <Text mode="Heading">Report set up and preview</Text>
            <ToggleButtonGroup
              type="radio"
              name="preview"
              className="d-inline-block"
              defaultValue={previewMode}
              onChange={(value: any) => {
                setPreviewMode(value);
              }}
            >
              <ToggleButton id="WEB" value="WEB">
                <MdComputer style={{ color: "white" }} size={20} /> Web Preview
              </ToggleButton>
              <ToggleButton id="PDF" value="PDF">
                <MdPictureAsPdf style={{ color: "white" }} size={20} /> PDF
                Preview
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <div className="px-4 my-3">
            {previewMode === "PDF" && <ReportPDFPreview form={form} />}
            {previewMode === "WEB" && <ReportWebPreview form={form} />}
          </div>
        </Col>
        <Col md="12" xl="3" className="bg-white h-100 d-none d-xl-block">
          <TemplateLayoutSelector form={form} />
        </Col>
      </Row>
    </Container>
  );
}
