import moment from "moment";
import React, {useRef, useState} from "react";
import {Toast, ToastContainer} from "react-bootstrap";
import {BsCheckCircleFill} from "react-icons/bs";
import {MdCancel, MdInfo} from "react-icons/md";
import InformationModal, {
  InformationModalPromptType,
  InformationModalProps,
} from "../components/information-modal/information-modal";
import "./../components/information-modal/information.modal.styles.css";

interface NotificationContextProps {
  closeNotification: Function;
  showNotification: (
    config: {
      type: InformationModalProps["type"];
      manualDismiss?: boolean;
      isToast?: boolean;
      prompts?: InformationModalPromptType;
    },
    message: string,
    description?: string
  ) => void;

  //   showToast: (title: string, message: string) => void;
}
const NotificationContext = React.createContext<NotificationContextProps>({
  closeNotification: () => {},
  showNotification: () => {},
});

export function NotificationProvider({children}: any) {
  const divRef = useRef<HTMLDivElement>(null);
  const [autoDismiss, setAutoDismiss] = useState<boolean>(true);
  const [message, setMessageState] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [messageType, setMessageType] =
    useState<InformationModalProps["type"]>("SUCCESS");

  const [prompts, setPrompts] = useState<InformationModalPromptType>([]);

  const [toasts, setToasts] = useState<
    Array<{
      id: number;
      message: string;
      title?: string;
      autoDismiss?: boolean;
      type: InformationModalProps["type"];
    }>
  >([]);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const closeNotification = () => {
    let div = divRef.current;
    if (div) {
      // div.style.transform = `translate(0, -${10}px)`;
      // div.style.opacity = "0";
      div.classList.remove("show");
      div.classList.add("close");

      setTimeout(() => {
        setDescription("");
        setMessageState("");

        setMessageType("SUCCESS");
        setPrompts([]);
      }, 500);
    }
  };
  const showNotification = (
    config: {
      type: InformationModalProps["type"];
      manualDismiss?: boolean;
      isToast?: boolean;
      prompts?: InformationModalPromptType;
    },
    message: string,
    description?: string
  ) => {
    if (config.isToast) {
      let toastID = moment().unix();
      setToasts([
        ...(toasts.length > 2 ? toasts.slice(1) : toasts),
        {
          id: toastID,
          message,
          title: description,
          autoDismiss: !config?.manualDismiss,
          type: config.type,
        },
      ]);

      if (!config.manualDismiss) {
        setTimeout(() => {
          setToasts((prev) => prev.filter((p) => p.id !== toastID));
        }, 10000);
      }
    } else {
      setMessageType(config.type);
      setMessageState(message);
      description && setDescription(description);
      config.prompts && setPrompts(config.prompts);
      let div = divRef.current;
      if (div) {
        // div.style.transform = `translate(0, ${10}px)`;
        // div.style.opacity = "1";

        div.classList.remove("close");
        div.classList.add("show");

        if (!config.manualDismiss) {
          const timeoutSubscription = setTimeout(() => {
            closeNotification();
          }, 5000);
          setTimeoutId(timeoutSubscription);
        }
      }
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        closeNotification,
        showNotification,
      }}
    >
      <ToastContainer
        style={{
          bottom: 20,
          right: 20,
          // top: 20,
          position: "fixed",
          maxHeight: "500px",
        }}
      >
        {toasts.map((t, i) => (
          <Toast
            key={`toast-${i}`}
            animation={true}
            autohide={t.autoDismiss}
            delay={7000}
            show={toasts.some(({id}) => id === t.id)}
            onClose={() => {
              setToasts(toasts.filter(({id}) => id !== t.id));
            }}
          >
            <Toast.Header>
              {t.type == "SUCCESS" && (
                <BsCheckCircleFill color="green" size={30} />
              )}
              {t.type == "ERROR" && <MdCancel color="red" size={30} />}
              {t.type == "INFO" && <MdInfo color="orange" size={30} />}

              <strong className="me-auto">{t.title}</strong>
              <small className="text-muted">
                {moment.unix(t.id).fromNow()}
              </small>
            </Toast.Header>
            <Toast.Body>{t.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
      {
        <div
          ref={divRef}
          className="position-absolute info-mod-container pt-3"
          style={{
            zIndex: 1031,
            top: 0,
            left: 10,
            right: 10,
            width: "fit-content",
            margin: "auto",
          }}
        >
          <InformationModal
            type={messageType}
            message={message}
            description={description}
            onClose={() => {
              clearTimeout(timeoutId);
              closeNotification();
            }}
            prompts={prompts}
          />
        </div>
      }
      {children}
    </NotificationContext.Provider>
  );
}

export default function useNotifications() {
  return React.useContext(NotificationContext);
}
