import {Button, Col, Container, Nav, Row, Stack, Tab} from "react-bootstrap";
import {BsQuestionCircleFill, BsThreeDotsVertical} from "react-icons/bs";
import {MdOutlineArrowBack} from "react-icons/md";
import {GrUndo, GrRedo} from "react-icons/gr";
import Text from "../../../components/text/text";
import "./template-editor.scss";
import BuildTemplate from "./tabs/build";
import TemplateAccess from "./tabs/access";
import {Fragment, useEffect, useState} from "react";
import TemplateReportLayout from "./tabs/report";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import Template, {
  QuestionType,
  TemplateCreatePayload,
} from "../../../api/template";
import useNotifications from "../../../hooks/notification";
import TemplateModule from "./../../../api/template";

export default function TemplateEditor({}) {
  const {templateId} = useParams();
  const notifications = useNotifications();
  const [activeTabkey, setActiveTabkey] = useState<string | null | undefined>(
    "build"
  );

  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();

  const BulkEditComponent = () => {
    return (
      <Fragment>
        <span className="animated-btn">
          <GrUndo size={20} />
        </span>
        <span className="animated-btn">
          <GrRedo size={20} />
        </span>
        <Button variant="light" className="border hover-btn">
          Bulk Edit
        </Button>
      </Fragment>
    );
  };
  const TITLE_PAGE_QUESTIONS: Array<Partial<QuestionType>> = [
    {
      title: "Site Conducted",
      type: "SITE",
      isRequired: true,
    },
    {
      title: "Conducted on",
      type: "DATETIME",
      isRequired: false,
    },
    {
      title: "Prepared by",
      type: "SHORT_ANSWER",
      isRequired: true,
    },
    {
      title: "Location",
      type: "LOCATION",
      isRequired: true,
    },
  ];
  const templateForm = useForm<Template.ITemplate>({
    defaultValues: {
      templateImage: undefined,
      title: "Untitled Template",
      description: "",
      pages: [
        {
          // id: 1,
          title: "Title Page",
          description:
            "The Title Page is the first page of the template. You can customize the Title Page below",
          questions: TITLE_PAGE_QUESTIONS,
        },
      ],
    },
  });

  useEffect(() => {
    if (templateId) {
      TemplateModule.FetchTemplate(
        templateId,
        (template) => {
          templateForm.reset(template);
        },
        (error) => {
          notifications.showNotification(
            {type: "ERROR", manualDismiss: true},
            "Unable to fetch template",
            error
          );
        }
      );
    }
  }, []);
  return (
    <Fragment>
      <Tab.Container
        defaultActiveKey="build"
        onSelect={(activeKey) => {
          setActiveTabkey(activeKey);
        }}
      >
        <Container fluid>
          <Row className="border-bottom py-2 justify-content-between">
            <Col xs="auto" lg>
              <Stack direction="horizontal">
                <span
                  className="animated-btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <MdOutlineArrowBack size={20} />
                </span>
                <Stack className="d-none d-sm-block">
                  <Text className="pointer">Back to List</Text>
                  {/* <Text variant="light" fontSize="12px">
                    All Changes Saved
                  </Text> */}
                </Stack>
              </Stack>
            </Col>
            <Col xs="auto" lgclassName="d-flex justify-content-center">
              <Nav>
                <Nav.Item className="">
                  <Nav.Link className="qs-tab-item h-100" eventKey="build">
                    Build
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link className="qs-tab-item h-100" eventKey="report">
                    Report
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link className="qs-tab-item h-100" eventKey="access">
                    Access
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs="auto" lg className="d-flex justify-content-end">
              <Stack direction="horizontal" className="create-temp-end">
                {/* {activeTabkey === "build" && (
                  <div className="d-none d-xl-block">
                    <BulkEditComponent />
                  </div>
                )} */}
                <span className="animated-btn">
                  <BsQuestionCircleFill size={20} />
                </span>
                <span className="animated-btn">
                  <BsThreeDotsVertical size={20} />
                </span>
              </Stack>
            </Col>
          </Row>
          {activeTabkey === "build" && (
            <Stack
              direction="horizontal"
              className="justify-content-between p-2"
            >
              <Button
                onClick={() => {
                  setShowPreview(true);
                }}
              >
                Preview
              </Button>
              {/* <div className="d-inline-block">
                <BulkEditComponent />
              </div> */}
              <Button
                variant="success"
                style={{color: "white"}}
                onClick={templateForm.handleSubmit(
                  (data) => {
                    if (templateId) {
                      Template.UpdateTemplate(
                        templateId,
                        data,
                        (template) => {
                          navigate(-1);
                        },
                        (error) => {
                          notifications.showNotification(
                            {
                              type: "ERROR",
                              manualDismiss: true,
                            },
                            "Unable to save template",
                            error
                          );
                        }
                      );
                    } else {
                      Template.CreateTemplate(
                        data,
                        (tem) => {
                          // console.log({ tem });
                          navigate(-1);
                        },
                        (error) => {
                          // console.log(error);
                          notifications.showNotification(
                            {
                              type: "ERROR",
                              manualDismiss: true,
                            },
                            "Unable to save template",
                            error
                          );
                        }
                      );
                    }
                  },
                  (error) => {
                    // console.log({ error });
                  }
                )}
              >
                {templateId ? "Update" : "Create"}
              </Button>
            </Stack>
          )}
          <Row>
            <Col className="p-0">
              <Tab.Content
                style={{
                  backgroundColor: "var(--bs-gray-200)",
                  height: "calc(100vh - 55px)",
                }}
                className=" overflow-auto"
              >
                <Tab.Pane className="h-100" eventKey="build">
                  <BuildTemplate
                    showPreview={showPreview}
                    togglePreview={() => {
                      setShowPreview(!showPreview);
                    }}
                    templateForm={templateForm}
                  />
                </Tab.Pane>
                <Tab.Pane className="h-100" eventKey="report">
                  <TemplateReportLayout />
                </Tab.Pane>
                <Tab.Pane eventKey="access">
                  <TemplateAccess />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </Fragment>
  );
}
