import moment from "moment";
import { Fragment, useCallback, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Offcanvas,
  OverlayTrigger,
  Pagination,
  Popover,
  Row,
  Table,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import Select from "react-select";
import { getInitials } from "../../../api/general";
import FilterButton from "../../../components/filter-button/filter-button";
import { AddIcon, SearchIcon } from "../../../components/icons/icons";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";
import SelectTheme from "../../../scss/select.theme";

type FilterPayload = {
  template: Array<number>;
  status: string;
  assignedTo: Array<number>;
  assignedBy: Array<number>;
  days: {
    number: number;
    type: "LAST" | "CURRENT";
    unit: "DAY" | "WEEK" | "MONTH" | "YEAR";
  };
};

export default function MissedLateInspections({}) {
  const [openCanvas, setOpenCanvas] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string;
      key: string;
      open: boolean;
    }>
  >([]);
  const [searchedText, setSearchedText] = useState("");
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm<FilterPayload>({
    defaultValues: {
      template: undefined,
      status: undefined,
      assignedTo: undefined,
      assignedBy: undefined,
      days: {
        number: 0,
        type: "CURRENT",
        unit: "DAY",
      },
    },
  });

  const [filters, setFilters] = useState([
    {
      label: "Template",
      key: "template",
      open: true,
    },
    {
      label: "Assignees",
      key: "assignees",
      open: true,
    },
    {
      label: "Assigned by",
      key: "assignedBy",
      open: true,
    },
    {
      label: "Status",
      key: "status",
      open: true,
    },
  ]);

  const daysFilterOptions = [
    {
      label: "Today",
      value: {
        number: 0,
        type: "CURRENT",
        unit: "DAY",
      },
    },
    {
      label: "Yesterday",
      value: {
        number: 1,
        type: "LAST",
        unit: "DAY",
      },
    },
    {
      label: "Last 7 days",
      value: {
        number: 7,
        type: "LAST",
        unit: "DAY",
      },
    },
    {
      label: "Last 30 days",
      value: {
        number: 30,
        type: "LAST",
        unit: "DAY",
      },
    },
    {
      label: "This Month",
      value: {
        number: 0,
        type: "CURRENT",
        unit: "MONTH",
      },
    },
    {
      label: "Last Month",
      value: {
        number: 0,
        type: "LAST",
        unit: "MONTH",
      },
    },
  ];

  const [schedules, setSchedules] = useState<
    Array<{
      title: string;
      assignedTo: string;
      status: string;
      due: number;
      template: string;
    }>
  >(
    Array.from({ length: 7 }).map((_, i) => ({
      title: "Inspection " + i,
      assignedTo: "Saoud Nazir",
      status: "Active",
      due: 1658724395,
      template: "Template 1",
    }))
  );
  const filteredSchedules = useCallback(() => {
    return schedules.filter((schedule) => {
      return schedule.title.toLowerCase().includes(searchedText.toLowerCase());
    });
  }, [searchedText]);

  return (
    <Fragment>
      <Container fluid>
        <Row className="mt-3">
          <Col xs="auto" className="ps-0">
            <TextInput
              placeholder="Search"
              startIcon={<SearchIcon size={20} />}
              onChange={(e) => setSearchedText(e.target.value)}
            />
          </Col>
          <Col xs="auto">
            <Controller
              control={control}
              name="days"
              render={({ field: { onChange, value, name } }) => (
                <Select
                  styles={{
                    ...SelectTheme,
                    control: (base, state) => ({
                      ...base,
                      ...(SelectTheme?.control &&
                        SelectTheme?.control(base, state)),
                      minWidth: "150px",
                      border: "1px solid var(--bs-primary)",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: "var(--bs-primary)",
                    }),
                  }}
                  options={daysFilterOptions}
                  onChange={(value: any) => {
                    onChange(value.value);
                  }}
                />
              )}
            />
          </Col>

          {appliedFilters.map((af, i) => (
            <Controller
              key={i}
              control={control}
              name={af.key as any}
              render={({ field: { onChange, value, name } }) => (
                <Col xs="auto">
                  <FilterButton
                    open={af.open}
                    setOpen={(open) => {
                      setAppliedFilters((prev) => {
                        return [...prev.slice(0, i), { ...prev[i], open }];
                      });
                    }}
                    type={name as any}
                    label={af.label}
                    defaultValue={value}
                    onChange={onChange}
                    onDone={handleSubmit(
                      (data) => {
                        console.log({ data });
                      },
                      (error) => {
                        console.log(error);
                      }
                    )}
                    onClear={() => {
                      reset({ [name]: undefined });
                      setAppliedFilters((prev) => {
                        return [...prev.slice(0, i)];
                      });
                    }}
                  />
                </Col>
              )}
            />
          ))}

          <Col xs="auto">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Popover
                  className="qs-dropdown-popup p-0 py-2"
                  style={{ minWidth: "160px" }}
                >
                  {filters.map((filter, i) => (
                    <Text
                      key={i}
                      className="inspection-filter-option px-3 pointer"
                      onClick={() => {
                        setAppliedFilters([...appliedFilters, filter]);
                        document.body.click();
                      }}
                    >
                      {filter.label}
                    </Text>
                  ))}
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-decoration-none fw-bold d-flex align-items-center"
              >
                <AddIcon size={20} className="me-2" /> Add filter
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="p-0">
            <ListGroup>
              <ListGroup.Item className="schedule-manage-header">
                <Row>
                  <Col xs md="3">
                    <Text fontWeight="500">Title</Text>
                  </Col>
                  <Col>
                    <Text fontWeight="500">Status</Text>
                  </Col>
                  <Col xs md="3">
                    <Text fontWeight="500">Template</Text>
                  </Col>
                  <Col>
                    <Text fontWeight="500">Assignees</Text>
                  </Col>
                  <Col>
                    <Text fontWeight="500">Due on</Text>
                  </Col>
                </Row>
              </ListGroup.Item>
              {filteredSchedules().map(
                ({ assignedTo, due, status, title, template }, i) => (
                  <ListGroup.Item
                    key={i}
                    className="schedule-manage-item elevated-item"
                    onClick={() => {
                      setOpenCanvas(true);
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs md="3">
                        <Text className="schedule-manage-item-text">
                          {title} {title}
                        </Text>
                      </Col>
                      <Col>
                        <Text className="schedule-manage-item-text">
                          <Badge>{status}</Badge>
                        </Text>
                      </Col>
                      <Col xs md="3">
                        <Text className="schedule-manage-item-text">
                          {template}
                        </Text>
                      </Col>
                      <Col>
                        <div className="schedule-manage-item-text initials">
                          {getInitials(assignedTo)}
                        </div>
                      </Col>

                      <Col>
                        <Text className="schedule-manage-item-text">
                          {moment.unix(due).format("DD MMM YYYY hh:mm A")}
                        </Text>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
            {/* <Table className="qs-table" responsive>
            <thead>
              <tr>
                <th>Title</th>
                <th>Status</th>
                <th>Template</th>
                <th>Assignees</th>
                <th>Due on</th>
              </tr>
            </thead>
            <tbody>
              {filteredSchedules().map(
                ({ assignedTo, due, status, title }, i) => (
                  <tr className="elevated-item">
                    <td width="25%">
                      {title} {title}
                      {title} {title}
                      {title} {title}
                    </td>
                    <td>{status}</td>
                    <td>
                      Template Template Template Template Template Template
                      Template Template Template TemplateTemplate Template
                      Template Template Template Template Template Template
                      Template Template
                    </td>
                    <td>
                      <div className="initials">{getInitials(assignedTo)}</div>
                    </td>
                    <td>
                      {moment.unix(Number(due)).format("MMM DD YYYY hh:mm A")}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table> */}
            <div className="d-flex justify-content-end mt-3">
              <Pagination>
                <Pagination.First />
                <Pagination.Item>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Last />
              </Pagination>
            </div>
          </Col>
        </Row>
      </Container>
      <Offcanvas
        show={openCanvas}
        placement="end"
        onHide={() => setOpenCanvas(false)}
      >
        <Offcanvas.Header>
          <div
            className="pointer animated-btn"
            onClick={() => {
              setOpenCanvas(false);
            }}
          >
            <MdOutlineClose size={20} />
          </div>
          <Text mode="SubHeading" fontWeight="600">
            Missed Inspection
          </Text>
          <div />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="px-4 py-2">
            <Text mode="Heading">
              Sample - COVID-19 Daily Inspection by Mitti
            </Text>
            <Text variant="light">
              This is a sample inspection for COVID-19.
            </Text>

            <Text variant="light" className="mt-3">
              Schedule Window
            </Text>
            <Text mode="SubHeading">
              Starts 21 Jul 2022 9:00 AM. Due 21 Jul 2022 5:00 PM
            </Text>

            <Text variant="light" className="mt-3">
              Assigned by
            </Text>
            <Text mode="SubHeading">Saoud Nazir</Text>
          </div>
          <div className="h-100 bg-light p-4">
            <div className="bg-white border rounded px-2 py-3">
              <Text variant="light">Assignees who missed this inspection</Text>
              <Text mode="SubHeading">Saoud Nazir</Text>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
}
