import React, { Fragment } from "react";
import { Button, ButtonGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import AccessPopup from "./components/access";
import DatePopup from "./components/date";
import { FilterProps } from "./components/filter-popup";
import GroupsPopup from "./components/groups";
import SitePopup from "./components/site";
import TemplatePopup from "./components/template";
import "./filter-button.scss";

enum FilterType {
  access,
  template,
  groups,
  site,
  date,
}

interface FilterButtonProps {
  label: string;
  onDone: Function;
  onChange: (data: any) => void;
  type: keyof typeof FilterType;
  defaultValue?: any;
  onClear: Function;
  searchable?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

export default function FilterButton({
  label,
  onDone,
  type,
  onChange,
  defaultValue,
  onClear,
  open,
  setOpen,
}: FilterButtonProps) {
  const TypeBasedComponent =
    type === "access"
      ? AccessPopup
      : type === "template"
      ? TemplatePopup
      : type === "groups"
      ? GroupsPopup
      : type === "site"
      ? SitePopup
      : type === "date"
      ? DatePopup
      : null;
  return (
    <div className="border rounded">
      <ButtonGroup>
        <OverlayTrigger
          // show={open}
          // defaultShow={true}
          // onToggle={setOpen}
          placement="bottom-start"
          trigger="click"
          rootClose
          overlay={
            <Popover
              className="qs-dropdown-popup"
              style={{
                maxWidth: "50rem",
                minWidth: "15rem",
              }}
            >
              {TypeBasedComponent && (
                <TypeBasedComponent
                  onChange={onChange}
                  defaultValue={defaultValue}
                />
              )}
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  className="rounded fw-bold"
                  onClick={() => {
                    onDone();
                    document.body.click();
                  }}
                >
                  Done
                </Button>
              </div>
            </Popover>
          }
        >
          <Button variant="link" className="text-decoration-none filter-btn">
            {label}
          </Button>
        </OverlayTrigger>
        <Button
          variant="link"
          className="text-decoration-none border-start filter-btn"
          onClick={() => onClear()}
        >
          <MdOutlineClose />
        </Button>
      </ButtonGroup>
    </div>
  );
}
