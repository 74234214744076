import moment from "moment";
import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  ListGroup,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {GoPrimitiveDot} from "react-icons/go";
import {MdMoreHoriz, MdOutlineFileDownload, MdSettings} from "react-icons/md";
import {useLinkClickHandler, useLocation, useNavigate} from "react-router-dom";
import {IssueListType, IssueStatus} from "../../api/issues";
import FilterButton from "../../components/filter-button/filter-button";
import {AddIcon, SearchIcon} from "../../components/icons/icons";
import TextInput from "../../components/input/input";
import MenuPopupItem from "../../components/menu/menu-item";
import Text from "../../components/text/text";
import "./issues.scss";
import NewIssue from "./modal/new-issue";
import ReportTypeSelection from "./modal/type-selection";
// import BottomIssueBar from "./components/bottom-issue-bar";
// import CreateIssue from "./modal/create-issue";

type FilterPayload = {
  status: Array<"TODO" | "INPROGRESS" | "COMPLETE" | "CAN'TDO">;
  assignee: Array<number>;
  location: Array<number>;
  template: Array<number>;
  inspection: Array<number>;
  creator: Array<number>;
  dueDate: {
    from: number;
    to: number;
  };
  created: {
    from: number;
    to: number;
  };
  updated: {
    from: number;
    to: number;
  };
};
export default function Issues({}) {
  const navigate = useNavigate();
  let location = useLocation();
  const navigateToDetails = useLinkClickHandler("/app/issues/detail/:id", {
    state: {
      backgroundLocation: location,
    },
  });

  const {control, handleSubmit, reset} = useForm<FilterPayload>({
    defaultValues: {
      status: undefined,
      assignee: undefined,
      location: undefined,
      template: undefined,
      inspection: undefined,
      creator: undefined,
      dueDate: {
        from: undefined,
        to: undefined,
      },
      created: {
        from: undefined,
        to: undefined,
      },
      updated: {
        from: undefined,
        to: undefined,
      },
    },
  });
  const filtersOptions = [
    {
      label: "Status",
      key: "status",
    },
    {
      label: "Assignee",
      key: "assignee",
    },

    {
      label: "Creator",
      key: "createdBy",
    },
    {
      label: "Category",
      key: "category",
    },
  ];

  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string;
      key: string;
    }>
  >([]);

  const [issues, setIssues] = useState<Array<IssueListType>>([
    {
      id: 1,
      reportType: {
        id: 1,
        text: "Observation",
      },
      reportedBy: {
        id: 1,
        name: "John Doe",
      },
      assignee: {
        id: 1,
        name: "John Doe",
      },
      lastUpdated: moment().unix(),
      status: "OPEN",
      name: "Observation 1",
    },
    {
      id: 1,
      reportType: {
        id: 1,
        text: "Observation",
      },
      reportedBy: {
        id: 1,
        name: "John Doe",
      },
      assignee: {
        id: 1,
        name: "John Doe",
      },
      lastUpdated: moment().unix(),
      status: "OPEN",
      name: "Observation 2",
    },
  ]);

  const [sortBy, setSortBy] = useState<{
    label: string;
    key: string;
  }>({
    label: "Due Date",
    key: "dueDate",
  });

  const [selectedIssues, setSelectedIssues] = useState<Array<number>>([]);

  const [openCreateIssueModal, setOpenCreateIssueModal] = useState(false);
  const [openNewIssueModal, setOpenNewIssueModal] = useState(false);

  const [selectedNewIssueType, setSelectedNewIssueType] =
    useState<IssueListType["reportType"]>();

  useEffect(() => {
    if (selectedNewIssueType) {
      setOpenCreateIssueModal(false);
      setOpenNewIssueModal(true);
    }
  }, [selectedNewIssueType?.text]);

  const [searchText, setSearchText] = useState<string>("");
  const filteredIssues = useMemo(() => {
    return issues.filter((issue) => {
      return issue.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchText, issues]);

  return (
    <Fragment>
      <NewIssue
        reportType={selectedNewIssueType}
        show={openNewIssueModal}
        centered
        onHide={() => setOpenNewIssueModal(false)}
      />
      <ReportTypeSelection
        show={openCreateIssueModal}
        centered
        onHide={() => {
          setOpenCreateIssueModal(false);
        }}
        onSelect={(type) => setSelectedNewIssueType(type)}
      />

      <Container className="p-md-5 p-sm-2 p-2 position-relative vh-100 overflow-auto">
        <Row className="">
          <Col>
            <Text mode="Heading">Issues</Text>
          </Col>
          <Col className="d-flex justify-content-end">
            <Stack direction="horizontal">
              <Button
                variant="link"
                className="text-decoration-none border me-3"
                onClick={() => {
                  navigate("/app/issues/categories");
                }}
              >
                <MdSettings /> Manage Categories
              </Button>
              <Button onClick={() => setOpenCreateIssueModal(true)}>
                <AddIcon /> Report Issue
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col>
            <div className="d-inline-block">
              <TextInput
                placeholder="Search"
                startIcon={<SearchIcon size={20} />}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </Col>
          <Col className="justify-content-end d-flex">
            <Stack direction="horizontal">
              <FormCheck
                type="switch"
                style={{transform: "scale(1.5)", display: "inline-block"}}
                className="me-2"
              />
              <Text fontWeight="500">Hide Closed</Text>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-3">
          {appliedFilters.map((af, i) => (
            <Controller
              key={i}
              control={control}
              name={af.key as any}
              render={({field: {onChange, value, name}}) => (
                <Col xs="auto">
                  <FilterButton
                    //   open={af.open}
                    //   setOpen={(open) => {
                    //     setAppliedFilters((prev) => {
                    //       return [...prev.slice(0, i), { ...prev[i], open }];
                    //     });
                    //   }}
                    type={name as any}
                    label={af.label}
                    defaultValue={value}
                    onChange={onChange}
                    onDone={handleSubmit(
                      (data) => {
                        // console.log({ data });
                      },
                      (error) => {
                        // console.log(error);
                      }
                    )}
                    onClear={() => {
                      reset({[name]: undefined});
                      setAppliedFilters((prev) => {
                        return prev.filter((af) => af.key !== name);
                      });
                    }}
                  />
                </Col>
              )}
            />
          ))}

          <Col xs="auto">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Popover
                  className="qs-dropdown-popup p-0 py-2"
                  style={{minWidth: "160px"}}
                >
                  {filtersOptions.map((filter, i) => (
                    <Text
                      key={i}
                      className="inspection-filter-option px-3 pointer"
                      onClick={() => {
                        setAppliedFilters([...appliedFilters, filter]);
                        document.body.click();
                      }}
                    >
                      {filter.label}
                    </Text>
                  ))}
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-decoration-none fw-bold d-flex align-items-center"
              >
                <AddIcon size={20} className="me-2" /> Add filter
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Stack direction="horizontal">
              <FormCheck
                type="checkbox"
                className="issue-check-box me-3"
                checked={selectedIssues.length === issues.length}
                onChange={(e: any) => {
                  setSelectedIssues(
                    e.target.checked ? issues.map((a) => a.id) : []
                  );
                }}
              />
              <Text fontWeight="500" mode="SubHeading">
                Select All
              </Text>
            </Stack>
          </Col>
          <Col className="d-flex justify-content-center">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Popover className="qs-dropdown">
                  {[
                    {label: "Creation date", key: "createdOn"},
                    {
                      label: "Due date",
                      key: "dueDate",
                    },
                    {
                      label: "Priority",
                      key: "priority",
                    },
                    {
                      label: "Modified date",
                      key: "status",
                    },
                  ].map(({label, key}, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setSortBy({
                          label,
                          key,
                        });
                      }}
                    >
                      {label}
                    </div>
                  ))}
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-decoration-none fw-bold border"
              >
                {sortBy.label}
              </Button>
            </OverlayTrigger>
          </Col>
          <Col className="d-flex justify-content-end">
            <Stack direction="horizontal">
              <Button
                variant="link"
                className="text-decoration-none border fw-bold d-lg-flex align-items-center d-none"
              >
                <MdOutlineFileDownload size={20} className="me-1" /> Download
                CSV
              </Button>
              <Text className="ms-3" variant="light" fontWeight="500">
                {issues.length} results
              </Text>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-3">
          <ListGroup>
            {filteredIssues.map(
              (
                {
                  id,
                  lastUpdated,
                  name,
                  status,
                  reportType,
                  reportedBy,
                  assignee,
                },
                i
              ) => {
                const lastUpdatedOn = moment.unix(lastUpdated);
                return (
                  <ListGroup.Item onClick={navigateToDetails} key={i}>
                    <Row className="align-items-start">
                      <Col
                        xs="auto"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <FormCheck
                          type="checkbox"
                          className="issue-check-box"
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                          checked={selectedIssues.includes(id)}
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              setSelectedIssues([...selectedIssues, id]);
                            } else {
                              setSelectedIssues(
                                selectedIssues.filter((s) => s !== id)
                              );
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <Text fontWeight="500" mode="SubHeading">
                          {name}
                        </Text>
                        <Row className="mt-1 justify-content-between">
                          <Col lg="auto" md="12">
                            <Stack direction="horizontal">
                              {/* <Text fontWeight="500">//Todo</Text> */}
                              <Badge>{reportType.text}</Badge>

                              <GoPrimitiveDot
                                className="text-muted mx-1"
                                size={10}
                              />
                              <Text variant="light">
                                Assigned to {assignee.name}
                              </Text>
                            </Stack>
                          </Col>

                          <Col
                            lg="auto"
                            md="12"
                            className=" mt-sm-1 mt-lg-0 justify-content-lg-end d-flex"
                          >
                            <Stack
                              direction="horizontal"
                              className="justify-content-between d-flex w-100"
                            >
                              <Text variant="light">
                                Updated {lastUpdatedOn.fromNow()}
                              </Text>
                              <GoPrimitiveDot
                                className="text-muted mx-1 d-lg-block d-none "
                                size={10}
                              />
                              <Text color={IssueStatus(status).color}>
                                {IssueStatus(status).text}
                              </Text>
                            </Stack>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              }
            )}
          </ListGroup>
        </Row>
      </Container>
      {/* {selectedIssues.length > 0 && (
        <BottomIssueBar selections={selectedIssues} />
      )} */}
    </Fragment>
  );
}
