import moment from "moment";
import {useRef} from "react";
import {Badge, Button, Image, Modal, ModalProps, Stack} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {MdDelete, MdOutlineCalendarToday} from "react-icons/md";
import {useLocation, useNavigate} from "react-router-dom";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";
interface NewIssueProps extends ModalProps {
  reportType?: {id: number; text: string};
}

type NewIssuePayload = {
  date: number;
  attachments: Array<{
    name: string;
    type?: string;
    base64: string;
  }>;
};
export default function NewIssue(props: NewIssueProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const {getValues, setValue, watch} = useForm<NewIssuePayload>({
    defaultValues: {
      date: moment().unix(),
      attachments: [],
    },
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <Modal {...props} contentClassName="bg-white p-3">
      <Stack direction="horizontal">
        <Badge bg="light" className="text-black fs-6 me-2">
          {props.reportType?.text}
        </Badge>
        <Text fontSize="14px">
          <MdOutlineCalendarToday className="me-2" />
          {moment.unix(getValues("date")).format("MMM DD, YYYY")}
        </Text>
      </Stack>
      <TextInput
        placeholder="Add title"
        style={{fontSize: "1.2rem"}}
        className="border-0 mt-2"
      />
      <TextInput
        placeholder="Description of what happened"
        style={{fontSize: "0.8rem"}}
        className="border-0"
      />
      <Stack direction="horizontal" className="flex-wrap">
        {watch("attachments")
          .filter(({type}) => type?.includes("image"))
          .map((image, i) => (
            <div className="position-relative" key={i}>
              <span
                className="animated-btn position-absolute"
                style={{
                  right: 0,
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              >
                <MdDelete size={20} color="red" />
              </span>
              <Image
                src={`data:${image.type};base64,${image.base64}`}
                style={{
                  width: "100px",
                }}
              />
            </div>
          ))}
      </Stack>
      <Stack>
        {watch("attachments")
          .filter(({type}) => type?.includes("video"))
          .map((image, i) => (
            <div
              className="border p-2 rounded text-primary d-flex align-items-center justify-content-between my-1"
              key={i}
            >
              {image.name}
              <span className="animated-btn ">
                <MdDelete size={20} color="red" />
              </span>
            </div>
          ))}
      </Stack>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        multiple
        onChange={(e) => {
          // convert file into base64

          let files = e.target.files;
          // console.log(files);

          if (files) {
            let baseCodesandNames: any = [];

            for (let i = 0; i < (e.target?.files?.length || 0); i++) {
              let file = files.item(i);

              if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  const encodedFile = reader.result as string;
                  const base64 = encodedFile.split(";base64,")[1];

                  baseCodesandNames.push(base64);
                  setValue("attachments", [
                    ...getValues("attachments"),
                    {
                      base64,
                      name: file?.name ?? `image-${i}`,
                      type: file?.type,
                    },
                  ]);
                };
              }
            }
          }
        }}
      />
      <Button
        variant="light"
        className="mt-3"
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        Add photos/videos
      </Button>

      <Button
        className="mt-5"
        onClick={() => {
          navigate("/app/issues/detail/:id", {
            state: {
              backgroundLocation: location,
            },
          });
        }}
      >
        Submit
      </Button>
      <Button variant="light" className="mt-2" onClick={props.onHide}>
        Cancel
      </Button>
    </Modal>
  );
}
