import { Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import "./issue-categories.scss";
import CategoriesList from "./tabs/edit";
import Notifications from "./tabs/notifications";
import CustomQuestions from "./tabs/questions";

export default function EditCategories({}) {
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <Modal show={true} fullscreen>
      <Tab.Container defaultActiveKey="edit">
        <Row
          style={{ backgroundColor: "var(--bs-gray-100)" }}
          className="align-items-center border-bottom"
        >
          <Col
            xs="12"
            md="auto"
            className={`${isSmallDevice ? "border-bottom p-2" : ""}`}
          >
            <div
              className="category-header-close-btn mx-4 pointer"
              onClick={() => {
                navigate(-1);
              }}
            >
              <MdClose className="me-1" size={20} />
              Cancel
            </div>
          </Col>
          <Col xs="12" md className="justify-content-center d-flex">
            <Nav style={{ marginLeft: isSmallDevice ? 0 : -145 }}>
              <Nav.Item>
                <Nav.Link
                  eventKey="edit"
                  className="qs-tab-item category-header-responsive-text"
                >
                  Edit categories
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="notification"
                  className="qs-tab-item category-header-responsive-text"
                >
                  Automatice notifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="questions"
                  className="qs-tab-item category-header-responsive-text"
                >
                  Custom questions
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Tab.Content>
          <Tab.Pane eventKey="edit">
            <CategoriesList />
          </Tab.Pane>
          <Tab.Pane eventKey="notification">
            <Notifications />
          </Tab.Pane>
          <Tab.Pane eventKey="questions">
            <CustomQuestions />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Modal>
  );
}
