import { Button, Modal, ModalProps, Stack } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Text from "../text/text";
interface DeleteActionProps extends ModalProps {
  actionIds: Array<number>;
  title?: string;
  description?: string;
}
export default function DeleteModal(props: DeleteActionProps) {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 576px)",
  });
  return (
    <Modal {...props} contentClassName="border-0 p-3 shadow">
      <Modal.Title>
        {props.actionIds.length === 1
          ? "Are you sure you want to delete this action?"
          : `Delete ${props.actionIds.length} actions`}
      </Modal.Title>
      <Text variant="light" fontSize="14px">
        The action will be deleted for all users. This cannot be undone.
      </Text>
      <Stack
        direction={isSmallDevice ? "vertical" : "horizontal"}
        className="justify-content-sm-end mb-2 mt-5"
      >
        <Button className="me-md-2 " variant="light" onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant="danger" className="my-2 my-sm-0">
          Delete
        </Button>
      </Stack>
    </Modal>
  );
}
