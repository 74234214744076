import React, { useEffect, useRef, useState } from "react";
import { FormCheck, OverlayTrigger, Popover, Stack } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { RiArrowDownSLine } from "react-icons/ri";
import { SearchIcon } from "../icons/icons";
import TextInput, { TextInputWithRef } from "../input/input";
import Text from "../text/text";
import "./styles.scss";
interface EmailSelectorProps {
  placeholder: string;
  innerRef: any;
}
export default function EmailSelector({
  placeholder,
  innerRef,
}: EmailSelectorProps) {
  // const [enteredEmail, setEnteredEmail] = useState("");
  //   const [emails, setEmails] = useState<string[]>([]);

  type FormValues = {
    emails: string[];
    enteredEmail: string;
  };
  const { control, watch, setValue, getValues } = useForm<FormValues>({
    defaultValues: {
      enteredEmail: "",
      emails: [],
    },
  });

  const watchedEmails = watch("emails");
  const watchedEnteredEmail = watch("enteredEmail");

  return (
    <OverlayTrigger
      placement="bottom"
      rootClose
      trigger="click"
      container={innerRef.current}
      popperConfig={{
        strategy: "absolute",
      }}
      overlay={
        <Popover body className={`border-0 shadow p-2 email-popup `}>
          <Controller
            control={control}
            name="enteredEmail"
            render={({ field }) => (
              <TextInputWithRef
                wrapperClassName=""
                startIcon={<SearchIcon size={20} />}
                placeholder="john@example.com"
                {...field}
              />
            )}
          />

          <Text fontSize="12px" className="my-2">
            Type and select the email address you want to send to.
          </Text>
          {watchedEnteredEmail.length > 0 ? (
            <Stack direction="horizontal" className="no-hover">
              <FormCheck
                className="email-form-check me-2"
                checked={watchedEmails.includes(getValues("enteredEmail"))}
                onChange={(e: any) => {
                  if (e.target.checked) {
                    // setEmails([...emails, enteredEmail]);
                    setValue("emails", [
                      ...watchedEmails,
                      getValues("enteredEmail"),
                    ]);
                  } else {
                    // setEmails(emails.filter((email) => email !== enteredEmail));
                    setValue(
                      "emails",
                      watchedEmails.filter(
                        (email) => email !== watchedEnteredEmail
                      )
                    );
                  }
                }}
              />
              <Text fontSize="14px">{watchedEnteredEmail}</Text>
            </Stack>
          ) : (
            watchedEmails?.map((email, i) => (
              <Stack direction="horizontal" className="no-hover" key={i}>
                <FormCheck
                  className="email-form-check me-2"
                  checked={watchedEmails.includes(email)}
                  onChange={(e: any) => {
                    console.log(e.target.checked);
                    // setEmails(emails.filter((femail) => femail !== email));

                    setValue(
                      "emails",
                      watchedEmails.filter((femail) => femail !== email)
                    );
                  }}
                />
                <Text fontSize="14px">{email}</Text>
              </Stack>
            ))
          )}
        </Popover>
      }
    >
      <Stack
        direction="horizontal"
        className="border-bottom py-2 px-3 email-selector-container justify-content-between pointer "
      >
        <Text style={{ minWidth: "40px" }}>{placeholder}</Text>
        <div className="flex-grow-1">
          <Stack>
            {watchedEmails.map((email, i) => (
              <div key={i}>{email}</div>
            ))}
          </Stack>
        </div>
        <RiArrowDownSLine size={20} />
      </Stack>
    </OverlayTrigger>
  );
}
