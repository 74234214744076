import { Button, CloseButton, Stack } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdCancel, MdInfo } from "react-icons/md";
import { BootstrapVaraints } from "../../scss/bootstrap.type";
import Text from "../text/text";
import "./information.modal.styles.css";
export type InformationModalPromptType = Array<{
  label: string;
  callback: Function;
  variant?: BootstrapVaraints;
  mode?: "Contained" | "Text";
}>;
export interface InformationModalProps {
  type: "SUCCESS" | "ERROR" | "INFO" | "PROMPT";
  message: string;
  description?: string;
  onClose?: Function;
  prompts?: InformationModalPromptType;
}

export default function InformationModal({
  type,
  description,
  message,
  onClose,
  prompts,
}: InformationModalProps) {
  return (
    <div className="bg-white border p-2 rounded shadow">
      <Stack
        direction="horizontal"
        className="flex-grow-1 justify-content-between align-items-start"
      >
        {type == "SUCCESS" && <BsCheckCircleFill color="green" size={30} />}
        {type == "ERROR" && <MdCancel color="red" size={30} />}
        {(type == "INFO" || type === "PROMPT") && (
          <MdInfo color="orange" size={30} />
        )}

        <Stack>
          <Text className="flex-grow-1 mx-2 fw-bold">{message}</Text>
          {description && (
            <Text className="flex-grow-1 mx-2" variant="light">
              {description}
            </Text>
          )}
        </Stack>
        {type !== "PROMPT" && (
          <CloseButton onClick={() => onClose && onClose()} />
        )}
      </Stack>
      {type === "PROMPT" && (
        <Stack direction="horizontal" className="justify-content-end">
          {prompts?.map(({ label, variant, callback }, i) => (
            <Button
              className="mx-2"
              variant={variant}
              style={{ color: "white" }}
              onClick={() => callback()}
            >
              {label}
            </Button>
          ))}
        </Stack>
      )}
    </div>
  );
}
