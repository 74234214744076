import React, {useEffect, useRef, useState} from "react";
import {Form, FormControl, InputGroup, Stack} from "react-bootstrap";
import {MdDelete, MdEdit} from "react-icons/md";
import useOutsideClick from "../../hooks/outside-detector";
import Text, {TextProps} from "../text/text";
import "./input.scss";

export interface TextInputProps
  extends React.ComponentProps<typeof FormControl> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  wrapperClassName?: string;
  isInvalid?: boolean;
}
export default function TextInput({
  startIcon,
  endIcon,
  wrapperClassName,
  isInvalid,
  ...restofProps
}: TextInputProps) {
  return (
    <InputGroup className={`rounded ${wrapperClassName}`}>
      {startIcon && (
        <InputGroup.Text className="bg-white">{startIcon}</InputGroup.Text>
      )}

      <FormControl
        {...restofProps}
        className={`${restofProps.className} ${isInvalid ? "invalid" : ""}`}
      />
      {endIcon && (
        <InputGroup.Text className="bg-white">{endIcon}</InputGroup.Text>
      )}
    </InputGroup>
  );
}
// interface TextInputWithRefProps extends TextInputProps {
//   refWrapperClassName?: string;
//   titleInput?: boolean;
//   titleInputIcon?: React.ReactNode;
//   titleProps?: TextProps;
//   titleStackClassName?: string;
//   id?: string;
// }

export interface TextInputWithRefProps extends TextInputProps {
  refWrapperClassName?: string;
  titleInput?: boolean;
  titleInputIcon?: React.ReactNode;
  titleProps?: TextProps;
  titleStackClassName?: string;
  id?: string;
}
export const TextInputWithRef = React.forwardRef<
  HTMLInputElement,
  TextInputWithRefProps
>((props, ref) => {
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const titleInputRef = useRef<HTMLInputElement>(null);
  const detectorRef = useOutsideClick<HTMLInputElement>(
    (inputRef) => {
      setIsInputEnabled(false);
    },
    (inputRef) => {}
  );

  useEffect(() => {
    if (isInputEnabled) {
      titleInputRef.current?.click();
      titleInputRef.current?.focus();
    }
  }, [isInputEnabled]);

  return (
    <div ref={detectorRef} id={props.id} className={props.refWrapperClassName}>
      {props.titleInput ? (
        isInputEnabled ? (
          <Form.Control
            ref={titleInputRef}
            {...props}
            className={`${props.className} ${props.isInvalid ? "invalid" : ""}`}
          />
        ) : (
          <Stack direction="horizontal" className={props.titleStackClassName}>
            <Text
              {...props.titleProps}
              onClick={(e) => {
                setIsInputEnabled(true);
                props.titleProps?.onClick && props.titleProps.onClick(e);
                e.stopPropagation();
              }}
              className={`pointer flex-grow-1 ${
                props.titleProps?.className ?? ""
              }`}
            >
              {props.value}
            </Text>
            {props.titleInputIcon}
          </Stack>
        )
      ) : (
        <TextInput ref={ref} {...props} />
      )}
    </div>
  );
});
