import { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import { AddIcon } from "../../../components/icons/icons";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";

export default function CustomQuestions({}) {
  const [show, setShow] = useState(false);

  return (
    <Container style={{ maxWidth: "600px" }}>
      <Row className="mt-5">
        <Col>
          <Text mode="Heading">What information should be captured?</Text>
        </Col>
      </Row>
      <div className="my-3">
        <Text
          className="mb-3"
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? (
            <IoIosArrowDown size={20} />
          ) : (
            <IoIosArrowForward size={20} />
          )}{" "}
          Observation
        </Text>
        <Collapse in={show}>
          <ListGroup>
            <ListGroup.Item>
              <Text fontSize="1.3rem" fontWeight="500">
                Custom Questions
              </Text>
              <Text variant="light" fontSize="14px">
                Automatically ask these questions when a "Hazard" issue is
                reported.
              </Text>
            </ListGroup.Item>
            <ListGroup.Item>
              <Stack direction="horizontal" className="justify-content-between">
                <Text fontWeight="500">What needs to be done?</Text>
                <Stack direction="horizontal">
                  <span className="animated-btn">
                    <MdEdit size={20} className="text-primary" />
                  </span>
                  <span className="animated-btn">
                    <MdDelete size={20} />
                  </span>
                </Stack>
              </Stack>
            </ListGroup.Item>
            <ListGroup.Item>
              <Stack direction="horizontal" className="justify-content-between">
                <Text fontWeight="500">What caused it?</Text>
                <Stack direction="horizontal">
                  <span className="animated-btn">
                    <MdEdit size={20} className="text-primary" />
                  </span>
                  <span className="animated-btn">
                    <MdDelete size={20} />
                  </span>
                </Stack>
              </Stack>
            </ListGroup.Item>
            <ListGroup.Item>
              <Stack>
                <Stack
                  direction="horizontal"
                  className="justify-content-between"
                >
                  <TextInput />

                  <span className="animated-btn">
                    <MdDelete size={20} />
                  </span>
                </Stack>
                <Stack direction="horizontal" className="mt-2">
                  <Button>Save</Button>
                  <Button className="ms-2 border" variant="light">
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </ListGroup.Item>
            <ListGroup.Item>
              <Button
                variant="light"
                className="text-primary border fw-bold d-flex align-items-center"
              >
                <AddIcon size={20} className="me-2" /> Add Question
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Collapse>
      </div>
      <Row className="justify-content-md-end mt-3">
        <Col xs="12" md="auto">
          <Button className="w-100">Save and apply</Button>
        </Col>
        <Col xs="12" md="auto">
          <Button variant="light" className="border w-100 my-2 my-md-0">
            Back
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
