import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { IoMdAddCircle } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { Category } from "../../../api/categories";
import { TextInputWithRef } from "../../../components/input/input";
import Text from "../../../components/text/text";

type CategoriesPayload = {
  categories: Array<Category>;
};
export default function CategoriesList({}) {
  const { control } = useForm<CategoriesPayload>({
    defaultValues: {
      categories: [
        {
          id: 1,
          name: "Obersavtion",
        },
        {
          id: 2,
          name: "Maintenance",
        },
        {
          id: 3,
          name: "Incident",
        },
      ],
    },
  });

  const { fields, append } = useFieldArray({ control, name: "categories" });

  return (
    <Container fluid="sm" style={{ maxWidth: "600px" }}>
      <Row className="mt-5">
        <Col>
          <Text mode="Heading">
            What type of issues does your team need to report?
          </Text>
        </Col>
      </Row>
      {fields.map((category, i) => (
        <Stack key={category.id} direction="horizontal">
          <Controller
            control={control}
            name={`categories.${i}.name`}
            render={({ field }) => (
              <TextInputWithRef
                refWrapperClassName="w-100"
                className="flex-grow-1 border rounded my-1 fs-6"
                style={{ padding: "5px 15px" }}
                {...field}
              />
            )}
          />

          <span className="animated-btn">
            <MdClose size={25} />
          </span>
        </Stack>
      ))}
      <Stack
        direction="horizontal"
        className="fs-6 text-primary hover-btn pointer"
        onClick={() => {
          append({ name: "" });
        }}
      >
        <IoMdAddCircle className="me-2" /> Add Category
      </Stack>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button>Continue</Button>
        </Col>
      </Row>
    </Container>
  );
}
