import {
  Document as PDF,
  Font,
  Image as PDFImage,
  Page,
  StyleSheet,
  Text as PDFText,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import {Fragment} from "react";
import {Col, Container, Image, Row, Stack} from "react-bootstrap";
import {UseFormReturn} from "react-hook-form";
import {HiPhotograph} from "react-icons/hi";
import Inspection from "../../../api/inspections";
import {TemplateReportPayload} from "../../../api/template";
import Text from "../../../components/text/text";
import {useBusinessProfile} from "../../../hooks/business";

interface ReportPDFPreviewProps {
  form?: UseFormReturn<TemplateReportPayload, object>;
  inspectionReport?: UseFormReturn<Inspection.FetchInspectionResponse, object>;
}
export default function ReportPDFPreview({
  form,
  inspectionReport,
}: ReportPDFPreviewProps) {
  const uploadedLogo = form?.watch("logoBase64");
  const showScore = form?.watch("showScores");
  const showFlagged = form?.watch("showFlaggedItems");
  const showActions = form?.watch("showActions");
  const showDisclaimer = form?.watch("showDisclaimer");
  const showInspectionResults = form?.watch("showInspectionResults");
  const showMediaSummary = form?.watch("showMediaSummary");

  const {businessName} = useBusinessProfile();

  return (
    <div className="bg-white p-5">
      <Container fluid>
        {form?.getValues("logoBase64") && (
          <div
            className={`${
              !uploadedLogo ? "report-logo-upload" : ""
            } d-inline-block p-3 rounded`}
          >
            {uploadedLogo && uploadedLogo?.length > 0 ? (
              <Image
                src={`data:image/png;base64,${uploadedLogo}`}
                style={{
                  width: "10rem",
                  height: "10rem",
                  objectFit: "contain",
                }}
              />
            ) : (
              <div>
                <HiPhotograph size={50} /> Your template/report logo
              </div>
            )}
          </div>
        )}
        <Text className="mt-5" fontWeight="500">
          {form?.getValues("title") ?? inspectionReport?.getValues("title")}
        </Text>
        <Stack direction="horizontal" className="justify-content-between">
          <Text className="mt-5" fontWeight="500">
            {businessName}
          </Text>
          <Text className="mt-5 text-danger" fontWeight="500">
            {form?.watch("status")}
          </Text>
        </Stack>
        {/* <Row className="mt-3">
          {showScore && (
            <Col>
              <Stack
                direction="horizontal"
                className="PDF-preview-highlighted justify-content-between"
              >
                <Text fontWeight="500">Score</Text>
                <Text fontWeight="500">0%</Text>
              </Stack>
            </Col>
          )}
          {showFlagged && (
            <Col>
              <Stack
                direction="horizontal"
                className="PDF-preview-highlighted justify-content-between"
              >
                <Text fontWeight="500">Flagged items</Text>
                <Text fontWeight="500">1</Text>
              </Stack>
            </Col>
          )}
          {showActions && (
            <Col>
              <Stack
                direction="horizontal"
                className="PDF-preview-highlighted justify-content-between"
              >
                <Text fontWeight="500">Actions</Text>
                <Text fontWeight="500">1</Text>
              </Stack>
            </Col>
          )}
        </Row> */}

        <div className="border-bottom py-2">
          <Text fontWeight="bold">
            Conducted by{" "}
            <span className="fw-normal ms-3">
              {inspectionReport?.getValues("submittedBy")}
            </span>
          </Text>
        </div>
        {/* <div className="border-bottom py-2">
  <Text fontWeight="bold">
    Site Conducted <span className="fw-normal ms-3">Area 1</span>
  </Text>
</div> */}
        {/* <div className="border-bottom py-2">
  <Text fontWeight="bold">
    Location <span className="fw-normal ms-3">Location 1</span>
  </Text>
</div> */}
        <div className="border-bottom py-2">
          <Text fontWeight="bold">
            Prepared by{" "}
            <span className="fw-normal ms-3">
              {inspectionReport?.getValues("createdBy")}
            </span>
          </Text>
        </div>
        {showDisclaimer && (
          <Fragment>
            <Text className="PDF-preview-highlighted mt-3" fontWeight="500">
              Disclaimer
            </Text>
            <Text>
              The assessors believe the information contained within this risk
              assessment report to be correct at the time of printing. The
              assessors do not accept responsibility for any consequences
              arising from the use of the information herein. The report is
              based on matters which were observed or came to the attention of
              the assessors during the day of the assessment and should not be
              relied upon as an exhaustive record of all possible risks or
              hazards that may exist or potential improvements that can be made.
              Information on the latest workers compensation and OHS / WHS laws
              can be found at the relevant State WorkCover / WorkSafe Authority.
            </Text>
          </Fragment>
        )}

        {(showActions || showFlagged) && (
          <Text className="PDF-preview-highlighted mt-3" fontWeight="500">
            {showActions && showFlagged && "Flagged items and Actions"}
            {showActions && !showFlagged && "Actions"}
            {!showActions && showFlagged && "Flagged items"}
          </Text>
        )}

        {showInspectionResults && (
          <div>
            <Text className="PDF-preview-highlighted mt-3" fontWeight="500">
              Untitled Page
            </Text>
            <div className="border-bottom py-2">
              <Text fontWeight="bold">Question 1</Text>
              <Text>Answer 1</Text>
            </div>
          </div>
        )}

        {inspectionReport
          ?.getValues("pages")
          ?.sort((a, b) => a.sequence - b.sequence)
          ?.map(({title, questions}, pageIndex) => (
            <div key={pageIndex}>
              <Text className="PDF-preview-highlighted mt-3" fontWeight="500">
                {title}
              </Text>
              {questions
                ?.sort((a, b) => a.sequence - b.sequence)
                ?.map((question, questionIndex) => (
                  <div className="border-bottom py-2" key={questionIndex}>
                    <Text fontWeight="bold">{question.title}</Text>
                    <Text>
                      {question.type === "DATETIME"
                        ? moment
                            .unix(Number(question.value))
                            .format("DD-MM-YYYY hh:mm A")
                        : question.value ??
                          question.multiOptions
                            ?.filter(({selected}) => selected)
                            ?.map(({title}) => title)
                            .join(", ")}
                    </Text>
                    <Row>
                      {question?.mediaPaths?.map((path, i) => (
                        <Col key={i} xs="auto" className="py-3">
                          <div
                            className="PDF-preview-image-container"
                            style={{width: "200px", height: "200px"}}
                          >
                            <Image
                              src={path}
                              style={{height: "100%", width: "100%"}}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ))}
            </div>
          ))}

        {showMediaSummary && (
          <Row>
            {Array.from({length: 5}).map((_, i) => (
              <Col key={i} xs="6" className="py-3">
                <div className="PDF-preview-image-container">
                  <HiPhotograph size={50} />
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
}

export function ReportPDF({
  form,
  inspectionReport,
  businessName,
  companyLogo,
}: ReportPDFPreviewProps & {businessName: string; companyLogo?: string}) {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxP.ttf",
        fontStyle: "normal",
        fontWeight: 100,
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf",
        fontStyle: "normal",
        fontWeight: 300,
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf",
        fontStyle: "normal",
        fontWeight: 400,
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf",
        fontStyle: "normal",
        fontWeight: 500,
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf",
        fontStyle: "normal",
        fontWeight: 700,
      },
      {
        src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAw.ttf",
        fontStyle: "normal",
        fontWeight: 900,
      },
    ],
  });
  const multipleOptions = [
    {
      title: "Excellent",
      color: "#3dc08b",
      score: 4,
    },
    {
      title: "Good",
      color: "#3d86c6",
      score: 3,
    },
    {
      title: "Satisfactory",
      color: "#ff9932",
      score: 2,
    },
    {
      title: "Poor",
      color: "#d95b5d",
      score: 1,
    },
    {
      title: "NA",
      color: "#707070",
    },

    {title: "Yes", color: "#3dc08b", score: 4},
    {title: "No", color: "#d95b5d", score: 0},
    {
      title: "NA",
      color: "#707070",
    },

    {title: "Safe", color: "#3dc08b", score: 1},
    {title: "At Risk", color: "#d95b5d", score: 0},
    {
      title: "NA",
      color: "#707070",
    },

    {title: "Pass", color: "#3dc08b", score: 1},
    {title: "Fail", color: "#d95b5d", score: 0},
    {
      title: "NA",
      color: "#707070",
    },

    {
      title: "Compliant",
      color: "#3dc08b",
      score: 1,
    },
    {
      title: "Non-Compliant",
      color: "#d95b5d",
      score: 0,
    },
    {
      title: "NA",
      color: "#707070",
    },
  ];

  const scoreLabels = inspectionReport
    ?.getValues("pages")
    ?.reduce((prev, curr) => {
      return [...prev, ...curr?.questions];
    }, [] as Array<Inspection.FetchInspectionResponseQuestionType>)
    .reduce((prev, curr) => {
      return [
        ...prev,
        ...(curr.multiOptions
          ?.filter(({selected, title}) => selected && title !== "NA")
          .map(({title}) => title) ?? []),
      ];
    }, [] as Array<string>);

  const logoPath = inspectionReport?.getValues("logoPath");
  const templateLogo = inspectionReport?.getValues("templateImage.url");

  return (
    <PDF>
      <Page size="A4" style={{padding: 30}} break>
        {(companyLogo || logoPath || templateLogo) && (
          <PDFImage
            src={{
              uri: templateLogo ?? logoPath ?? companyLogo ?? "",
              headers: {"Cache-Control": "no-cache"},
              method: "GET",
              body: "",
            }}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "contain",
            }}
          />
        )}

        <PDFText style={PDFStyle.title}>
          {form?.getValues("title") ?? inspectionReport?.getValues("title")}
        </PDFText>

        <View style={[{marginVertical: 5}, PDFStyle.subHeadingContainer]}>
          <PDFText style={PDFStyle.subTitle}>
            {businessName} - {inspectionReport?.getValues("submittedBy")}
          </PDFText>
          <PDFText
            style={[PDFStyle.subTitle, {color: "green", textAlign: "right"}]}
          >
            Completed
          </PDFText>
        </View>
        <View
          style={[
            {
              backgroundColor: "#F3F3F3",
              flexDirection: "row",
              alignItems: "center",
              padding: 5,
              marginVertical: 10,
            },
          ]}
        >
          <PDFText
            style={{
              fontSize: 12,
              fontFamily: "Roboto",
              fontWeight: 400,
              marginRight: 10,
            }}
          >
            Score:
          </PDFText>
          <PDFText style={PDFStyle.questionHeading}>
            {(
              ((scoreLabels?.reduce((prev, curr) => {
                return (
                  prev +
                  (multipleOptions.find(({title}) => title === curr)?.score ??
                    0)
                );
              }, 0) ?? 4) /
                ((scoreLabels?.length ?? 1) * 4)) *
              100
            ).toFixed(2)}
            %
          </PDFText>
        </View>

        {inspectionReport
          ?.getValues("pages")
          ?.sort((a, b) => a.sequence - b.sequence)
          ?.map(({title, questions}, pageIndex) => (
            <View key={pageIndex}>
              <PDFText style={PDFStyle.pageHeading}>{title}</PDFText>
              <View style={{padding: 5}}>
                {questions
                  ?.sort((a, b) => a.sequence - b.sequence)
                  ?.map((question, i) => (
                    <View key={i} style={PDFStyle.questionContainer}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <PDFText style={[PDFStyle.questionHeading, {flex: 1}]}>
                          {question.title}
                        </PDFText>

                        {question.type === "MULTICHOICE_RADIO" ||
                        question.type === "MULTICHOICE_CHECKBOX" ? (
                          <View
                            style={{
                              backgroundColor:
                                multipleOptions?.find(
                                  ({title}) =>
                                    title ===
                                    question.multiOptions?.find(
                                      ({selected}) => selected
                                    )?.title
                                )?.color ?? "gray",
                              padding: 5,
                              width: "200px",
                            }}
                          >
                            <PDFText
                              style={{
                                color: "white",
                                textAlign: "center",
                                fontSize: 12,
                              }}
                            >
                              {question.multiOptions
                                ?.filter(({selected}) => selected)
                                ?.map(({title}) => title)
                                .join(", ")}
                            </PDFText>
                          </View>
                        ) : (
                          <PDFText
                            style={[
                              PDFStyle.answer,
                              {
                                flex: 1,
                                textAlign: "right",
                              },
                            ]}
                          >
                            {question.type === "DATETIME"
                              ? moment
                                  .unix(Number(question.value))
                                  .format("DD-MM-YYYY hh:mm A")
                              : question.value}
                          </PDFText>
                        )}
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginVertical: 10,
                          flexWrap: "wrap",
                        }}
                      >
                        {question?.mediaPaths?.map((path, imageIndex) => (
                          // <Link src={path}>
                          <PDFImage
                            key={imageIndex}
                            src={{
                              uri: path,
                              headers: {"Cache-Control": "no-cache"},
                              method: "GET",
                              body: "",
                            }}
                            style={{
                              height: "80px",
                              width: "80px",
                              marginRight: "10px",
                            }}
                          />
                          // </Link>
                        ))}
                      </View>
                    </View>
                  ))}
              </View>
            </View>
          ))}
      </Page>
    </PDF>
  );
}

const PDFStyle = StyleSheet.create({
  title: {
    fontWeight: 500,
    marginTop: 20,
    fontSize: 20,
    fontFamily: "Roboto",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: 12,
    // paddingTop: 5,
    color: "gray",
    flex: 1,
  },

  // Actual Styles
  subHeadingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pageHeading: {
    fontSize: 15,
    fontFamily: "Roboto",
    fontWeight: 500,
    backgroundColor: "#F3F3F3",
    padding: 5,
  },
  questionHeading: {
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: 500,
  },
  answer: {
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#787878",
  },
  questionContainer: {
    borderBottomColor: "#e9ecef",
    borderBottomWidth: 1,
    marginVertical: 5,
  },
});
