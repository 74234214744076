import {
  Container,
  Nav,
  Image,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Button,
  NavDropdown,
} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
// import { useBusinessProfile } from "../../hooks/business";
import HeaderLogo from "../../assets/logo-horizontal.svg";
import "./header.styles.scss";
import React, { Fragment, useEffect, useState } from "react";
import {
  MdAccessTimeFilled,
  MdDashboard,
  MdOutlineLocationCity,
} from "react-icons/md";
import { BsPersonBadgeFill } from "react-icons/bs";
import {
  FaCalendarAlt,
  FaClipboardCheck,
  FaFilePrescription,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../components/text/text";
import { IoMdArrowDropdown } from "react-icons/io";
// import { useUserProfile } from "../../hooks/user";
// import { getHeight } from "../../api/components";
import { TiClipboard } from "react-icons/ti";
import { Link } from "react-router-dom";
import { AiOutlineAudit, AiOutlineUser } from "react-icons/ai";
import { HiSwitchHorizontal } from "react-icons/hi";
import { CgLink } from "react-icons/cg";
import { useBusinessProfile } from "../../hooks/business";
import { useUserProfile } from "../../hooks/user";

function getHeight(elementId: string): number {
  return document?.getElementById(elementId)?.clientHeight || 0;
}

function Header({}) {
  const browserLocation = useLocation();
  const business = useBusinessProfile();
  const { title, profileUrl } = useUserProfile();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_TIMESHEET_URL;

  let topMenu = [
    {
      title: "Dashboard",
      url: `${baseURL}/app/dashboard`,
      Icon: MdDashboard,
    },
    // {
    //   title: "News Feed",
    //   url: "/app/feed",
    // },
    // {
    //   title: "Tasks",
    //   url: "",
    // },
    {
      title: "Location",
      url: `${baseURL}/app/location`,
      Icon: MdOutlineLocationCity,
    },
    {
      title: "Employee",
      url: `${baseURL}/app/employee`,
      Icon: BsPersonBadgeFill,
    },
    {
      title: "Schedule",
      url: `${baseURL}/app/schedule`,
      Icon: FaCalendarAlt,
    },
    // {
    //   title: "Timesheet",
    //   url: "/app/timesheet",
    //   Icon: MdAccessTimeFilled,
    //   isDropDown: true,
    //   subMenu: [
    //     { title: "Approve Timesheet", url: "/app/timesheet" },
    //     { title: "Export Timesheet", url: "/app/export-timesheet" },
    //   ],
    // },
    {
      title: "Reports",
      url: `${baseURL}/app/report`,
      Icon: FaFilePrescription,
      callback: undefined,
    },
    {
      title: "Audit",
      url: "/app/dashboard",
      Icon: FaClipboardCheck,
      callback: undefined,
      subMenu: [
        { title: "Templates", url: "/app/template" },
        { title: "Inspections", url: "/app/inspections" },
      ],
    },
  ];

  const [shrinkNavbar, setShrinkNavbar] = useState(false);

  useEffect(() => {
    function scrollLisenter() {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > getHeight("navbar")) {
        setShrinkNavbar(true);
      } else {
        setShrinkNavbar(false);
      }
    }
    window.addEventListener("scroll", scrollLisenter);
    return () => {
      window.removeEventListener("scroll", scrollLisenter);
    };
  }, []);

  return (
    <Navbar
      expand="md"
      fixed="top"
      className="shadow-sm p-0 "
      bg="white"
      expanded={isSidebarOpen}
    >
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={business.logoPath || HeaderLogo}
            style={{
              height: "55px",
              objectFit: "cover",
              aspectRatio: "auto",
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
        />
        <Navbar.Offcanvas placement="end">
          <Offcanvas.Header
            closeButton
            onHide={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
          >
            <Offcanvas.Title>
              <ProfileButton />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="justify-content-between align-items-center">
            <Nav className="justify-content-center" style={{ flex: 1 }}>
              {topMenu.map(({ title, url, Icon, callback, subMenu }, index) =>
                subMenu ? (
                  <NavDropdown
                    className="no-carret "
                    title={
                      <Nav.Link
                        key={index}
                        href={url}
                        className={` nav-option ${
                          browserLocation.pathname === url ? "active" : ""
                        }`}
                        onClick={callback}
                      >
                        <Icon className="nav-option-icon" size={20} />

                        <div
                          className={`nav-option-text ${
                            isSidebarOpen
                              ? ""
                              : shrinkNavbar
                              ? "magic-nav-text scrolled"
                              : "magic-nav-text unscrolled"
                          }`}
                        >
                          {title}
                        </div>
                      </Nav.Link>
                    }

                    // className={`nav-option ${
                    //   browserLocation.pathname === url ? "active" : ""
                    // }`}
                  >
                    {subMenu?.map(({ title, url }) => (
                      <NavDropdown.Item
                        eventKey={url}
                        onClick={() => {
                          navigate(url);
                        }}
                      >
                        {title}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    key={index}
                    href={url}
                    className={`nav-option ${
                      browserLocation.pathname === url ? "active" : ""
                    }`}
                    onClick={callback}
                  >
                    <Icon className="nav-option-icon" size={20} />

                    <div
                      className={`nav-option-text ${
                        isSidebarOpen
                          ? ""
                          : shrinkNavbar
                          ? "magic-nav-text scrolled"
                          : "magic-nav-text unscrolled"
                      }`}
                    >
                      {title}
                    </div>
                  </Nav.Link>
                )
              )}
            </Nav>

            {!isSidebarOpen && <ProfileButton />}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;

function ProfileButton({}) {
  const { title, profileUrl } = useUserProfile();
  const business = useBusinessProfile();
  const navigate = useNavigate();
  return (
    <OverlayTrigger
      rootClose
      placement="bottom"
      trigger="click"
      overlay={
        <Popover>
          <div className="white-container">
            <Container>
              <Row>
                <Col>
                  <Text fontSize="1.2vw" color="grey">
                    {title}
                  </Text>
                </Col>
              </Row>
              {/* <Row>
                <Col xs="auto" className="me-0 pe-0">
                  <TiClipboard />
                </Col>
                <Col>
                  <Text fontSize="14px">
                    Account Overview{" "}
                    <div style={{ fontSize: "12px" }}>
                      Edit your global profile and manage billing.
                    </div>
                  </Text>
                </Col>
              </Row> */}
              {business.businessName && (
                <Row className="mt-2">
                  <Col>
                    <Text>{business.businessName}</Text>
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col>
                  <a
                    href={`${process.env.REACT_APP_TIMESHEET_URL}/app/profile`}
                    className="popover-button"
                  >
                    <AiOutlineUser /> Profile
                  </a>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <a
                    href={`${process.env.REACT_APP_TIMESHEET_URL}/app/business-selection`}
                    className="popover-button"
                  >
                    <HiSwitchHorizontal /> Switch Business
                  </a>
                </Col>
              </Row>
              {/* <Row className="mt-2">
                <Col>
                  <Link to="/app/business-selection" className="popover-button">
                    <CgLink /> Integrations
                  </Link>
                </Col>
              </Row> */}
              {/* <Dropdown.Divider /> */}

              <Row className="mt-2">
                <Col>
                  <Button
                    // size="sm"

                    className="w-100"
                    onClick={() => {
                      localStorage.removeItem("user");
                      localStorage.removeItem("business");
                      localStorage.removeItem("token");
                      window.location.href =
                        process.env.REACT_APP_TIMESHEET_URL!;
                    }}
                    variant="primary"
                  >
                    Logout
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </Popover>
      }
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          cursor: "pointer",
        }}
      >
        <div className="me-2">
          {profileUrl ? (
            <Image
              src={profileUrl}
              style={{ height: 30, width: 30 }}
              roundedCircle
            />
          ) : (
            <div
              className="user-initials p-0 m-0 d-flex justify-content-center align-items-center "
              style={{ height: 30, width: 30 }}
            >
              {title && title.charAt(0).toUpperCase()}
            </div>
          )}
        </div>
        <Text fontSize="14px" color="grey">
          Hello, {title.split(" ")[0]}
          <IoMdArrowDropdown />
        </Text>
      </div>
    </OverlayTrigger>
  );
}
