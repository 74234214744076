import React from "react";
import axiosInstance from "./axiosInstance";

// Get Initials
export const getInitials = (name: string): string => {
  const names = name.split(" ");
  const initials = names.map((n) => n[0]).join("");
  return initials;
};

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const HexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export async function getBase64(event: React.ChangeEvent<HTMLInputElement>) {
  return new Promise<string | undefined>((resolve, reject) => {
    let file = event.target.files?.[0];
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader?.result?.toString().split(",")?.[1]);
      };
      reader.onerror = function (error) {
        reject("Error: " + error);
      };
    } else {
      reject("File not found");
    }
  });
}

export async function getBase64FromFile(file: File) {
  return new Promise<string | undefined>((resolve, reject) => {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader?.result?.toString().split(",")?.[1]);
      };
      reader.onerror = function (error) {
        reject("Error: " + error);
      };
    } else {
      reject("File not found");
    }
  });
}

export function getUserLocation(
  success: (position: GeolocationPosition) => void,
  error: (positionError: GeolocationPositionError) => void
) {
  return navigator.geolocation.getCurrentPosition(success, error, {
    enableHighAccuracy: true,
    timeout: 10000,
  });
}

export type LocationType = {
  address?: string;
  latitude?: string;
  longitude?: string;
  country?: string;
};
export function FetchLocations(
  address: string,
  success: (addresses: Array<LocationType>) => void,
  error: (error: string) => void
) {
  axiosInstance
    .post(`/comply-tsfm/api/utility/addresses`, {address})
    .then(({data}) => {
      success(data);
    })
    .catch((e) => {
      error(e.response?.data?.message ?? e.response?.data?.error);
    });
}
