import moment from "moment";
import {Fragment, useState} from "react";
import {
  Button,
  Col,
  Container,
  FormCheck,
  ListGroup,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {GoPrimitiveDot} from "react-icons/go";
import {MdOutlineFileDownload, MdSettings} from "react-icons/md";
import {useLinkClickHandler, useLocation} from "react-router-dom";
import {ActionListType, ActionStatus} from "../../api/actions";
import FilterButton from "../../components/filter-button/filter-button";
import {AddIcon, SearchIcon} from "../../components/icons/icons";
import TextInput from "../../components/input/input";

import Text from "../../components/text/text";
import "./actions.scss";
import BottomActionBar from "./components/bottom-action-bar";
import CreateAction from "./modal/create-action";
import {FcHighPriority, FcLowPriority, FcMediumPriority} from "react-icons/fc";

type FilterPayload = {
  status: Array<"TODO" | "INPROGRESS" | "COMPLETE" | "CAN'TDO">;
  assignee: Array<number>;
  location: Array<number>;
  template: Array<number>;
  inspection: Array<number>;
  creator: Array<number>;
  dueDate: {
    from: number;
    to: number;
  };
  created: {
    from: number;
    to: number;
  };
  updated: {
    from: number;
    to: number;
  };
};
export default function Actions({}) {
  let location = useLocation();
  const navigateToDetails = useLinkClickHandler("/app/actions/:id", {
    state: {
      backgroundLocation: location,
    },
  });

  const {control, handleSubmit, reset} = useForm<FilterPayload>({
    defaultValues: {
      status: undefined,
      assignee: undefined,
      location: undefined,
      template: undefined,
      inspection: undefined,
      creator: undefined,
      dueDate: {
        from: undefined,
        to: undefined,
      },
      created: {
        from: undefined,
        to: undefined,
      },
      updated: {
        from: undefined,
        to: undefined,
      },
    },
  });
  const filtersOptions = [
    {
      label: "Status",
      key: "status",
    },
    {
      label: "Assignee",
      key: "assignee",
    },
    {
      label: "Location",
      key: "location",
    },
    {
      label: "Template",
      key: "template",
    },
    {
      label: "Inspection",
      key: "inspection",
    },
    {
      label: "Creator",
      key: "createdBy",
    },
    {
      label: "Due Date",
      key: "dueDate",
    },
    {
      label: "Created",
      key: "createdOn",
    },
    {
      label: "Updated",
      key: "lastUpdated",
    },
  ];

  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string;
      key: string;
    }>
  >([]);

  const [actions, setActions] = useState<Array<ActionListType>>([
    {
      id: 1,
      name: "Action 1",
      dueDate: 1597354800000,
      createdBy: {
        name: "John Doe",
        id: 1,
      },
      lastUpdated: 1597354800000,
      status: "TODO",
      priority: "Low",
    },
    {
      id: 2,
      name: "Action 2",
      dueDate: 1597354800000,
      createdBy: {
        name: "John Doe",
        id: 1,
      },
      lastUpdated: 1597354800000,
      status: "INPROGRESS",
      priority: "Medium",
    },

    {
      id: 3,
      name: "Action 3",
      dueDate: 1658722867,
      createdBy: {
        name: "John Doe",
        id: 1,
      },
      lastUpdated: 1658722867,
      status: "COMPLETED",
      priority: "High",
    },
  ]);

  const [sortBy, setSortBy] = useState<{
    label: string;
    key: string;
  }>({
    label: "Due Date",
    key: "dueDate",
  });

  const [selectedActions, setSelectedActions] = useState<Array<number>>([]);

  function ActionPriority(priority: string): {
    text: string;
    color: string;
    Icon: React.ReactNode;
  } {
    const ActionPriorityObj: any = {
      Low: {
        text: "Low",
        color: "rgb(76, 175, 80)",
        Icon: <FcLowPriority />,
      },
      Medium: {
        text: "Medium",
        color: "rgb(255, 193, 7)",
        Icon: <FcMediumPriority />,
      },
      High: {
        text: "High",
        color: "red",
        Icon: <FcHighPriority />,
      },
    };
    return ActionPriorityObj[priority];
  }

  return (
    <Fragment>
      <CreateAction />
      <Container className="p-md-5 p-sm-2 p-2 position-relative vh-100 overflow-auto">
        <Row className="">
          <Col>
            <Text mode="Heading">Actions</Text>
          </Col>
          <Col className="d-flex justify-content-end">
            <Stack direction="horizontal">
              <Button
                variant="link"
                className="text-decoration-none border me-3"
              >
                <MdSettings /> Settings
              </Button>
              <Button>
                <AddIcon /> Create Action
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col>
            <div className="d-inline-block">
              <TextInput
                placeholder="Search"
                startIcon={<SearchIcon size={20} />}
              />
            </div>
          </Col>
          <Col className="justify-content-end d-flex">
            <Stack direction="horizontal">
              <FormCheck
                type="switch"
                style={{transform: "scale(1.5)", display: "inline-block"}}
                className="me-2"
              />
              <Text fontWeight="500">Hide Closed</Text>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-3">
          {appliedFilters.map((af, i) => (
            <Controller
              key={i}
              control={control}
              name={af.key as any}
              render={({field: {onChange, value, name}}) => (
                <Col xs="auto">
                  <FilterButton
                    //   open={af.open}
                    //   setOpen={(open) => {
                    //     setAppliedFilters((prev) => {
                    //       return [...prev.slice(0, i), { ...prev[i], open }];
                    //     });
                    //   }}
                    type={name as any}
                    label={af.label}
                    defaultValue={value}
                    onChange={onChange}
                    onDone={handleSubmit(
                      (data) => {
                        // console.log({ data });
                      },
                      (error) => {
                        // console.log(error);
                      }
                    )}
                    onClear={() => {
                      reset({[name]: undefined});
                    }}
                  />
                </Col>
              )}
            />
          ))}

          <Col xs="auto">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Popover
                  className="qs-dropdown-popup p-0 py-2"
                  style={{minWidth: "160px"}}
                >
                  {filtersOptions.map((filter, i) => (
                    <Text
                      key={i}
                      className="inspection-filter-option px-3 pointer"
                      onClick={() => {
                        setAppliedFilters([...appliedFilters, filter]);
                        document.body.click();
                      }}
                    >
                      {filter.label}
                    </Text>
                  ))}
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-decoration-none fw-bold d-flex align-items-center"
              >
                <AddIcon size={20} className="me-2" /> Add filter
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Stack direction="horizontal">
              <FormCheck
                type="checkbox"
                className="action-check-box me-3"
                checked={selectedActions.length === actions.length}
                onChange={(e: any) => {
                  setSelectedActions(
                    e.target.checked ? actions.map((a) => a.id) : []
                  );
                }}
              />
              <Text fontWeight="500" mode="SubHeading">
                Select All
              </Text>
            </Stack>
          </Col>
          <Col className="d-flex justify-content-center">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Popover className="qs-dropdown">
                  {[
                    {label: "Creation date", key: "createdOn"},
                    {
                      label: "Due date",
                      key: "dueDate",
                    },
                    {
                      label: "Priority",
                      key: "priority",
                    },
                    {
                      label: "Modified date",
                      key: "status",
                    },
                  ].map(({label, key}, i) => (
                    <div
                      key={key}
                      onClick={() => {
                        setSortBy({
                          label,
                          key,
                        });
                      }}
                    >
                      {label}
                    </div>
                  ))}
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-decoration-none fw-bold border"
              >
                {sortBy.label}
              </Button>
            </OverlayTrigger>
          </Col>
          <Col className="d-flex justify-content-end">
            <Stack direction="horizontal">
              <Button
                variant="link"
                className="text-decoration-none border fw-bold d-lg-flex align-items-center d-none"
              >
                <MdOutlineFileDownload size={20} className="me-1" /> Download
                CSV
              </Button>
              <Text className="ms-3" variant="light" fontWeight="500">
                {actions.length} results
              </Text>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-3">
          <ListGroup>
            {actions.map(
              (
                {createdBy, dueDate, id, lastUpdated, name, priority, status},
                i
              ) => {
                let lastUpdatedOn = moment.unix(lastUpdated);
                let dueDateOn = moment.unix(dueDate);

                let isDueDatePassed = dueDateOn.isBefore(moment());

                return (
                  <ListGroup.Item onClick={navigateToDetails} key={i}>
                    <Row className="align-items-start">
                      <Col
                        xs="auto"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <FormCheck
                          type="checkbox"
                          className="action-check-box"
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                          checked={selectedActions.includes(id)}
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              setSelectedActions([...selectedActions, id]);
                            } else {
                              setSelectedActions(
                                selectedActions.filter((s) => s !== id)
                              );
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <Text fontWeight="500" mode="SubHeading">
                          {name}
                        </Text>
                        <Row className="mt-1 justify-content-between">
                          <Col lg="auto" md="12">
                            <Stack direction="horizontal">
                              <Text
                                fontWeight="500"
                                color={ActionPriority(priority).color}
                              >
                                {ActionPriority(priority).Icon}
                                {ActionPriority(priority).text}
                              </Text>
                              <GoPrimitiveDot
                                className="text-muted mx-1"
                                size={10}
                              />
                              <Text
                                variant={isDueDatePassed ? "danger" : "light"}
                              >
                                Due {dueDateOn.format("DD MMM YYYY")}
                              </Text>
                              <GoPrimitiveDot
                                className="text-muted mx-1"
                                size={10}
                              />
                              <Text variant="light">
                                Created by {createdBy.name}
                              </Text>
                            </Stack>
                          </Col>

                          <Col
                            lg="auto"
                            md="12"
                            className=" mt-sm-1 mt-lg-0 justify-content-lg-end d-flex"
                          >
                            <Stack
                              direction="horizontal"
                              className="justify-content-between d-flex w-100"
                            >
                              <Text variant="light">
                                Updated {lastUpdatedOn.fromNow()}
                              </Text>
                              <GoPrimitiveDot
                                className="text-muted mx-1 d-lg-block d-none "
                                size={10}
                              />
                              <Text color={ActionStatus(status).color}>
                                {ActionStatus(status).text}
                              </Text>
                            </Stack>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              }
            )}
          </ListGroup>
        </Row>
      </Container>
      {selectedActions.length > 0 && (
        <BottomActionBar selections={selectedActions} />
      )}
    </Fragment>
  );
}
