import { useState } from "react";
import { Button, ListGroup, Stack } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";

export default function QuestionItem({ question }: { question: string }) {
  const [isEditing, setIsEditing] = useState(false);
  if (isEditing) {
    return (
      <Stack>
        <Stack direction="horizontal" className="justify-content-between">
          <TextInput defaultValue={question} />

          <span className="animated-btn">
            <MdDelete size={20} />
          </span>
        </Stack>
        <Stack direction="horizontal" className="mt-2">
          <Button>Save</Button>
          <Button
            className="ms-2 border"
            variant="light"
            onClick={() => {
              setIsEditing(false);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack direction="horizontal" className="justify-content-between">
        <Text fontWeight="500">{question}</Text>
        <Stack direction="horizontal">
          <span
            className="animated-btn"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <MdEdit size={20} className="text-primary" />
          </span>
          <span className="animated-btn">
            <MdDelete size={20} />
          </span>
        </Stack>
      </Stack>
    );
  }
}
