import updateArray from "immutability-helper";
import {Fragment, useEffect, useRef, useState} from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  Image,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import {Controller, UseFormReturn} from "react-hook-form";
import {GrUpload} from "react-icons/gr";
import {MdDelete, MdOutlineArrowBack} from "react-icons/md";
import {RiArrowDownSLine, RiImageAddFill} from "react-icons/ri";
import {getBase64} from "../../../../api/general";
import Template from "../../../../api/template";
import {AddIcon} from "../../../../components/icons/icons";
import {TextInputWithRef} from "../../../../components/input/input";
import Text from "../../../../components/text/text";
import DraggableContainer from "../../components/draggable-container";
import RenderedTemplate from "../../rendered-template";
import "./../template-editor.scss";

interface BuildTemplateProps {
  showPreview: boolean;
  togglePreview: Function;

  templateForm: UseFormReturn<Template.ITemplate, any>;
}

export default function BuildTemplate({
  showPreview,
  togglePreview,
  templateForm,
}: BuildTemplateProps) {
  // const [showPreview, setShowPreview] = useState(false);
  const [openedPages, setOpenedPages] = useState<Array<string>>([]);
  // const templateForm = useForm<TemplateCreatePayload>({
  //   defaultValues: {
  //     logoBase64: "",
  //     title: "Untitled Template",
  //     discription: "",
  //     pages: [
  //       {
  //         // id: 1,
  //         title: "Title Page",
  //         subTitle:
  //           "The Title Page is the first page of the template. You can customize the Title Page below",
  //         questions: TITLE_PAGE_QUESTIONS,
  //       },
  //     ],
  //   },
  // });

  const logoInputRef = useRef<HTMLInputElement>(null);
  const watchedLogo = templateForm.watch("templateImage.base64");
  const watchLogoURL = templateForm.watch("templateImage.url");
  let watchedPages = templateForm?.watch("pages");

  useEffect(() => {
    if (watchedPages.length > 0) {
      setOpenedPages(watchedPages.map((page, index) => page?.id ?? "") ?? []);
    }
  }, [watchedPages]);

  return (
    <Fragment>
      <Container
        fluid
        className="pt-3 vh-100 overflow-auto"
        style={{
          paddingBottom: "400px",
        }}
      >
        <Row>
          <Col
            // md="12"
            // lg="12"
            // xl="9"
            // style={{
            //   padding: "0rem 4rem",
            // }}
            className="px-md-6"
          >
            <Row>
              <Col
                xs="12"
                sm="auto"
                className="d-flex justify-content-center my-2 my-md-0"
              >
                <div className="template-image-upload position-relative">
                  {watchLogoURL?.length > 0 || watchedLogo?.length > 0 ? (
                    <div className="position-absolute">
                      <div className="position-relative">
                        <Image
                          src={
                            watchedLogo
                              ? `data:image/png;base64,${watchedLogo}`
                              : watchLogoURL
                          }
                          style={{
                            width: "100px",
                            height: "100px",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: "10px",
                            objectFit: "fill",
                          }}
                        />
                        <div
                          className="animated-btn position-absolute"
                          style={{
                            top: -10,
                            right: -10,
                          }}
                          onClick={() => {
                            templateForm.setValue("templateImage.base64", "");
                            templateForm.setValue("templateImage.fileName", "");
                            templateForm.setValue("templateImage.url", "");
                          }}
                        >
                          <MdDelete color="red" size={20} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        logoInputRef.current &&
                          (logoInputRef.current.value = "");
                        logoInputRef.current?.click();
                      }}
                    >
                      <div className="position-absolute template-image-upload-overlay pointer">
                        <GrUpload size={25} />
                        Upload Image
                      </div>
                      <RiImageAddFill size={50} />
                    </div>
                  )}
                </div>
                <Controller
                  control={templateForm.control}
                  name="templateImage.base64"
                  render={({field}) => (
                    <input
                      hidden
                      type="file"
                      ref={logoInputRef}
                      onChange={(e) => {
                        getBase64(e)
                          .then((base64) => {
                            field.onChange(base64);
                            templateForm.setValue(
                              "templateImage.fileName",
                              e.target.files?.[0]?.name ?? ""
                            );
                          })
                          .catch((err) => {});
                      }}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  control={templateForm.control}
                  name="title"
                  rules={{
                    required: {
                      value: true,
                      message: "Title is missing",
                    },
                  }}
                  render={({field}) => (
                    <TextInputWithRef
                      className=" template-focused-input"
                      style={{
                        fontSize: "1.8rem",
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={templateForm.control}
                  name="description"
                  render={({field}) => (
                    <TextInputWithRef
                      className=" template-focused-input"
                      style={{
                        fontSize: "1rem",
                      }}
                      placeholder="Enter Template Discription"
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>
            {templateForm.watch("pages").map((page, index) => {
              return (
                <Fragment key={index}>
                  <Stack
                    direction="horizontal"
                    className="align-items-center my-3"
                  >
                    <span
                      className="animated-btn"
                      onClick={() => {
                        setOpenedPages(
                          openedPages.includes(page.id ?? "")
                            ? openedPages.filter(
                                (id) => id !== (page?.id ?? "")
                              )
                            : [...openedPages, page.id ?? ""]
                        );
                      }}
                    >
                      <RiArrowDownSLine size={20} />
                    </span>
                    <Stack>
                      {/* <Text fontWeight="500" fontSize="1.3rem">
                      {page.title}
                    </Text> */}
                      <Controller
                        control={templateForm.control}
                        name={`pages.${index}.title`}
                        rules={{
                          required: {
                            value: true,
                            message: "Page Title is missing",
                          },
                        }}
                        render={({field}) => (
                          <TextInputWithRef
                            style={{
                              fontSize: "1.3rem",
                            }}
                            className="template-focused-input"
                            refWrapperClassName="w-100 "
                            {...field}
                          />
                        )}
                      />
                      <Text variant="light" fontSize="0.9rem">
                        {page.description}
                      </Text>
                    </Stack>
                    {index !== 0 && (
                      <span
                        className="animated-btn"
                        onClick={() => {
                          templateForm.setValue(
                            "pages",
                            updateArray(templateForm.watch("pages"), {
                              $splice: [[index, 1]],
                            })
                          );
                        }}
                      >
                        <MdDelete size={20} />
                      </span>
                    )}
                  </Stack>

                  <Collapse in={openedPages.includes(page.id ?? "")}>
                    <div>
                      <DraggableContainer
                        pageIndex={index}
                        templateForm={templateForm}
                        key={page.questions?.length}
                        listType={page.title}
                        listItems={page.questions}
                        onItemDelete={(itemIndex) => {
                          const newQuestions = updateArray(
                            // pagesArr.fields?.[index].questions,
                            templateForm.getValues(`pages.${index}.questions`),
                            {
                              $splice: [[itemIndex, 1]],
                            }
                          );
                          templateForm.setValue(
                            `pages.${index}.questions`,
                            newQuestions
                          );
                        }}
                        onPositionChanged={(rearrangedArray) => {
                          templateForm.setValue(
                            `pages.${index}.questions`,
                            rearrangedArray
                          );
                        }}
                        onAddQuestion={(currentQuestionIndex) => {
                          const newQuestions = updateArray(
                            templateForm.getValues(`pages.${index}.questions`),
                            {
                              $splice: [
                                [
                                  currentQuestionIndex + 1,
                                  0,
                                  {
                                    dummyId: (Math.random() * 100).toString(),
                                    title: "",
                                    type: "SHORT_ANSWER",
                                  } as any,
                                ],
                              ],
                            }
                          );
                          templateForm.setValue(
                            `pages.${index}.questions`,
                            newQuestions
                          );
                        }}
                      />
                      <Stack direction="horizontal" className="mt-2">
                        <Button
                          variant="light"
                          className="text-primary fw-bold d-flex align-items-center"
                          onClick={() => {
                            templateForm.setValue(
                              `pages.${index}.questions`,
                              updateArray(page.questions, {
                                $push: [
                                  {
                                    title: "",
                                    type: "SHORT_ANSWER",
                                    dummyId: (Math.random() * 100).toString(),
                                  } as any,
                                ],
                              })
                            );
                          }}
                        >
                          <AddIcon size={15} className="me-1" /> Add Question
                        </Button>
                        {/* <Button
                          variant="light"
                          className="text-primary fw-bold d-flex align-items-center ms-2"
                          onClick={() => {
                            templateForm.setValue(
                              `pages.${index}.questions`,
                              updateArray(page.questions, {
                                $push: [
                                  {
                                    id: (Math.random() * 100).toString(),
                                    questions: [],
                                    sequence: 0,
                                    title: "",
                                  },
                                ],
                              })
                            );
                          }}
                        >
                          <TbSection size={15} className="me-1" /> Add Section
                        </Button> */}
                      </Stack>

                      <div
                        className="text-decoration-underline my-2 pointer d-inline-block"
                        style={{}}
                        onClick={() => {
                          const id = (Math.random() * 100).toString();
                          setOpenedPages([...openedPages, id]);
                          templateForm.setValue(
                            "pages",
                            updateArray(templateForm.getValues("pages"), {
                              $splice: [
                                [
                                  index + 1,
                                  0,
                                  {
                                    title: "New Page",
                                    description: "",
                                    questions: [],
                                    sections: [],
                                  },
                                ],
                              ],
                            })
                          );
                        }}
                      >
                        Add Page
                      </div>
                    </div>
                  </Collapse>
                </Fragment>
              );
            })}
          </Col>
          {/* <Col xl="3" className="d-none d-xl-block">
           
          </Col> */}
        </Row>
      </Container>
      <Modal show={showPreview} fullscreen>
        <Modal.Header>
          <Modal.Title>
            <span className="animated-btn" onClick={() => togglePreview()}>
              <MdOutlineArrowBack size={20} />
            </span>
            Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className=""
          style={{
            backgroundColor: "var(--bs-gray-200)",
          }}
        >
          <RenderedTemplate mode="PREVIEW" form={templateForm} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
