import { FC, useRef, useState } from "react";
import type { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { RiDragMoveLine } from "react-icons/ri";
import {
  Col,
  Collapse,
  ListGroup,
  Offcanvas,
  Overlay,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import Text from "../../../components/text/text";
import ResponseAction from "./response-actions";
import ResponseTypes, {
  ResponseType,
  ResponseTypesArr,
} from "./response-types";
import { MdDelete, MdOutlineFormatColorText } from "react-icons/md";
import Template, {
  QuestionType,
  TemplateCreatePayload,
} from "../../../api/template";
import { Item } from "./draggable-container";
import { AddIcon } from "../../../components/icons/icons";
import { TbSection } from "react-icons/tb";
import { useMediaQuery } from "react-responsive";
import TextInput, { TextInputWithRef } from "../../../components/input/input";
import { Controller, UseFormReturn } from "react-hook-form";

const cardStyle = {
  // border: "1px dashed gray",
  //   padding: "0.5rem 1rem",
  //   marginBottom: ".5rem",
  backgroundColor: "white",
  //   cursor: "move",
};

export interface DroppableItemProps {
  index: number;
  moveDroppableItem: (dragIndex: number, hoverIndex: number) => void;
  data: QuestionType;
  type?: string;
  onDelete: (index: number) => void;
  onAddQuestion: (index: number) => void;
  templateForm: UseFormReturn<Template.ITemplate, object>;
  pageIndex: number;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DroppableItem: FC<DroppableItemProps> = ({
  data,
  index,
  moveDroppableItem,
  type = "card",
  onDelete,
  onAddQuestion,
  templateForm,
  pageIndex,
}) => {
  // is screen width is more than 768px
  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });

  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLAnchorElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveDroppableItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: type,
    item: () => {
      return { id: data.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  //   drag(drop(ref));
  drag(dragRef);
  drop(preview(previewRef));

  const [open, setOpen] = useState(false);

  const responseTypeObject = ResponseTypesArr.find(
    (value) => value.type === data?.type
  );
  const [selectedResponseType, setSelectedResponseType] =
    useState<ResponseType>({
      type: responseTypeObject?.type ?? "SHORT_ANSWER",
      label: responseTypeObject?.label ?? "Short Answer",
      Icon: responseTypeObject?.Icon ?? MdOutlineFormatColorText,
    });

  return (
    <>
      {isLargeScreen && (
        <Overlay
          target={previewRef.current}
          show={open}
          rootClose
          placement="left"
          onHide={() => setOpen(false)}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div className="p-0 me-2 shadow border-0" {...props}>
              <Stack
                className=""
                style={{
                  // position: "absolute",
                  backgroundColor: "white",
                  padding: "2px 5px",
                  // margin: "0px 5px",
                  color: "white",
                  borderRadius: 3,
                }}
              >
                <Stack
                  className="d-flex align-items-center pointer"
                  onClick={() => {
                    onAddQuestion(index);
                  }}
                >
                  <AddIcon size={20} color="green" />
                  <Text fontSize="0.7rem">Question</Text>
                </Stack>
                <Stack className="d-flex align-items-center mt-2 pointer">
                  <TbSection size={20} color="blue" />
                  <Text fontSize="0.7rem">Section</Text>
                </Stack>
              </Stack>
            </div>
          )}
        </Overlay>
      )}
      <ListGroup.Item
        ref={previewRef}
        style={{ ...cardStyle, opacity }}
        // className="p-0"
      >
        <Row className="">
          <Col md="12" lg="9">
            {/* <OverlayTrigger
              trigger="click"
              placement="left-start"
              rootClose={true}
              // show={open}
              onToggle={() => setOpen(!open)}
              overlay={
                
              }
            > */}
            <Stack
              direction="horizontal"
              className="h-100"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <div ref={dragRef} data-handler-id={handlerId}>
                <RiDragMoveLine size={20} />
              </div>

              {open ? (
                <Controller
                  control={templateForm.control}
                  name={`pages.${pageIndex}.questions.${index}.title`}
                  render={({ field }) => {
                    return (
                      <TextInputWithRef
                        refWrapperClassName="flex-grow-1"
                        className="ms-3"
                        onClick={(e: any) => {
                          e?.stopPropagation();
                        }}
                        // defaultValue={data.text}
                        // value={data.text}
                        autoFocus
                        // onBlur={() => {
                        //   setOpen(false);
                        // }}
                        placeholder="Type your question"
                        // onChange={(e) => {
                        //   templateForm.setValue(
                        //     `pages.${pageIndex}.questions.${index}.text`,
                        //     e.target.value
                        //   );
                        // }}
                        {...field}
                      />
                    );
                  }}
                />
              ) : (
                <Text className="ms-3">
                  {data?.isRequired ? "* " : undefined}
                  {data?.title ?? "Type your question"}
                </Text>
              )}
            </Stack>
            {/* </OverlayTrigger> */}
          </Col>
          <Col xs="3" className="border-start d-none d-lg-block">
            <div
              className="d-flex align-items-center my-auto h-100 w-100"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <ResponseTypes
                onChange={(value) => {
                  setSelectedResponseType(value);
                  templateForm.setValue(
                    `pages.${pageIndex}.questions.${index}.type`,
                    value.type
                  );
                }}
                defaultOption={selectedResponseType}
                pageIndex={pageIndex}
                questionIndex={index}
                templateForm={templateForm}
              />
            </div>
          </Col>
        </Row>
        <Collapse in={open} className="mt-2">
          <div
            style={{ backgroundColor: "var(--bs-gray-100)" }}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            {/* {ResponseAction(selectedResponseType.type)} */}
            <ResponseAction
              type={selectedResponseType.type}
              onChange={(value) => {
                setSelectedResponseType(value);
                templateForm.setValue(
                  `pages.${pageIndex}.questions.${index}.type`,
                  value.type
                );
              }}
              defaultOption={selectedResponseType}
              templateForm={templateForm}
              templateFormControllerKey={`pages.${pageIndex}.questions.${index}.isRequired`}
            />
          </div>
        </Collapse>
      </ListGroup.Item>

      {isLargeScreen && (
        <Overlay
          target={previewRef.current}
          show={open}
          placement="right-start"
        >
          <span
            className="pointer p-2"
            onClick={() => {
              onDelete(index);
            }}
          >
            <MdDelete size={20} />
          </span>
        </Overlay>
      )}
    </>
  );
};

export default DroppableItem;
