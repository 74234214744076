import moment from "moment";
import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import DateTime from "../../date-time/date-time";
import { FilterProps } from "../type";
import "./../filter-button.scss";

export default function DatePopup({
  onChange,
  defaultValue,
}: Omit<FilterProps, "defaultValue"> & {
  defaultValue: {
    startDate: number;
    endDate: number;
  };
}) {
  const [selectedPeriod, setSelectedPeriod] = useState<
    | "TODAY"
    | "YESTERDAY"
    | "LAST-7-DAYS"
    | "LAST-30-DAYS"
    | "THIS-MONTH"
    | "LAST-MONTH"
    | "CUSTOM"
  >();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [dateRange, setDateRange] = useState<{
    startDate: number;
    endDate: number;
  }>();

  useEffect(() => {
    if (startDate && endDate && selectedPeriod === "CUSTOM") {
      onChange({
        startDate,
        endDate,
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (defaultValue?.startDate || defaultValue?.endDate) {
      let start = moment.unix(defaultValue.startDate).toDate();
      let end = moment.unix(defaultValue.endDate).toDate();
      setStartDate(start);
      setEndDate(end);
      // setDateRange({
      //   startDate: defaultValue.startDate,
      //   endDate: defaultValue.startDate,
      // });
    }
  }, []);

  useEffect(() => {
    if (selectedPeriod === "TODAY") {
      onChange({
        startDate: moment().unix(),
        endDate: moment().unix(),
      });
    }
    if (selectedPeriod === "YESTERDAY") {
      onChange({
        startDate: moment().subtract(1, "day").unix(),
        endDate: moment().subtract(1, "day").unix(),
      });
    }
    if (selectedPeriod === "LAST-7-DAYS") {
      onChange({
        startDate: moment().subtract(6, "day").unix(),
        endDate: moment().unix(),
      });
    }
    if (selectedPeriod === "LAST-30-DAYS") {
      onChange({
        startDate: moment().subtract(29, "day").unix(),
        endDate: moment().unix(),
      });
    }
    if (selectedPeriod === "THIS-MONTH") {
      onChange({
        startDate: moment().startOf("month").unix(),
        endDate: moment().endOf("month").unix(),
      });
    }
    if (selectedPeriod === "LAST-MONTH") {
      onChange({
        startDate: moment().subtract(1, "month").startOf("month").unix(),
        endDate: moment().subtract(1, "month").endOf("month").unix(),
      });
    }
  }, [selectedPeriod]);
  return (
    <Stack direction="horizontal">
      <Stack direction="vertical" className="date-popup-stack">
        <div
          className={selectedPeriod === "TODAY" ? "active" : undefined}
          onClick={() => {
            setSelectedPeriod("TODAY");
          }}
        >
          Today
        </div>
        <div
          className={selectedPeriod === "YESTERDAY" ? "active" : undefined}
          onClick={() => {
            setSelectedPeriod("YESTERDAY");
          }}
        >
          Yesterday
        </div>
        <div
          className={selectedPeriod === "LAST-7-DAYS" ? "active" : undefined}
          onClick={() => {
            setSelectedPeriod("LAST-7-DAYS");
          }}
        >
          Last 7 days
        </div>
        <div
          className={selectedPeriod === "LAST-30-DAYS" ? "active" : undefined}
          onClick={() => {
            setSelectedPeriod("LAST-30-DAYS");
          }}
        >
          Last 30 days
        </div>
        <div
          className={selectedPeriod === "THIS-MONTH" ? "active" : undefined}
          onClick={() => {
            setSelectedPeriod("THIS-MONTH");
          }}
        >
          This month
        </div>
        <div
          className={selectedPeriod === "LAST-MONTH" ? "active" : undefined}
          onClick={() => {
            setSelectedPeriod("LAST-MONTH");
          }}
        >
          Last month
        </div>
        <div
          className={selectedPeriod === "CUSTOM" ? "active" : undefined}
          onClick={() => setSelectedPeriod("CUSTOM")}
        >
          Custom range
        </div>
      </Stack>
      {selectedPeriod === "CUSTOM" && (
        <Stack direction="horizontal" className="ms-2">
          <DateTime
            inline
            showTimeSelect={false}
            onChange={(date) => {
              setStartDate(date);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            selected={startDate}
            minDate={startDate}
          />
          <div className="ms-3">
            <DateTime
              inline
              showTimeSelect={false}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </Stack>
      )}
    </Stack>
  );
}
