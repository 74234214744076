import {useEffect, useState} from "react";
import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  Row,
  Stack,
} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {MdDelete, MdFeedback, MdOutlinePause} from "react-icons/md";
import Text from "../../components/text/text";
import Select from "react-select";
import SelectTheme from "../../scss/select.theme";
import {BiArrowFromLeft} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import DateInput from "../../components/input/date-input";
import Employee from "../../api/employees";
import Schedule from "../../api/schedule";
import moment from "moment";
import Template from "../../api/template";
import Location from "../../api/location";
// type ScheduleInspectionPayload = {
//   id?: number;
//   template?: { id: number; name: string };
//   assignedTo?: Array<{ id?: number; name: string }>;
//   frequency: any;
//   startTime: {
//     text: string;
//     timestamp: number;
//   };
//   endTime: {
//     text: string;
//     timestamp: number;
//   };
//   canSubmitAfterDueDate: boolean;
//   location: { id?: number; name: string };
//   title: string;
// };
interface ScheduleFormProps {
  mode: "CREATE" | "EDIT";
}
export default function ScheduleForm({mode}: ScheduleFormProps) {
  const navigate = useNavigate();
  const form = useForm<
    Schedule.ScheduleInspectionPayload & {
      template: {label: string; value: string};
      employee: Array<{label: string; value: string}>;
      location: Array<{label: string; value: string}>;
      frequency: {label: string; value: string};
    }
  >({
    defaultValues: {
      assignedTo: [],

      startTime: moment().unix().toString(),
      endTime: moment().unix().toString(),

      title: "",
      id: undefined,
      templateId: undefined,
      howOften: "EVERY_DAY",
      isAllowedInspections: true,
      sites: [],
      employee: [],
      location: [],
      template: {
        label: "",
        value: "",
      },
      frequency: {
        label: "",
        value: "",
      },
    },
  });

  const [employees, setEmployees] = useState<Array<Employee.IEmployee>>([]);
  const [templates, setTemplates] = useState<Array<Template.FetchResponse>>([]);
  const [locations, setLocations] = useState<Array<Location.ILocation>>([]);
  const howOftenOptions = [
    {
      value: "ONE_OFF",
      label: "One-off",
    },
    {
      value: "EVERY_DAY",
      label: "Every day",
    },
    {
      value: "EVERY_WEEK_DAY",
      label: "Every weekday",
    },
    {
      value: "EVERY_WEEK",
      label: "Every week",
    },
    {
      value: "EVERY_MONTH",
      label: "Every month",
    },
    {
      value: "EVERY_YEAR",
      label: "Every year",
    },
  ];
  useEffect(() => {
    if (mode === "EDIT") {
      //  TODO: get data from API
    }
    if (mode === "CREATE") {
    }

    Employee.FetchEmployees(
      (data) => {
        // console.log(data);
        setEmployees(data);
      },
      (error) => {
        // console.log({ error });
      }
    );
    Template.FetchAllTemplates(
      (templates) => {
        setTemplates(templates);
      },
      (error) => {
        // console.log({ error });
      }
    );
    Location.FetchLocations(
      (locations) => {
        setLocations(locations);
      },
      (error) => {
        // console.log({ error });
      }
    );
  }, []);
  return (
    <Container style={{maxWidth: "45rem"}}>
      <Row className="mt-5 align-items-center">
        <Col>
          <Text mode="Heading">Schedule Inspection</Text>
        </Col>
        <Col className="justify-content-end d-flex">
          <Button className="text-decoration-none" variant="link">
            <MdFeedback size={18} /> Send Feedback
          </Button>
        </Col>
      </Row>
      <div className="border rounded mt-5 p-3 ">
        <Row>
          <Col>
            <Stack>
              <Text fontSize="16px" fontWeight="500">
                Template
              </Text>
              <Controller
                control={form.control}
                name="templateId"
                rules={{
                  required: {
                    value: true,
                    message: "Please select template",
                  },
                }}
                render={({field}) => (
                  <Select
                    isSearchable
                    options={templates.map((temp) => ({
                      label: temp.title,
                      value: temp.id,
                    }))}
                    styles={SelectTheme}
                    {...field}
                    value={form.watch("template")}
                    onChange={(val: any) => {
                      field.onChange(val.value);
                      form.setValue("template", val);
                    }}
                  />
                )}
              />
            </Stack>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Stack>
              <Text fontSize="16px" fontWeight="500">
                Assigned to
              </Text>
              <Controller
                control={form.control}
                name="assignedTo"
                rules={{
                  required: {
                    value: true,
                    message: "Please select employee",
                  },
                }}
                render={({field}) => (
                  <Select
                    isMulti
                    isSearchable
                    options={employees.map((emp) => ({
                      label: emp.preferredTitle,
                      value: emp.id,
                    }))}
                    styles={SelectTheme}
                    {...field}
                    value={form.watch("employee")}
                    onChange={(val: any) => {
                      if (val?.length > 0) {
                        field.onChange([val?.map((v: any) => v?.value)]);
                        form.setValue("employee", val);
                      } else {
                        form.setValue("employee", []);
                        form.setValue("assignedTo", []);
                      }
                    }}
                  />
                )}
              />
            </Stack>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Stack>
              <Text fontSize="16px" fontWeight="500">
                How often
              </Text>
              <Controller
                control={form.control}
                name="howOften"
                rules={{
                  required: {
                    value: true,
                    message: "Please select frequency",
                  },
                }}
                render={({field}) => (
                  <Select
                    options={howOftenOptions}
                    styles={SelectTheme}
                    {...field}
                    value={form.watch("frequency")}
                    onChange={(val: any) => {
                      field.onChange(val.value);
                      form.setValue("frequency", val);
                    }}
                  />
                )}
              />

              <Stack direction="horizontal" className="mt-2">
                <Controller
                  control={form.control}
                  name="startTime"
                  rules={{
                    required: {
                      value: true,
                      message: "Please select start time",
                    },
                  }}
                  render={({field}) => (
                    // <FormControl placeholder="Start Time" {...field} />
                    <DateInput
                      inputProps={{
                        placeholder: "Start Time",
                        ...field,
                      }}
                      onChangeDate={(timestamp) => {
                        field.onChange(String(timestamp));
                      }}
                    />
                  )}
                />
                <BiArrowFromLeft size={50} className="mx-2" />
                <Controller
                  control={form.control}
                  name="endTime"
                  rules={{
                    required: {
                      value: true,
                      message: "Please select end time",
                    },
                  }}
                  render={({field}) => (
                    // <FormControl placeholder="End Time" {...field} />
                    <DateInput
                      inputProps={{
                        placeholder: "End Time",
                        ...field,
                      }}
                      onChangeDate={(timestamp) => {
                        field.onChange(String(timestamp));
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="horizontal" className="mt-2">
                <Controller
                  control={form.control}
                  name="isAllowedInspections"
                  render={({field}) => (
                    <FormCheck
                      // checked={value} onChange={onChange}
                      {...field}
                    />
                  )}
                />

                <Text className="ms-2">
                  Allow inspections to be submitted after the due date
                </Text>
              </Stack>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Stack>
              <Text fontSize="16px" fontWeight="500">
                Site
              </Text>
              <Controller
                control={form.control}
                name="sites"
                rules={{
                  required: {
                    value: true,
                    message: "Please select location",
                  },
                }}
                render={({field}) => (
                  <Select
                    isMulti
                    isSearchable
                    options={locations.map((loc) => ({
                      label: loc.name,
                      value: loc.id,
                    }))}
                    styles={SelectTheme}
                    {...field}
                    value={form.watch("location")}
                    onChange={(val: any) => {
                      if (val?.length > 0) {
                        field.onChange([val?.map((v: any) => v?.value)]);
                        form.setValue("location", val);
                      } else {
                        form.setValue("location", []);
                        form.setValue("sites", []);
                      }
                    }}
                  />
                )}
              />
            </Stack>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Stack>
              <Text fontSize="16px" fontWeight="500">
                Title
              </Text>
              <Controller
                control={form.control}
                name="title"
                rules={{
                  required: {
                    value: true,
                    message: "Please enter title",
                  },
                }}
                render={({field}) => (
                  <FormControl placeholder="Inspection Title" {...field} />
                )}
              />
            </Stack>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Stack direction="horizontal">
              <Button
                className="mx-1"
                onClick={form.handleSubmit(
                  (validData) => {
                    // console.log({validData});
                  },
                  (error) => {
                    // console.log({error});
                  }
                )}
              >
                Save
              </Button>
              <Button
                className="mx-1 border text-decoration-none"
                variant="link"
                onClick={() => {
                  navigate("/app/schedule/manage");
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Col>
          <Col className="justify-content-end d-flex">
            <Stack direction="horizontal">
              <Button
                className="mx-1 align-items-center d-flex schedule-form-action"
                variant="light"
              >
                <MdOutlinePause /> Pause
              </Button>
              <Button
                className="mx-1 align-items-center d-flex schedule-form-action"
                variant="light"
              >
                <MdDelete /> Delete
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
