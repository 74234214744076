export enum ActionPriorityType {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}
export type ActionListType = {
  id: number;
  name: string;
  dueDate: number;
  createdBy: {
    name: string;
    id: number;
  };
  lastUpdated: number;
  status: string;
  priority: keyof typeof ActionPriorityType;
};
export type ActionCreatePayload = {
  name: string;
  description: string;
  status?: string;
  location?: number;
  assignee?: Array<number>;
  priority?: keyof typeof ActionPriorityType;
  dueDate?: number;
  history?: Array<{
    comment: string;
    createdBy: {
      name: string;
      id: number;
    };
    createdAt: number;
  }>;
};
export interface ActionItemProps {
  text?: string;
  timestamp: number;
  type: "MESSAGE" | "ACTION" | "IMAGE" | "PDF";
  createdBy: {
    name: string;
    id: number;
  };
  media?: {
    url: string;
    fileName: string;
  };
}
export function ActionStatus(status: string): { text: string; color: string } {
  const ActionStatusObj: any = {
    TODO: {
      text: "To do",
      color: "rgb(182, 89, 23)",
    },
    INPROGRESS: {
      text: "In Progress",
      color: "#ffc107",
    },
    COMPLETED: {
      text: "Completed",
      color: "rgb(7, 111, 67)",
    },
  };
  return ActionStatusObj[status];
}
