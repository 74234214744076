import { Badge, Col, Container, ListGroup, Row } from "react-bootstrap";
import Text from "../../../../components/text/text";
import "./../template-editor.scss";

export default function TemplateAccess({}) {
  const listHeaderStyle = {};
  return (
    <Container className="py-4">
      <Row>
        <Col>
          <Text>Test Template</Text>
          <Text mode="Heading">
            Who can access this template and its inspections?
          </Text>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <ListGroup>
            <ListGroup.Item className="d-none d-lg-block">
              <Row>
                <Col xs="6" md="6" lg>
                  <Text className="template-access-header-item">
                    Template available to
                  </Text>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className="template-access-header-item">
                    Access level
                  </Text>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className="template-access-header-item">
                    Inspection results available to
                  </Text>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className="template-access-header-item">
                    Access level
                  </Text>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Template available to
                  </Text>
                  <Badge className="template-access-item">Saoud Nazir</Badge>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Access level
                  </Text>
                  <Text className="template-access-item">Template owner</Text>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Inspection results available to
                  </Text>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Access level
                  </Text>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Template available to
                  </Text>
                  <Badge className="template-access-item">Saoud Nazir</Badge>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Access level
                  </Text>
                  <Text className="template-access-item">Template owner</Text>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Inspection results available to
                  </Text>
                  <Badge className="template-access-item">Saoud Nazir</Badge>
                </Col>
                <Col xs="6" md="6" lg>
                  <Text className=" d-md-block d-lg-none template-access-header-item">
                    Access level
                  </Text>
                  <Text className="template-access-item">View, Edit</Text>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}
