import moment from "moment";
import { useState } from "react";
import {
  Badge,
  Collapse,
  Container,
  Image,
  ListGroup,
  Stack,
} from "react-bootstrap";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { HiPhotograph } from "react-icons/hi";
import { IoIosArrowUp } from "react-icons/io";
import { MdCalendarToday } from "react-icons/md";
import { TemplateReportPayload } from "../../../api/template";
import Text from "../../../components/text/text";
import "./styles.scss";

interface ReportWebPreviewProps {
  form: UseFormReturn<TemplateReportPayload, object>;
}
export default function ReportWebPreview({ form }: ReportWebPreviewProps) {
  const [openOverview, setOpenOverview] = useState(true);
  const [openFlagged, setOpenFlagged] = useState(true);
  const [openActions, setOpenActions] = useState(true);
  const [openPage, setOpenPage] = useState<Array<number>>([]);
  const uploadedLogo = form.watch("logoBase64");
  const showScore = form.watch("showScores");
  const showFlagged = form.watch("showFlaggedItems");
  const showActions = form.watch("showActions");
  const showDisclaimer = form.watch("showDisclaimer");
  const showInspectionResults = form.watch("showInspectionResults");
  const showMediaSummary = form.watch("showMediaSummary");

  return (
    <Container fluid>
      <div className="report-web-container">
        <Stack
          direction="horizontal"
          className="justify-content-between pointer"
          onClick={() => {
            setOpenOverview(!openOverview);
          }}
        >
          <Stack direction="horizontal">
            <IoIosArrowUp size={20} />
            <Text mode="SubHeading" className="ms-2">
              Overview
            </Text>
          </Stack>
          <Text>{form.watch("status")}</Text>
        </Stack>
        <Collapse in={openOverview}>
          <div>
            <div className="report-web-collapse">
              <div
                className={`${
                  !uploadedLogo ? "report-logo-upload" : ""
                } d-inline-block p-3 rounded`}
              >
                {uploadedLogo?.length > 0 ? (
                  <Image
                    src={`data:image/png;base64,${uploadedLogo}`}
                    style={{
                      width: "15rem",
                      height: "15rem",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <div>
                    <HiPhotograph size={50} /> Your template/report logo
                  </div>
                )}
              </div>
              <Text className="mt-3" mode="Heading">
                Template Name
              </Text>
              <Text className="mt-2" fontWeight="500">
                11 Aug 2022 / Saoud Nazir
              </Text>
              <ListGroup horizontal="md" className="mt-3">
                {showScore && (
                  <ListGroup.Item className="flex-grow-1">
                    <Text fontSize="13px" variant="light">
                      Inspection score
                    </Text>
                    <Text mode="Heading">0%</Text>
                  </ListGroup.Item>
                )}
                {showFlagged && (
                  <ListGroup.Item className="flex-grow-1">
                    <Text fontSize="13px" variant="light">
                      Flagged items
                    </Text>
                    <Text mode="Heading">1</Text>
                  </ListGroup.Item>
                )}
                {showActions && (
                  <ListGroup.Item className="flex-grow-1">
                    <Text fontSize="13px" variant="light">
                      Created actions
                    </Text>
                    <Text mode="Heading">1</Text>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </div>
            <div className="report-web-collapse-item">
              <Text fontSize="12px">Conducted by</Text>
              <Text variant="light">Unanswered</Text>
            </div>
            <div className="report-web-collapse-item">
              <Text fontSize="12px">Site conducted</Text>
              <Text variant="light">Unanswered</Text>
            </div>
            <div className="report-web-collapse-item">
              <Text fontSize="12px">Location</Text>
              <Text variant="light">Unanswered</Text>
            </div>
            <div className="report-web-collapse-item">
              <Text fontSize="12px">Prepared by</Text>
              <Text variant="light">Unanswered</Text>
            </div>
          </div>
        </Collapse>
      </div>

      {showDisclaimer && (
        <div className="report-web-container mt-3">
          <Stack
            direction="horizontal"
            className="pointer"
            onClick={() => {
              if (openPage.includes(0)) {
                setOpenPage(openPage.filter((item) => item !== 0));
              } else {
                setOpenPage([...openPage, 0]);
              }
            }}
          >
            <IoIosArrowUp size={20} />
            <Text mode="SubHeading" className="ms-2">
              Disclaimer
            </Text>
          </Stack>
          <Collapse in={openPage.includes(0)}>
            <div className="report-web-collapse-item">
              <Text>
                The assessors believe the information contained within this risk
                assessment report to be correct at the time of printing. The
                assessors do not accept responsibility for any consequences
                arising from the use of the information herein. The report is
                based on matters which were observed or came to the attention of
                the assessors during the day of the assessment and should not be
                relied upon as an exhaustive record of all possible risks or
                hazards that may exist or potential improvements that can be
                made. Information on the latest workers compensation and OHS /
                WHS laws can be found at the relevant State WorkCover / WorkSafe
                Authority.
              </Text>
            </div>
          </Collapse>
        </div>
      )}

      {showFlagged && (
        <div className="report-web-container mt-3">
          <Stack
            direction="horizontal"
            className="justify-content-between pointer"
            onClick={() => {
              setOpenFlagged(!openFlagged);
            }}
          >
            <Stack direction="horizontal">
              <IoIosArrowUp size={20} />
              <Text mode="SubHeading" className="ms-2">
                Flagged items
              </Text>
            </Stack>
            <Badge bg="danger">1</Badge>
          </Stack>
          <Collapse in={openFlagged}>
            <div>
              <div className="report-web-collapse-item">
                <Text>
                  Hard Hats (available, worn by crew and in safe condition)
                </Text>
                <Badge
                  bg="danger"
                  className="px-4 py-1 mt-2"
                  style={{
                    borderRadius: "0.75rem",
                    minWidth: "20px",
                    fontSize: "0.87rem",
                  }}
                >
                  No
                </Badge>
              </div>
            </div>
          </Collapse>
        </div>
      )}

      {showActions && (
        <div className="report-web-container mt-3">
          <Stack
            direction="horizontal"
            className="justify-content-between pointer"
            onClick={() => {
              setOpenActions(!openActions);
            }}
          >
            <Stack direction="horizontal">
              <IoIosArrowUp size={20} />
              <Text mode="SubHeading" className="ms-2">
                Actions
              </Text>
            </Stack>
            <Badge>1</Badge>
          </Stack>
          <Collapse in={openActions}>
            <div>
              <div className="report-web-collapse-item">
                <Text fontSize="12px" variant="light">
                  Mark Jones created a High priority action for Fred Smith
                </Text>
                <Stack
                  direction="horizontal"
                  className="align-items-center mt-2"
                >
                  <Badge
                    // bg="danger"
                    className="px-4 py-1 "
                    style={{
                      borderRadius: "0.75rem",
                      minWidth: "20px",
                      fontSize: "0.87rem",
                    }}
                  >
                    In Progress
                  </Badge>
                  <Stack direction="horizontal" className="ms-3">
                    <MdCalendarToday size={15} className="me-2" />
                    {moment().format("DD MMM YYYY")}
                  </Stack>
                </Stack>
              </div>
            </div>
          </Collapse>
        </div>
      )}

      {showInspectionResults && (
        <div className="report-web-container mt-3">
          <Stack
            direction="horizontal"
            className="pointer"
            onClick={() => {
              if (openPage.includes(0)) {
                setOpenPage(openPage.filter((item) => item !== 0));
              } else {
                setOpenPage([...openPage, 0]);
              }
            }}
          >
            <IoIosArrowUp size={20} />
            <Text mode="SubHeading" className="ms-2">
              Untitled Page
            </Text>
          </Stack>
          <Collapse in={openPage.includes(0)}>
            <div>
              <div className="report-web-collapse-item">
                <Text fontSize="12px">Question 1</Text>
                <Text variant="light">Unanswered</Text>
              </div>
            </div>
          </Collapse>
        </div>
      )}
    </Container>
  );
}
