import React, { RefObject } from "react";

function useOutsideClick<O>(
  outsideCallback: (ref?: RefObject<O>) => void,
  insideCallback?: (ref?: RefObject<O>) => void
): React.MutableRefObject<any>;
function useOutsideClick<O>(
  outsideCallback: (ref?: RefObject<O>) => void,
  insideCallback?: (ref?: RefObject<O>) => void,
  elementRef?: RefObject<O>
) {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current?.contains(event?.target)) {
        // console.log("Inside");
        outsideCallback && outsideCallback(elementRef);
      } else {
        // console.log("Outside");
        insideCallback && insideCallback(elementRef);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, elementRef]);

  return ref;
}
export default useOutsideClick;
