import { Button, Modal, ModalProps, Stack } from "react-bootstrap";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";
interface MoveToCompleteProps extends ModalProps {}
export default function MoveToComplete(props: MoveToCompleteProps) {
  return (
    <Modal {...props} contentClassName="shadow border-0 p-3">
      <Modal.Title>Move to Complete</Modal.Title>
      <div className="my-3">
        <Text>Great! Leave some notes about what actions have been taken</Text>
        <TextInput
          placeholder="What has been done?"
          as="textarea"
          className="focused-input"
          style={{ minHeight: "150px" }}
        />
      </div>
      <Stack direction="horizontal" className="justify-content-end">
        <Button className="me-2" variant="light" onClick={props.onHide}>
          Cancel
        </Button>
        <Button>Move to complete</Button>
      </Stack>
    </Modal>
  );
}
