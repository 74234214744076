import moment from "moment";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  Image,
  ListGroup,
  Modal,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import {
  MdDelete,
  MdLibraryAddCheck,
  MdOutlineLibraryAddCheck,
  MdOutlineMyLocation,
  MdOutlineNoteAlt,
  MdOutlinePermMedia,
} from "react-icons/md";
import {useMediaQuery} from "react-responsive";
import {useNavigate, useParams} from "react-router-dom";
import ResizeTextArea from "react-textarea-autosize";
import {
  FetchLocations,
  getBase64,
  getBase64FromFile,
  getUserLocation,
  LocationType,
} from "../../api/general";
import Inspection from "../../api/inspections";
import Template, {QuestionType} from "../../api/template";
import AttachmentGallery from "../../components/attachment-modal/attachment-gallery";
import AttachmentItem from "../../components/attachment-modal/attachment-item";
import TextInput, {TextInputWithRef} from "../../components/input/input";
import Text from "../../components/text/text";
import useNotifications from "../../hooks/notification";
import "./template.scss";
interface RenderedTemplateProps {
  form?: UseFormReturn<any, object>;
  mode?: "PREVIEW" | "INSPECTION" | "VIEW";
}
export default function RenderedTemplate({form, mode}: RenderedTemplateProps) {
  const notification = useNotifications();
  const {id} = useParams();
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(0);

  const [isSubmiting, setIsSubmitting] = useState<boolean>(false);

  const inspectionForm = useForm<Inspection.InspectionSubmitPayload>({
    defaultValues: {
      // id: "",
      mediaUniqueId: "",
      pages: [],
      templateId: "",
      title: "",
    },
  });

  // const watchedInspectionPages = inspectionForm.watch("pages");

  const [template, setTemplate] = useState<Template.ITemplate | undefined>(
    form?.getValues()
  );

  useEffect(() => {
    if (mode === "INSPECTION") {
      if (id) {
        Template.FetchTemplate(
          id,
          (template) => {
            setTemplate(template);
            inspectionForm.reset({
              // id: "",
              mediaUniqueId: template.mediaUniqueId,
              pages: template.pages.map(({id, questions, sections}) => ({
                id,
                questions: questions?.map(({id}) => ({id})),
                sections,
              })),
              templateId: template.id,
              title: template.title,
            });
          },
          (error) => {
            notification.showNotification(
              {
                type: "PROMPT",
                manualDismiss: true,
                prompts: [
                  {
                    label: "Go back",
                    callback: () => {
                      navigate(-1);
                    },
                    variant: "danger",
                  },
                ],
              },
              error
            );
          }
        );
      } else {
        navigate(-1);
      }
    }

    if (mode === "VIEW") {
      if (id) {
        Inspection.FetchInspectionById(
          id,
          (inspection) => {
            inspectionForm.reset(inspection);
          },
          (error) => {
            notification.showNotification(
              {
                type: "PROMPT",
                manualDismiss: true,
                prompts: [
                  {
                    label: "Go back",
                    callback: () => {
                      navigate(-1);
                    },
                    variant: "danger",
                  },
                ],
              },
              error
            );
          }
        );
      } else {
        navigate(-1);
      }
    }
  }, []);

  return (
    <Fragment>
      <Container
        fluid="md"
        style={{
          maxWidth: "48rem",
        }}
      >
        <Row>
          {/* <Col xs="auto">
            <OverlayTrigger
              placement="bottom"
              rootClose
              trigger="click"
              overlay={
                <Popover className="qs-dropdown">
                  {(watchedInspectionPages ?? template?.pages)?.map(
                    (page, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setSelectedPage(index);
                          document.body.click();
                        }}
                      >
                        {page.title}
                      </div>
                    )
                  )}
                </Popover>
              }
            >
              <div className="border-bottom border-start border-end hover-btn shadow-sm rounded-0 rounded-bottom">
                <Stack>
                  <Text variant="light" fontSize="14px">
                    Page {selectedPage + 1} of{" "}
                    {watchedInspectionPages?.length ?? template?.pages?.length}
                  </Text>
                  <Text>
                    {watchedInspectionPages?.[selectedPage]?.title ??
                      template?.pages?.[selectedPage]?.title}
                  </Text>
                </Stack>
              </div>
            </OverlayTrigger>
          </Col> */}
          <Col className="py-2">
            <Text fontWeight="bold" fontSize="1.5rem">
              {template?.title}
            </Text>
          </Col>
        </Row>

        {
          // templateForm
          //   ?.getValues(`pages.${selectedPage}`)
          (mode === "VIEW"
            ? inspectionForm.watch(`pages`)
            : (template?.pages as unknown as Inspection.InspectionSubmitPayload["pages"])
          )
            ?.reduce(
              (
                prev: Array<{
                  label?: string;
                  questions: Array<Partial<QuestionType>>;
                }>,
                current
              ) => [
                ...prev,
                {label: current?.title, questions: current.questions},
              ],
              []
            )
            ?.map((page, pageInd) => (
              <Container>
                <Text fontWeight="bold" fontSize="1.25rem">
                  {page.label}
                </Text>
                {page.questions.map((question, index) => (
                  <Row
                    key={index}
                    className={`bg-white  p-3 border my-3 shadow-sm ${
                      inspectionForm.formState.errors.pages?.[pageInd]
                        ?.questions?.[index]?.message
                        ? "invalid-answer"
                        : ""
                    }`}
                    style={{
                      borderRadius: "20px",
                    }}
                  >
                    <Col xs="12">
                      <Stack
                        direction="horizontal"
                        className="align-items-center"
                      >
                        {question.type === "CHECKBOX" && (
                          <Controller
                            control={inspectionForm.control}
                            rules={{
                              required: question.isRequired,
                              validate: (val) =>
                                String(val?.value).length > 0 ||
                                (val?.multiOptionValues?.length ?? 0) > 0 ||
                                `No Value No MultiOptionValues ${JSON.stringify(
                                  val
                                )}`,
                            }}
                            name={`pages.${pageInd}.questions.${index}`}
                            render={({field}) => (
                              <FormCheck
                                key={`question-${index}-${pageInd}`}
                                type="checkbox"
                                className="render-template-checkbox me-2"
                                {...field}
                                value={undefined}
                                onChange={(e) => {
                                  field.onChange({
                                    id: question.id,
                                    value: e.target.checked,
                                  });
                                }}
                              />
                            )}
                          />
                        )}
                        <Text className="my-2">
                          {question.isRequired && "*"}
                          {question.title}
                        </Text>
                      </Stack>
                    </Col>

                    <Controller
                      key={`question-${index}-${pageInd}`}
                      control={inspectionForm.control}
                      rules={{
                        required: question.isRequired,
                        // validate: (val) =>
                        //   String(val?.value).length > 0 ||
                        //   val?.multiOptionValues?.length > 0 ||
                        //   `No Value No MultiOptionValues ${JSON.stringify(val)}`,

                        validate: (val) => {
                          if (
                            !question.isRequired ||
                            (val?.value !== undefined &&
                              String(val?.value).length > 0) ||
                            (val?.multiOptionValues !== undefined &&
                              val?.multiOptionValues?.length > 0) ||
                            (val?.mediaPaths !== undefined &&
                              val?.mediaPaths?.length > 0)
                          ) {
                            return true;
                          } else {
                            return "Value Missing";
                          }
                        },
                      }}
                      name={`pages.${pageInd}.questions.${index}`}
                      render={({field, fieldState: {error}}) => (
                        <RenderResponseField
                          ref={field.ref}
                          type={question.type}
                          field={field}
                          questionId={question?.id! ?? question.dummyId}
                          mediaUniqueId={template?.mediaUniqueId ?? ""}
                          error={error}
                          inspectionForm={inspectionForm}
                          pageIndex={pageInd}
                          questionIndex={index}
                          template={template}
                        />
                      )}
                    />

                    {/* <Col xs="12">
                      <BottomActionBar
                        key={question.id}
                        mediaUniqueId={template?.mediaUniqueId ?? ""}
                        pageIndex={pageInd}
                        questionIndex={index}
                        inspectionForm={inspectionForm}
                      />
                    </Col> */}
                  </Row>
                ))}
              </Container>
            ))
        }
        <Container />
        <Row className="mb-5">
          {/* {selectedPage <
            ((watchedInspectionPages ?? template?.pages)?.length || 0) &&
            selectedPage !== 0 && (
              <Col>
                <Button
                  className="d-flex align-items-center fs-6"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setSelectedPage(selectedPage - 1);
                  }}
                >
                  <MdKeyboardArrowLeft size={20} /> Previous Page
                </Button>
              </Col>
            )}
          {selectedPage + 1 !==
            (watchedInspectionPages ?? template?.pages)?.length && (
            <Col className="d-flex justify-content-end">
              <Button
                className="d-flex align-items-center fs-6"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setSelectedPage(selectedPage + 1);
                }}
              >
                Next Page <MdKeyboardArrowRight size={20} />
              </Button>
            </Col>
          )} */}
          {
            // selectedPage + 1 ===
            // (watchedInspectionPages ?? template?.pages)?.length &&
            mode === "INSPECTION" && (
              <Col className="d-flex justify-content-end">
                <Button
                  className="d-flex align-items-center fs-6"
                  onClick={(e) => {
                    inspectionForm.handleSubmit(
                      (data) => {
                        setIsSubmitting(true);
                        Inspection.SubmitInspection(
                          data,
                          (message) => {
                            setIsSubmitting(false);

                            if (
                              window.history.state &&
                              window.history.state.idx > 0
                            ) {
                              navigate(-1);
                            } else {
                              navigate("/app/template/");
                            }
                          },
                          (error) => {
                            setIsSubmitting(false);
                            notification.showNotification(
                              {
                                type: "ERROR",
                                manualDismiss: true,
                              },
                              "Unable to submit inspection",
                              error
                            );
                          }
                        );
                      },
                      (errors) => {
                        setIsSubmitting(false);
                        notification.showNotification(
                          {
                            type: "ERROR",
                          },
                          "Please answer all required questions"
                        );
                      }
                    )(e);
                  }}
                >
                  {isSubmiting ? (
                    <Spinner animation="border" />
                  ) : (
                    "Complete Inspection"
                  )}
                </Button>
              </Col>
            )
          }
        </Row>
      </Container>
    </Fragment>
  );
}

const RenderResponseField = React.forwardRef<
  HTMLInputElement,
  {
    type?: QuestionType["type"];
    field: ControllerRenderProps<
      Inspection.InspectionSubmitPayload,
      `pages.${number}.questions.${number}`
    >;
    questionId: string;
    pageIndex: number;
    questionIndex: number;
    mediaUniqueId: string;
    error?: FieldError;
    inspectionForm: UseFormReturn<Inspection.InspectionSubmitPayload>;
    template?: Template.ITemplate;
  }
>(
  (
    {
      field,
      mediaUniqueId,
      questionId,
      type,
      error,
      inspectionForm,
      pageIndex,
      questionIndex,
      template,
    },
    ref
  ) => {
    const mediaInputRef = useRef<HTMLInputElement>(null);
    const [mediaAttachments, setMediaAttachments] = useState<
      Array<{
        name: string;
        base64: string;
        type: string;
        id: number;
      }>
    >([]);

    const annotateInputRef = useRef<HTMLInputElement>(null);
    const [annotateMediaAttachments, setAnnotateMediaAttachments] = useState<
      Array<{
        name: string;
        base64: string;
        type: string;
        id: number;
      }>
    >([]);

    const signInputRef = useRef<HTMLInputElement>(null);
    const [signAttachment, setSignAttachment] = useState<{
      name: string;
      base64: string;
      type: string;
    }>();

    const [signTime, setSignTime] = useState<number>(moment().unix());

    const [selectedDate, setSelectedDate] = useState<Date | null>(
      field?.value?.value ? moment.unix(field?.value?.value).toDate() : null
    );

    const [openLocationModal, setOpenLocationModal] = useState(false);

    const [isLocationLoading, setIsLocationLoading] = useState<boolean>(false);
    const [location, setLocation] = useState<LocationType>();
    const [loadedAddresses, setLoadedAddresses] = useState<Array<LocationType>>(
      []
    );

    const notification = useNotifications();

    const [isUploadingImages, setIsUploadingImages] = useState<boolean>(false);

    const [uploadImageCount, setUploadImageCount] = useState<number>(0);

    const [totalImages, setTotalImages] = useState<number>(0);

    async function UploadMediaToServer(
      e: React.ChangeEvent<HTMLInputElement>
      // state: any,
      // setState: React.Dispatch<React.SetStateAction<any>>
    ) {
      const files = e.target.files;

      if (files) {
        setTotalImages(files.length);
        let urls: string[] = [];
        setIsUploadingImages(true);
        for (let i = 0; i < files.length; i++) {
          try {
            const file = files[i];
            const base64 = await getBase64FromFile(file);
            if (base64) {
              const {data} = await Inspection.UploadInspectionMediaAsync(
                mediaUniqueId,
                {base64, fileName: file.name}
              );

              urls.push(data?.path);
              setUploadImageCount(i + 1);
            }
          } catch (error) {
            notification.showNotification(
              {
                type: "ERROR",
              },
              "Unable to upload media"
            );
          }
        }

        setIsUploadingImages(false);

        field.onChange({
          ...(field.value ?? {}),
          value: "",
          mediaPaths: [...(field.value?.mediaPaths ?? []), ...urls],
        });

        setUploadImageCount(0);
        setTotalImages(0);
      }

      // const file = e.target.files?.[0];

      // if (file) {
      //   getBase64(e)
      //     .then((base64) => {
      //       if (base64) {

      //         Inspection.UploadInspectionMedia(
      //           mediaUniqueId,
      //           {base64, fileName: file.name},
      //           (url) => {
      //             field.onChange({
      //               ...(field.value ?? {}),
      //               mediaPaths: [...(field.value?.mediaPaths ?? []), url],
      //             });
      //           },
      //           (error) => {
      //             notification.showNotification(
      //               {
      //                 type: "ERROR",
      //               },
      //               "Unable to upload attached media",
      //               file.name
      //             );
      //           }
      //         );
      //       }
      //     })
      // .catch((e) => {
      //   notification.showNotification(
      //     {
      //       type: "ERROR",
      //     },
      //     "Unable to encrypt attached media",
      //     file.name
      //   );
      // });
      // }
    }

    const watchMedia = inspectionForm.watch(
      `pages.${pageIndex}.questions.${questionIndex}.mediaPaths`
    );

    const removeMedia = (index: number) =>
      inspectionForm.setValue(
        `pages.${pageIndex}.questions.${questionIndex}.mediaPaths`,
        watchMedia?.filter((_, i) => i !== index)
      );

    if (type === "SHORT_ANSWER") {
      return (
        <Col xs="12" md="6">
          <TextInputWithRef
            placeholder="Answer..."
            {...field}
            value={field.value?.value}
            onChange={(e: any) => {
              field.onChange({
                ...field?.value,
                id: questionId,
                value: e.target.value,
              });
            }}
          />
        </Col>
      );
    }
    if (type === "NUMBER") {
      return (
        <Col xs="12" md="6">
          <TextInputWithRef
            placeholder="Answer..."
            type="number"
            {...field}
            value={field.value?.value}
            onChange={(e: any) => {
              field.onChange({
                ...field?.value,
                id: questionId,
                value: e.target.value,
              });
            }}
          />
        </Col>
      );
    }
    if (type === "MEDIA") {
      return (
        <Fragment>
          <Col
            xs="12"
            md={{
              span: 6,
              offset: 6,
            }}
            className="justify-content-end d-flex"
          >
            <Button
              onClick={() => {
                mediaInputRef.current?.click();
              }}
              disabled={isUploadingImages}
            >
              {isUploadingImages
                ? `Uploading Media (${uploadImageCount}/${totalImages})`
                : "Add Media"}
            </Button>
            <input
              hidden
              type="file"
              ref={mediaInputRef}
              multiple
              onChange={(e) => {
                UploadMediaToServer(e);
                // e.target.value = "";
              }}
            />
          </Col>
          <AttachmentGallery
            attachments={
              field?.value?.mediaPaths ? field?.value?.mediaPaths : []
            }
            onDelete={(index) => {
              // removeMedia(index);
              field.onChange({
                ...field.value,
                value: "",
                mediaPaths: field.value?.mediaPaths?.filter(
                  (_, i) => index !== i
                ),
              });
            }}
          />
        </Fragment>
      );
    }

    if (type === "ANNOTATION") {
      return (
        <Fragment>
          <Col
            xs="12"
            md={{
              span: 6,
              offset: 6,
            }}
            className="justify-content-end d-flex"
          >
            <Button
              onClick={() => {
                annotateInputRef.current?.click();
              }}
            >
              Annotate
            </Button>
            <input
              hidden
              type="file"
              ref={annotateInputRef}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  getBase64(e)
                    .then((base64) => {
                      if (base64) {
                        setAnnotateMediaAttachments([
                          ...annotateMediaAttachments,
                          {
                            name: file.name || "Unnamed file",
                            base64,
                            type: file.type,
                            id: annotateMediaAttachments.length + 1,
                          },
                        ]);
                      }
                    })
                    .catch((e) => {});
                }
                e.target.value = "";
              }}
            />
          </Col>
          <AttachmentGallery
            attachments={watchMedia}
            onDelete={(index) => {
              removeMedia(index);
            }}
          />
        </Fragment>
      );
    }

    if (type === "SLIDER") {
      return (
        <Col>
          <Form.Range
            min={0}
            max={10}
            // value={rangeValue}
            // onChange={(e) => {
            //   setRangeValue(Number(e.target.value));
            // }}
            {...field}
            value={field.value?.value}
            onChange={(e) => {
              field.onChange({
                ...field?.value,
                id: questionId,
                value: e.target.value,
              });
            }}
          />
          <Stack direction="horizontal" className="justify-content-between">
            <Text>0</Text>
            <Text fontWeight="bold">{field.value?.value}</Text>
            <Text>10</Text>
          </Stack>
        </Col>
      );
    }

    if (type === "SIGNATURE") {
      return (
        <Fragment>
          <Col>
            <TextInput
              // value={signFullName}
              // onChange={(e) => setSignFullName(e.target.value)}
              placeholder="Full Name"
              {...field}
              value={field.value?.value}
              onChange={(e) => {
                field.onChange({
                  ...field?.value,
                  id: questionId,
                  value: e.target.value,
                });
              }}
            />
          </Col>
          <Col xs="auto">
            <Button
              onClick={() => {
                signInputRef.current?.click();
              }}
            >
              Signature
            </Button>
            <input
              hidden
              type="file"
              accept="image/*"
              ref={signInputRef}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  getBase64(e)
                    .then((base64) => {
                      if (base64) {
                        setSignAttachment({
                          name: file.name || "Unnamed file",
                          base64,
                          type: file.type,
                        });
                        setSignTime(moment().unix());
                      }
                    })
                    .catch((e) => {});
                }
                e.target.value = "";
              }}
            />
          </Col>
          <Col xs="12">
            {signAttachment && (
              <Stack direction="horizontal" className="mt-2">
                <Image
                  src={`data:image/png;base64,${signAttachment.base64}`}
                  style={{
                    width: "64px",
                  }}
                />
                <Text fontSize="0.8rem" variant="light">
                  {moment.unix(signTime).format("DD MMM YYYY hh:mm A")}
                </Text>
              </Stack>
            )}
          </Col>
        </Fragment>
      );
    }

    if (type === "LOCATION") {
      return (
        <Fragment>
          <Stack direction="horizontal">
            <TextInput
              placeholder="Location..."
              wrapperClassName="me-2"
              {...field}
              value={field.value?.value}
              onChange={(e) => {
                field.onChange({
                  ...field?.value,
                  id: questionId,
                  value: e.target.value,
                });
              }}
            />
            <Button
              onClick={() => {
                setOpenLocationModal(true);
              }}
            >
              Map
            </Button>
          </Stack>
          <Modal
            onEscapeKeyDown={() => {
              setOpenLocationModal(false);
              setLocation(undefined);
            }}
            show={openLocationModal}
            onHide={() => {
              setOpenLocationModal(false);
              setLocation(undefined);
            }}
            style={{zIndex: 1056}}
            centered
            backdropClassName="backdrop-zIndex"
            size="lg"
          >
            <Modal.Body>
              <Modal.Title>Select Location</Modal.Title>
              <Container fluid>
                <Row>
                  <Col xs="12" md className="px-0">
                    <TextInput
                      placeholder="Type address"
                      style={{
                        fontSize: "1rem",
                      }}
                      value={location?.address}
                      onChange={(e) => {
                        setLocation({
                          address: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs="12" md="auto" className="px-0 py-4 py-md-0 px-md-2">
                    <Button
                      className="w-100"
                      style={{
                        fontSize: "1rem",
                      }}
                      disabled={!location?.address || isLocationLoading}
                      onClick={() => {
                        if (location?.address !== undefined) {
                          setIsLocationLoading(true);
                          FetchLocations(
                            location.address,
                            (addresses) => {
                              setLoadedAddresses(addresses);
                              setIsLocationLoading(false);
                            },
                            (error) => {
                              setIsLocationLoading(false);
                            }
                          );
                        }
                      }}
                    >
                      {isLocationLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </Col>
                  <Col xs="12" md="auto" className="px-0">
                    <div
                      className="w-100 hover-btn  border d-flex align-items-center justify-content-center"
                      style={{
                        fontSize: "1rem",
                      }}
                      onClick={() =>
                        getUserLocation(
                          (location) => {
                            setLocation({
                              latitude: location.coords.latitude.toString(),
                              longitude: location.coords.longitude.toString(),
                              address: `(${location.coords.latitude}, ${location.coords.longitude})`,
                            });
                          },
                          (error) => {
                            console.log(error);
                          }
                        )
                      }
                    >
                      <MdOutlineMyLocation className="me-1" /> Locate me
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="p-0">
                    <ListGroup>
                      {loadedAddresses.map((address) => (
                        <ListGroup.Item
                          className="location-item"
                          onClick={() => {
                            setLocation(address);
                          }}
                        >
                          {address.address}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Container>
                <Row className="justify-content-end">
                  <Col
                    xs={{
                      span: 12,
                      order: 2,
                    }}
                    md={{
                      span: "auto",
                      order: 1,
                    }}
                    className="p-0 "
                  >
                    <div
                      className="hover-btn border w-md-100 text-center mt-4 mt-md-0 me-2"
                      style={{
                        fontSize: "1rem",
                      }}
                      onClick={() => {
                        setOpenLocationModal(false);
                        setLocation(undefined);
                      }}
                    >
                      Cancel
                    </div>
                  </Col>
                  <Col
                    xs={{
                      span: 12,
                      order: 1,
                    }}
                    md={{
                      span: "auto",
                      order: 2,
                    }}
                    className="p-0"
                  >
                    <Button
                      className="w-md-100"
                      style={{
                        fontSize: "1rem",
                      }}
                      onClick={() => {
                        // onChange(
                        //   `${location?.address} (${location?.latitude},${location?.longitude})`
                        // );
                        field.onChange({
                          ...field?.value,
                          value: `${location?.address} (${location?.latitude},${location?.longitude})`,
                          id: questionId,
                        });
                        setOpenLocationModal(false);
                      }}
                    >
                      Save and apply
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Footer>
          </Modal>
        </Fragment>
      );
    }

    if (type === "DATETIME") {
      return (
        <DatePicker
          selected={selectedDate}
          customInput={<TextInputWithRef />}
          showTimeSelect
          dateFormat="dd/MM/yyyy h:mm aa"
          className="qs-calendar"
          wrapperClassName="qs-calendar-wrapper"
          popperPlacement="bottom"
          {...field}
          value={undefined}
          onChange={(date) => {
            setSelectedDate(date);
            if (date) {
              field.onChange({
                ...field?.value,
                id: questionId,
                value: moment(date.toISOString()).unix(),
              });
            }
          }}
        />
      );
    }

    if (type === "MULTICHOICE_RADIO" || type === "MULTICHOICE_CHECKBOX") {
      return (
        <Container fluid>
          <Row>
            {(
              field.value?.multiOptions ??
              template?.pages?.[pageIndex].questions?.[questionIndex]?.options
            )?.map((op: any) => (
              <Col sm="12" md="auto" className="mt-sm-2 mt-md-0">
                <Button
                  variant={
                    field.value?.multiOptionValues?.some(
                      ({id}) => id === (op.id ?? op.dummyId)
                    ) || op.selected
                      ? "primary"
                      : "light"
                  }
                  className="w-100"
                  onClick={() => {
                    if (type === "MULTICHOICE_RADIO") {
                      field.onChange({
                        ...field.value,
                        // id: questionId,
                        multiOptionValues: [
                          {
                            id: op?.id ?? op.dummyId,
                          },
                        ],
                      });
                    } else {
                      const existingOptions = field.value?.multiOptionValues;

                      if (
                        existingOptions?.some(
                          ({id}) => id === (op?.id ?? op.dummyId)
                        )
                      ) {
                        field.onChange({
                          ...field.value,
                          id: op.id,
                          multiOptionValues: existingOptions.filter(
                            ({id}) => id !== (op?.id ?? op.dummyId)
                          ),
                        });
                      } else {
                        field.onChange({
                          ...field.value,
                          id: op.id,
                          multiOptionValues: [
                            ...(field.value?.multiOptionValues ?? []),
                            {
                              id: op?.id ?? op.dummyId,
                            },
                          ],
                        });
                      }
                    }
                  }}
                >
                  {op.title}
                </Button>
              </Col>
            ))}
          </Row>
        </Container>
      );
    }

    return <></>;
  }
);

function BottomActionBar({
  mediaUniqueId,
  inspectionForm,
  pageIndex,
  questionIndex,
}: {
  mediaUniqueId?: string;
  inspectionForm: UseFormReturn<Inspection.InspectionSubmitPayload, object>;
  questionIndex: number;
  pageIndex: number;
}) {
  const notifications = useNotifications();
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const [isNoteEditorOpen, setIsNoteEditorOpen] = useState(false);
  const [notes, setNotes] = useState("");

  const [isActionEditorOpen, setIsActionEditorOpen] = useState(false);
  const [actions, setActions] = useState<Array<string>>([]);

  const actionEditorRef = useRef<HTMLTextAreaElement>(null);

  const imageInputRef = useRef<HTMLInputElement>(null);
  const [isUploadingImage, setIsUploadingImage] = useState<Array<number>>([]);

  const [attachments, setAttachments] = useState<
    Array<{
      base64: string;
      name: string;
      type: string;
      id: number;
    }>
  >([]);
  const removeIdfromUploadingArray = (idToRemove: number) => {
    setIsUploadingImage(isUploadingImage.filter((id) => id !== idToRemove));
    setAttachments(attachments.filter(({id}) => id !== idToRemove));
  };

  const changeMediaStatus = (idToUpdate: number) => {
    setIsUploadingImage(isUploadingImage.filter((id) => id !== idToUpdate));
  };

  function onImageDelete(id: number) {
    setAttachments(attachments.filter((_, i) => _.id !== id));
  }

  function uploadAttachedMedia(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0];

    if (file) {
      const fileId = file?.lastModified + file?.size;
      if (mediaUniqueId) {
        getBase64(e)
          .then((base64) => {
            if (base64) {
              // setIsUploadingImage([...isUploadingImage, fileId]);
              // setAttachments([
              //   ...attachments,
              //   {
              //     base64,
              //     name: file.name,
              //     type: file.type,
              //     id: fileId,
              //   },
              // ]);

              Inspection.UploadInspectionMedia(
                mediaUniqueId,
                {
                  base64,
                  fileName: file.name,
                },
                (url) => {
                  changeMediaStatus(watchMedia?.length ?? 0);
                  inspectionForm.setValue(
                    `pages.${pageIndex}.questions.${questionIndex}.mediaPaths`,
                    [
                      ...(inspectionForm.getValues(
                        `pages.${pageIndex}.questions.${questionIndex}.mediaPaths`
                      ) ?? []),
                      url,
                    ]
                  );
                },
                (error) => {
                  notifications.showNotification(
                    {
                      type: "ERROR",
                    },
                    error,
                    file.name
                  );
                  removeIdfromUploadingArray(fileId);
                }
              );
            }
          })
          .catch((err) => {
            notifications.showNotification(
              {
                type: "ERROR",
                manualDismiss: true,
              },
              "Failed to upload attachment",
              file.name
            );
          });
      } else {
        notifications.showNotification(
          {
            type: "ERROR",
          },
          "Unable to upload attached media",
          "Cannot find media attachment key"
        );
        removeIdfromUploadingArray(fileId);
      }
    }
    e.target.value = "";
  }

  const watchedNotes = inspectionForm.watch(
    `pages.${pageIndex}.questions.${questionIndex}.notes`
  );

  const watchMedia = inspectionForm.watch(
    `pages.${pageIndex}.questions.${questionIndex}.mediaPaths`
  );

  return (
    <Row>
      <Col xs="12">
        <Stack direction="horizontal" className="mt-3 flex-wrap">
          {watchMedia

            // .filter((attachment) => attachment.type.includes("image"))
            ?.map((attachment, i) => (
              <div key={i} className="">
                <div className="position-relative m-2 d-inline-block">
                  <Image
                    // src={`data:image/png;base64,${attachment.base64}`}
                    src={attachment}
                    style={{
                      width: "64px",
                      height: "64px",
                    }}
                    rounded
                  />
                  <div
                    className="position-absolute animated-btn"
                    style={{
                      top: -10,
                      right: -10,
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                    onClick={() => onImageDelete(i)}
                  >
                    {isUploadingImage.some((id) => id === i) ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <MdDelete size={20} color="red" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </Stack>
        <Stack>
          {attachments

            .filter((attachment) => !attachment.type.includes("image"))
            .map((attachment, i) => (
              <AttachmentItem
                name={attachment.name}
                key={i}
                onDelete={() => onImageDelete(attachment.id)}
              />
            ))}
        </Stack>
      </Col>
      <Col xs="12">
        {isNoteEditorOpen ? (
          <div className="mt-3">
            <ResizeTextArea
              className="form-control"
              maxRows={5}
              placeholder="Enter your notes...."
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              value={notes ?? watchedNotes ?? ""}
            />
            <Stack direction="horizontal" className="mt-2">
              <Button
                // disabled={isNoteSavingButtonDisabled}
                onClick={() => {
                  setIsNoteEditorOpen(false);
                  inspectionForm.setValue(
                    `pages.${pageIndex}.questions.${questionIndex}`,
                    {
                      ...inspectionForm.getValues(
                        `pages.${pageIndex}.questions.${questionIndex}`
                      ),
                      notes,
                    }
                  );
                }}
              >
                Save
              </Button>
              <Button
                className="ms-2 hover-btn"
                variant="light"
                onClick={() => {
                  setIsNoteEditorOpen(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          </div>
        ) : (
          (watchedNotes?.length ?? 0) > 0 && (
            <Text
              fontSize="0.8rem"
              fontWeight="300"
              className="render-template-notes"
              onClick={() => {
                setIsNoteEditorOpen(true);
              }}
            >
              {watchedNotes}
            </Text>
          )
        )}
      </Col>
      <Col xs="12">
        <div className="mt-3">
          {actions.length > 0 &&
            actions.map((action, index) => (
              <Stack
                key={index}
                direction="horizontal"
                className="border p-2 rounded my-2 justify-content-between"
              >
                <Stack direction="horizontal">
                  <div>
                    <MdLibraryAddCheck size={15} className="me-2" />
                  </div>
                  <Text fontSize="0.8rem" fontWeight="300">
                    {action}
                  </Text>
                </Stack>
                <Badge>TO DO</Badge>
              </Stack>
            ))}
          {isActionEditorOpen && (
            <div className="mt-2">
              <ResizeTextArea
                className="form-control"
                maxRows={5}
                placeholder="What needs to be done?"
                ref={actionEditorRef}
              />

              <Stack direction="horizontal" className="mt-2">
                <Button
                  onClick={() => {
                    if (actionEditorRef.current)
                      setActions([...actions, actionEditorRef.current?.value]);
                    setIsActionEditorOpen(false);
                  }}
                >
                  Create
                </Button>
                <Button
                  className="ms-2 hover-btn"
                  variant="light"
                  onClick={() => {
                    setIsActionEditorOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </div>
          )}
        </div>
      </Col>
      <Col xs="12" className="d-flex justify-content-end">
        <Stack direction="horizontal" className="mt-2 ">
          <div
            className="hover-btn align-items-center d-flex"
            onClick={() => {
              setIsNoteEditorOpen(true);
            }}
          >
            <MdOutlineNoteAlt size={15} className="me-1" />
            {isSmallDevice ? "Note" : "Add Note"}
          </div>

          <div
            className="hover-btn align-items-center d-flex"
            onClick={() => {
              imageInputRef.current?.click();
            }}
          >
            <MdOutlinePermMedia size={15} className="me-1" />
            {isSmallDevice ? "Media" : "Attach Media"}
          </div>
          <input
            hidden
            type="file"
            ref={imageInputRef}
            onChange={uploadAttachedMedia}
          />

          <div
            className="hover-btn align-items-center d-flex"
            onClick={() => {
              setIsActionEditorOpen(true);
            }}
          >
            <MdOutlineLibraryAddCheck size={15} className="me-1" />
            {isSmallDevice ? "Action" : "Create action"}
          </div>
        </Stack>
      </Col>
    </Row>
  );
}
