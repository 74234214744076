import "./side-nav-bar.scss";
import Logo from "./../../assets/logo-horizontal.svg";
import { Button, Image, OverlayTrigger, Popover } from "react-bootstrap";
import {
  HiOutlineHome,
  HiOutlineTemplate,
  HiOutlineClipboardCheck,
  HiOutlineCalendar,
  HiOutlineCheckCircle,
  HiOutlineSpeakerphone,
} from "react-icons/hi";
import {
  MdOutlineNotificationsActive,
  MdMobileFriendly,
  MdOutlineSensors,
  MdOutlineMessage,
  MdLink,
  MdOutlineVideoCameraFront,
  MdOutlineVideoLabel,
  MdUploadFile,
  MdLightbulbOutline,
  MdOutlineSlowMotionVideo,
  MdUpdate,
} from "react-icons/md";
import { GoIssueOpened } from "react-icons/go";

import { VscFeedback } from "react-icons/vsc";
import { FiHelpCircle, FiSettings } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import React, { Fragment } from "react";
import Text from "../text/text";
import MenuPopupItem from "../menu/menu-item";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { useBusinessProfile } from "../../hooks/business";

export default function SideNavBar() {
  return (
    <div>
      <div className="p-3">
        <Image
          src={Logo}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>
      <div>
        <MenuButton
          Icon={HiOutlineHome}
          title="Dashboard"
          url="/app/dashboard"
        />
        {/* <MenuButton
          Icon={MdOutlineNotificationsActive}
          title="Notifications"
          url="/app/notifications"
        /> */}
        <hr className="shadow-lg" />
        <MenuButton
          Icon={HiOutlineTemplate}
          title="Templates"
          url="/app/template"
        />
        <MenuButton
          Icon={HiOutlineClipboardCheck}
          title="Inspections"
          url="/app/inspections"
        />
        <MenuButton
          Icon={HiOutlineCalendar}
          title="Schedule"
          url="/app/schedule"
        />
        {/* <MenuButton
          Icon={HiOutlineCheckCircle}
          title="Actions"
          url="/app/actions"
        />
        <MenuButton Icon={GoIssueOpened} title="Issues" url="/app/issues" /> */}
        {/* <MenuButton
          Icon={HiOutlineSpeakerphone}
          title="Heads Up"
          url="/app/heads-up"
        />
        <MenuButton Icon={MdMobileFriendly} title="EdApp" url="/app/edapp" />
        <MenuButton
          Icon={MdOutlineSensors}
          title="Sensors"
          url="/app/sensors"
        /> */}
        <hr className="shadow-lg" />
        {/* <MenuPopupButton
          title="Help"
          Icon={FiHelpCircle}
          content={HelpPopup()}
        /> */}
        <MenuPopupButton
          title="Saoud"
          Icon={FiSettings}
          content={ProfilePopup()}
        />
      </div>
    </div>
  );
}

function MenuButton({
  Icon,
  title,
  url,
}: {
  title: string;
  Icon: any;
  url?: string;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <Button
      variant="link"
      className={`${
        pathname === url ? "active" : "inactive"
      } text-decoration-none d-flex align-items-center icon-button w-100 menu-button-container py-2 rounded-0`}
      onClick={() => {
        url && navigate(url);
      }}
    >
      <Icon size={20} /> {title}
    </Button>
  );
}

function MenuPopupButton({
  Icon,
  title,
  content,
}: {
  title: string;
  Icon: any;
  content: any;
}) {
  return (
    <OverlayTrigger
      placement="right-end"
      rootClose
      trigger="click"
      overlay={
        <Popover
          className="ms-2 py-2 shadow border-0"
          style={{ minWidth: 300 }}
        >
          {content}
        </Popover>
      }
    >
      <span>
        <MenuButton title={title} Icon={Icon} />
      </span>
    </OverlayTrigger>
  );
}

function HelpPopup() {
  return (
    <Fragment>
      <MenuPopupItem title="Live Chat with support" Icon={MdOutlineMessage} />
      <MenuPopupItem
        title="Book a demonstration"
        Icon={MdOutlineVideoCameraFront}
        subTitle="Let us show you how to get started"
        externalLink="https://www.google.com"
      />
      <hr className="dropdown-divider" />
      <MenuPopupItem
        title="Webinars"
        Icon={MdOutlineVideoLabel}
        subTitle="Save a spot at a live training event"
        externalLink="https://www.google.com"
      />
      <MenuPopupItem
        title="Digitalize your forms"
        Icon={MdUploadFile}
        subTitle="Your forms converted into templates"
        externalLink="https://www.google.com"
      />
      <MenuPopupItem
        title="Knowledge Base"
        Icon={MdLightbulbOutline}
        subTitle="Find help in our support articles"
        externalLink="https://www.google.com"
      />
      <MenuPopupItem
        title="Tutorials"
        Icon={MdOutlineSlowMotionVideo}
        subTitle="Learn about QSComply Auditor with short videos"
        externalLink="https://www.google.com"
      />
      <hr className="dropdown-divider" />
      <MenuPopupItem
        title="Product updates"
        Icon={MdUpdate}
        externalLink="https://www.google.com"
      />
      <MenuPopupItem
        title="Send feedback"
        Icon={VscFeedback}
        externalLink="https://www.google.com"
      />
    </Fragment>
  );
}

function ProfilePopup() {
  return (
    <Fragment>
      <Text style={{ marginLeft: "32px" }} mode="SubHeading" fontWeight="700">
        QSComply
      </Text>
      <MenuPopupItem title="Users" />
      <MenuPopupItem title="Groups" />
      <MenuPopupItem title="Sites" />
      <MenuPopupItem title="Permissions" />
      <hr className="dropdown-divider" />
      <MenuPopupItem title="Integrations" />
      <MenuPopupItem title="Organization Settings" />
      <hr className="dropdown-divider" />
      <Text mode="SubHeading" fontWeight="700" style={{ marginLeft: "32px" }}>
        Saoud Nazir
      </Text>
      <MenuPopupItem title="Profile Settings" />
      <MenuPopupItem title="Logout" />
    </Fragment>
  );
}
