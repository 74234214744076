import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Pagination,
  Popover,
  Row,
  Table,
} from "react-bootstrap";

import {AddIcon, SearchIcon} from "../../../components/icons/icons";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";
import {Fragment, useCallback, useEffect, useState} from "react";
import SmallLogo from "./../../../assets/smalllogo.svg";
import moment from "moment";
import {
  MdArchive,
  MdEdit,
  MdLink,
  MdOutlineClose,
  MdOutlineHistory,
  MdSettings,
} from "react-icons/md";
import {BsThreeDotsVertical} from "react-icons/bs";
import MenuPopupItem from "../../../components/menu/menu-item";
import {HiDuplicate, HiClipboard, HiOutlineDownload} from "react-icons/hi";
import FilterButton from "../../../components/filter-button/filter-button";
import {Controller, useForm} from "react-hook-form";
import FilterPopup from "../../../components/filter-button/components/filter-popup";
import {useLinkClickHandler, useLocation, useNavigate} from "react-router-dom";
import InspectionAPI from "./../../../api/inspections";
import useNotifications from "../../../hooks/notification";
import ReportPDFPreview from "../../templates/components/report-pdf-preview";

type TableDataType = {
  id: number;
  name: string;
  actions: any;
  docNum: number;
  date: string;
  status: string;
};
type FilterPayloadType = {
  access: any;
  template: any;
  groups: any;
  site: any;
  date: any;
};
interface InspectionTabProps {
  mode: "Archive" | "Inspection";
}
export default function Inspection({mode}: InspectionTabProps) {
  const notifications = useNotifications();
  const navigate = useNavigate();
  const {
    formState: {errors},
    control,
    handleSubmit,
    reset,
  } = useForm<FilterPayloadType>({
    defaultValues: {
      access: undefined,
      template: undefined,
      groups: undefined,
      site: undefined,
      date: undefined,
    },
  });
  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string;
      key: string;
      open: boolean;
    }>
  >([]);

  const [openCanvas, setOpenCanvas] = useState(false);

  const tableColumns = [
    {
      dataField: "title",
      text: "Inspection",
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    // },
    // {
    //   dataField: "docNumber",
    //   text: "Doc Number",
    // },
    // {
    //   dataField: "score",
    //   text: "Score",
    // },
    {
      dataField: "conductedTimeStamp",
      text: "Conducted",
      sort: true,
    },
    {
      dataField: "status",
      text: "Completed",
    },
  ];
  const SAMPLE_TABLE_DATA: Array<TableDataType> = Array.from({
    length: 2,
  }).map((_, i) => ({
    id: i,
    name: "Inspection " + i,
    template: "SAMPLE - COVID-19 Daily Assessment",
    actions: "",
    docNum: i,
    date: `2022-07-${i + 1}`,
    status: "Completed",
    score: "100%",
  }));
  const [groupedData, setGroupedData] = useState<{
    [key: string]: Array<InspectionAPI.FetchInspection>;
  }>({});
  useEffect(() => {
    InspectionAPI.FetchAllInspections(
      (inspections) => {
        setGroupedData(
          inspections.reduce((acc: any, curr) => {
            const key = curr.startedAt?.split(" - ")?.[0];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
          }, {})
        );
      },
      (error) => {
        notifications.showNotification(
          {
            type: "ERROR",
            manualDismiss: true,
          },
          error
        );
      }
    );
  }, []);

  const [selectedInspection, setSelectedInspection] = useState<Array<string>>(
    []
  );

  const [searchedText, setSearchedText] = useState("");
  const filteredInspection = useCallback(() => {
    return Object.entries(groupedData).reduce(
      (acc: {[key: string]: Array<any>}, [key, value]) => {
        const filtered = value.filter((item) => {
          return item.title.toLowerCase().includes(searchedText.toLowerCase());
        });
        if (filtered.length > 0) {
          acc[key] = filtered;
        }
        return acc;
      },
      {}
    );
  }, [searchedText, groupedData]);

  const [selectedInspectionIdForDetails, setSelectedInspectionIdForDetails] =
    useState<string>();
  const [inspectionDetails, setInspectionDetails] =
    useState<InspectionAPI.FetchInspectionDetailsResponse>();

  useEffect(() => {
    if (selectedInspectionIdForDetails) {
      InspectionAPI.FetchInspectionDetails(
        selectedInspectionIdForDetails,
        (details) => {
          setInspectionDetails(details);
          setOpenCanvas(true);
        },
        (error) => {
          notifications.showNotification({type: "ERROR"}, error);
        }
      );
    }
  }, [selectedInspectionIdForDetails]);

  function closeOffcanvas() {
    setOpenCanvas(false);
    setInspectionDetails(undefined);
    setSelectedInspectionIdForDetails(undefined);
  }

  let location = useLocation();
  const navigateToReport = useLinkClickHandler<HTMLButtonElement>(
    `/app/inspections/report/${selectedInspectionIdForDetails}`,
    {
      state: {
        backgroundLocation: location,
      },
    }
  );

  return (
    <Fragment>
      <Container fluid className="py-4 px-5">
        <Text mode="Heading">
          {mode === "Archive" ? "Inspection archive" : "Inspections"}
        </Text>
        <Row className="mt-2">
          <Col xs="auto">
            <TextInput
              placeholder="Search"
              startIcon={<SearchIcon size={20} />}
              onChange={(e) => setSearchedText(e.target.value)}
            />
          </Col>
          {appliedFilters.map((af, i) => (
            <Controller
              key={i}
              control={control}
              name={af.key as any}
              render={({field: {onChange, value, name}}) => (
                <Col xs="auto">
                  <FilterButton
                    open={af.open}
                    setOpen={(open) => {
                      setAppliedFilters((prev) => {
                        return [...prev.slice(0, i), {...prev[i], open}];
                      });
                    }}
                    type={name as any}
                    label={af.label}
                    defaultValue={value}
                    onChange={onChange}
                    onDone={handleSubmit(
                      (data) => {
                        // console.log({ data });
                      },
                      (error) => {
                        // console.log(error);
                      }
                    )}
                    onClear={() => {
                      reset({[name]: undefined});
                    }}
                  />
                </Col>
              )}
            />
          ))}

          <Col xs="auto">
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={
                <Popover
                  className="qs-dropdown-popup p-0 py-2"
                  style={{minWidth: "160px"}}
                >
                  {[
                    {
                      label: "Access",
                      key: "access",
                      open: true,
                    },
                    {
                      label: "Template",
                      key: "template",
                      open: true,
                    },
                    {
                      label: "Groups",
                      key: "groups",
                      open: true,
                    },
                    {
                      label: "Site",
                      key: "site",
                      open: true,
                    },
                    {
                      label: "Date",
                      key: "date",
                      open: true,
                    },
                  ].map((filter, i) => (
                    <Text
                      key={i}
                      className="inspection-filter-option px-3 pointer"
                      onClick={() => {
                        setAppliedFilters([...appliedFilters, filter]);
                        document.body.click();
                      }}
                    >
                      {filter.label}
                    </Text>
                  ))}
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-decoration-none fw-bold d-flex align-items-center"
              >
                <AddIcon size={20} className="me-2" /> Add filter
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table className="inspection-table border mt-3">
              <thead>
                <tr>
                  <td align="center">
                    <Form.Check
                      onClick={(e: any) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                          setSelectedInspection(
                            Object.values(groupedData).map((item) => item[0].id)
                          );
                        } else {
                          setSelectedInspection([]);
                        }
                      }}
                    />
                  </td>
                  {tableColumns.map((column) => (
                    <td key={column.dataField}>{column.text}</td>
                  ))}
                  {/* <td></td> */}
                  <td align="center">
                    <MdSettings size={20} />
                  </td>
                </tr>
              </thead>
              <tbody>
                {groupedData &&
                  Object.entries(filteredInspection()).map(
                    ([key, values], i) => {
                      const date = moment(key, "DD/MM/YYYY - hh:mm");
                      let dateLabel = "";
                      if (date.isSame(moment(), "date")) {
                        dateLabel = "Today";
                      } else if (
                        date.isSame(moment().subtract(1, "day"), "date")
                      ) {
                        dateLabel = "Yesterday";
                      } else {
                        dateLabel = date.fromNow();
                      }

                      return (
                        <Fragment key={i}>
                          <tr className="inspection-table-date">
                            <td align="center">
                              <Form.Check
                                onChange={(e: any) => {
                                  e.stopPropagation();
                                  if (e.target.checked) {
                                    setSelectedInspection([
                                      ...selectedInspection,
                                      ...values.map((v) => v.id),
                                    ]);
                                  } else {
                                    setSelectedInspection(
                                      selectedInspection.filter(
                                        (v) =>
                                          !values.map((v) => v.id).includes(v)
                                      )
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td colSpan={tableColumns.length + 2}>
                              <Text mode="Normal">{dateLabel}</Text>
                            </td>
                          </tr>
                          {values.map((value, i) => (
                            <tr
                              key={i}
                              className="inspection-table-row-item"
                              onClick={(e) => {
                                setOpenCanvas(true);
                                setSelectedInspectionIdForDetails(value.id);
                                // navigate(`/app/inspections/view/${value.id}`);
                              }}
                            >
                              <td align="center">
                                <Form.Check
                                  checked={selectedInspection.includes(
                                    value.id
                                  )}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e: any) => {
                                    e.stopPropagation();
                                    if (selectedInspection.includes(value.id)) {
                                      setSelectedInspection(
                                        selectedInspection.filter(
                                          (id) => id !== value.id
                                        )
                                      );
                                    } else {
                                      setSelectedInspection([
                                        ...selectedInspection,
                                        value.id,
                                      ]);
                                    }
                                  }}
                                />
                              </td>
                              {tableColumns.map((column) => (
                                <td key={column.dataField}>
                                  {column.dataField === "title" ? (
                                    <div className="d-flex align-items-center">
                                      {/* <Image
                                        src={SmallLogo}
                                        style={{ width: 35, height: 35 }}
                                        className="me-2"
                                      /> */}
                                      <div>
                                        <Text mode="Normal" className="fw-bold">
                                          {moment(
                                            value?.conductedTimeStamp?.replace(
                                              "[Etc/UTC]",
                                              ""
                                            )
                                          ).format("DD/MM/YYYY - hh:mm A")}{" "}
                                          / {value?.siteConducted} /{" "}
                                          {value?.conductedBy}
                                        </Text>
                                        <Text className="fw-normal text-muted">
                                          {value?.templateTitle}
                                        </Text>
                                      </div>
                                    </div>
                                  ) : (
                                    <Text fontWeight="400">
                                      {column.dataField === "conductedTimeStamp"
                                        ? moment(
                                            value[column.dataField].replace(
                                              "[Etc/UTC]",
                                              ""
                                            )
                                          ).format("DD/MM/YYYY - hh:mm A")
                                        : value[column.dataField]}
                                    </Text>
                                  )}
                                </td>
                              ))}
                              {/* <td>
                                <Button
                                  variant="link"
                                  className="text-decoration-none fw-bold"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate("/app/template/preview");
                                  }}
                                >
                                  Continue
                                </Button>
                              </td> */}
                              <td align="center">
                                <OverlayTrigger
                                  placement="left-start"
                                  overlay={
                                    <Popover
                                      className="ms-2 py-2 shadow border-0"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <InspectionMenu />
                                    </Popover>
                                  }
                                  trigger="click"
                                  rootClose
                                >
                                  <div
                                    className="inspection-table-settings-btn d-inline-block p-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <BsThreeDotsVertical size={20} />
                                  </div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                        </Fragment>
                      );
                    }
                  )}
              </tbody>
            </Table>
            {/* <div className="d-flex justify-content-end">
              <Pagination>
                <Pagination.First />
                <Pagination.Item>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Last />
              </Pagination>
            </div> */}
          </Col>
        </Row>
      </Container>

      <Offcanvas show={openCanvas} onHide={closeOffcanvas} placement="end">
        <div className="inspection-offcanvas-detector" />
        <Offcanvas.Header>
          <div className="pointer animated-btn" onClick={closeOffcanvas}>
            <MdOutlineClose size={20} />
          </div>
          <Text mode="SubHeading" fontWeight="600">
            Inspection
          </Text>
          <div className="pointer animated-btn">
            <OverlayTrigger
              placement="left-start"
              overlay={
                <Popover
                  className="ms-2 py-2 shadow border-0"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <InspectionMenu />
                </Popover>
              }
              trigger="click"
              rootClose
            >
              <div>
                <BsThreeDotsVertical size={20} />
              </div>
            </OverlayTrigger>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="px-3">
            <Text mode="Heading" className="mb-3">
              {inspectionDetails?.template}
            </Text>
            <div className="d-flex justify-content-between align-items-center">
              <Button size="lg" className="flex-grow-1">
                Continue
              </Button>
              <Dropdown className="border rounded mx-2">
                <Button
                  variant="link"
                  className="text-decoration-none"
                  size="lg"
                  onClick={navigateToReport}
                >
                  <HiOutlineDownload size={22} /> PDF
                </Button>
                <Dropdown.Toggle variant="link" size="lg" />
                <Dropdown.Menu>
                  <Dropdown.Item>Word</Dropdown.Item>
                  <Dropdown.Item>CSV</Dropdown.Item>
                  <Dropdown.Item>CSV with media</Dropdown.Item>
                  <Dropdown.Item>JSON</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                size="lg"
                variant="link"
                className="text-decoration-none border"
                style={{
                  verticalAlign: "middle",
                }}
              >
                <MdLink size={22} /> Share
              </Button>
            </div>
          </div>
          <div className="bg-light p-3 mt-2 h-100">
            <div className="d-flex justify-content-between mb-2">
              <Text mode="Heading">Details</Text>
              <Button
                variant="link"
                className="text-decoration-none d-flex align-items-center"
                style={{
                  fontWeight: "500",
                }}
              >
                <AddIcon size={18} className="me-1" /> Create Action
              </Button>
            </div>
            <div className="border rounded bg-white p-2">
              <Text className="text-muted">Template</Text>
              <Text fontWeight="400" fontSize="16px">
                {inspectionDetails?.template}
              </Text>
              <Text className="text-muted mt-2">Status</Text>
              <Text fontWeight="400" fontSize="16px">
                {inspectionDetails?.status}
              </Text>
              <Text className="text-muted mt-2">Score</Text>
              <Text fontWeight="400" fontSize="16px">
                {`${inspectionDetails?.score}%`}
              </Text>
              <Text className="text-muted mt-2">Items completed</Text>
              <Text fontWeight="400" fontSize="16px">
                {inspectionDetails?.itemsCompleted}
              </Text>
              <Text className="text-muted mt-2">Location</Text>
              <Text fontWeight="400" fontSize="16px">
                {inspectionDetails?.location}
              </Text>
              <Text className="text-muted mt-2">Owner</Text>
              <Text fontWeight="400" fontSize="16px">
                {inspectionDetails?.owner}
              </Text>
              <Text className="text-muted mt-2">Last edited by</Text>
              <Text fontWeight="400" fontSize="16px">
                {inspectionDetails?.lastEditedBy}
              </Text>
              <Text className="text-muted mt-2">Started</Text>
              <Text fontWeight="400" fontSize="16px">
                {inspectionDetails?.started}
              </Text>
              <Text className="text-muted mt-2">Updated</Text>
              <Text fontWeight="400" fontSize="16px" variant="primary">
                {inspectionDetails?.updated}
              </Text>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
}

function InspectionMenu() {
  return (
    <Fragment>
      <MenuPopupItem title="Edit inspection" Icon={MdEdit} />
      <MenuPopupItem title="Duplicate inspection" Icon={HiDuplicate} />
      <MenuPopupItem title="View Report" Icon={HiClipboard} />
      <MenuPopupItem title="View History" Icon={MdOutlineHistory} />
      <MenuPopupItem title="Archive" Icon={MdArchive} />
    </Fragment>
  );
}
