import React from "react";
import { BootstrapVaraints } from "../../scss/bootstrap.type";

enum FontSizes {
  Heading = "fs-4 fw-bold",
  SubHeading = "fs-5 fw-semibold",
  Normal = "fs-6",
}
enum FontColor {
  dark = "text-dark",
  light = "text-black-50",
  primary = "text-primary",
  secondary = "text-secondary",
  danger = "text-danger",
  success = "text-success",
  muted = "text-muted",
}
export interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  fontSize?: string;
  fontWeight?:
    | "normal"
    | "bold"
    | "bolder"
    | "lighter"
    | "initial"
    | "inherit"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";
  color?: string;
  variant?: BootstrapVaraints;
  mode?: keyof typeof FontSizes;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  flexType?: string;
}
export default function Text({
  children,
  fontSize,
  fontWeight,
  color,
  startIcon,
  endIcon,
  variant = "dark",
  mode,
  flexType = "d-flex",
  ...restOfProps
}: TextProps) {
  return (
    <div
      {...restOfProps}
      className={`${restOfProps.className ?? ""} ${
        color ? "" : FontColor[variant]
      }  ${
        !fontSize ? (mode ? FontSizes[mode] : "") : ""
      } ${flexType} align-items-center`}
      style={{
        fontSize,
        fontWeight,
        color,
        // lineHeight: "1rem",

        ...restOfProps.style,
      }}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </div>
  );
}

export const TextWithRef = React.forwardRef<HTMLDivElement, TextProps>(
  (props, ref) => (
    <div ref={ref}>
      <Text {...props} />
    </div>
  )
);
