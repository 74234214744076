import {
  Badge,
  Button,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import Text from "../../components/text/text";
import "./dashboard.scss";
import { RiTeamFill } from "react-icons/ri";
import { HiPlus } from "react-icons/hi";
import {
  ActionsIcon,
  InspectionIcon,
  IssueIcon,
} from "../../components/icons/icons";
import { GoPrimitiveDot } from "react-icons/go";

import { useNavigate } from "react-router-dom";
import TemplateBuildImage from "./../../assets/template-build.png";
import DailyActivityImage from "./../../assets/daily-activity.png";
import useNotifications from "../../hooks/notification";
import moment from "moment";

export default function Dashboard({}) {
  const navigate = useNavigate();
  const Notifications = useNotifications();
  return (
    <Container fluid>
      <Row>
        <Col md="12" lg="auto" className="dashboard-content-wrapper">
          <Row className="align-items-center">
            <Col>
              <Text mode="Heading">Dashboard</Text>
            </Col>
            <Col xs="auto">
              <Row xs="auto" className="align-items-center">
                <Col>
                  <Text
                    startIcon={<RiTeamFill className="me-1" />}
                    variant="light"
                  >
                    My Team
                  </Text>
                </Col>
                {Array.from({ length: 5 }).map((_, i) => (
                  <Col className="p-0 pe-1" key={i}>
                    <div className="add-team-btn">
                      <HiPlus color="" />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <div className="mt-3">
            <Text mode="SubHeading" fontWeight="600">
              Build your inspection template
            </Text>
            <div className="border rounded p-5 d-flex flex-column align-items-center mt-4">
              <Image src={TemplateBuildImage} className="dashboard-image" />
              <Text className="w-50 text-center my-3">
                Convert your current paper inspections into smart inspection
                forms in minutes.
              </Text>
              <Button
                onClick={() => {
                  navigate("/app/template/create");
                }}
              >
                Build inspection template
              </Button>
            </div>
          </div>
          <div className="mt-3">
            <Text mode="SubHeading" fontWeight="600">
              To Do
            </Text>
            <div className="mt-4">
              <ListGroup>
                {Array.from({ length: 5 }).map((_, i) => (
                  <ListGroup.Item className="to-do-item d-flex py-3" key={i}>
                    <div className="to-do-item-icon-container p-1">
                      <InspectionIcon className="to-do-item-icon" size={25} />
                    </div>
                    <div className="mx-2 w-100">
                      <div className="d-flex justify-content-between">
                        <Text>Saoud Nazir</Text>
                        <Text
                          variant="primary"
                          fontSize="0.7rem"
                          fontWeight="bold"
                        >
                          In Progress
                        </Text>
                      </div>
                      <Text className="my-2" variant="light">
                        Sample - COVID-19 Daily Assessment
                      </Text>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex align-items-center">
                          <Badge bg="primary" style={{ fontSize: "0.7rem" }}>
                            Inspection
                          </Badge>
                          <GoPrimitiveDot className="mx-2" />
                          <Text variant="light" fontSize="0.8rem">
                            0% score
                          </Text>
                        </div>
                        <Text variant="light" fontSize="0.8rem">
                          Last update 22 Jun 2022 3:24 PM
                        </Text>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>

          <Text fontSize="2rem" fontWeight="700" className="mt-3">
            Activity
          </Text>
          <div className="mt-3">
            <Text mode="SubHeading" fontWeight="600">
              Open Issues
            </Text>
            <div className="mt-4">
              <ListGroup>
                {Array.from({ length: 5 }).map((_, i) => (
                  <ListGroup.Item className="to-do-item d-flex py-3" key={i}>
                    <div className="to-do-item-icon-container p-1">
                      <IssueIcon className="to-do-item-icon" size={25} />
                    </div>
                    <div className="mx-2 w-100">
                      <div className="d-flex justify-content-between">
                        <Text>Saoud Nazir</Text>
                        <Text
                          variant="danger"
                          fontSize="0.7rem"
                          fontWeight="bold"
                        >
                          Open
                        </Text>
                      </div>

                      <div className="d-flex align-items-center justify-content-between w-100 mt-2">
                        <Badge bg="primary" style={{ fontSize: "0.7rem" }}>
                          Maintenance
                        </Badge>

                        <Text variant="light" fontSize="0.8rem">
                          22 Jun 2022 3:24 PM
                        </Text>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>

          <div className="mt-3">
            <Text mode="SubHeading" fontWeight="600">
              Recently Completed
            </Text>
            <div className="mt-4">
              <ListGroup>
                {Array.from({ length: 2 }).map((_, i) => (
                  <ListGroup.Item className="to-do-item d-flex py-3" key={i}>
                    <div className="to-do-item-icon-container p-1">
                      <ActionsIcon className="to-do-item-icon" size={25} />
                    </div>
                    <div className="mx-2 w-100">
                      <div className="d-flex justify-content-between">
                        <Text>Action 1</Text>
                        <Text
                          variant="success"
                          fontSize="0.7rem"
                          fontWeight="bold"
                        >
                          Completed
                        </Text>
                      </div>

                      <div className="d-flex align-items-center justify-content-between w-100 mt-2">
                        <Badge bg="primary" style={{ fontSize: "0.7rem" }}>
                          Action
                        </Badge>

                        <Text variant="light" fontSize="0.8rem">
                          22 Jun 2022 3:24 PM
                        </Text>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>
        </Col>
        <Col
          lg="4"
          className="daily-activity-container d-lg-block d-none border-start shadow-sm p-3"
        >
          <div className="no-daily-activity-wrapper border shadow-sm rounded p-4 d-flex flex-column align-items-center">
            <Image
              src={DailyActivityImage}
              className="dashboard-image"
              rounded
            />
            <Text className="my-2" mode="SubHeading" fontWeight="bold">
              Daily activity (last 30 days)
            </Text>
            <Text variant="light">No inspection data yet</Text>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
