import {useCallback, useState} from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  OverlayTrigger,
  Pagination,
  Popover,
  Row,
} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {getInitials} from "../../../api/general";
import FilterButton from "../../../components/filter-button/filter-button";
import {AddIcon, SearchIcon} from "../../../components/icons/icons";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";

export default function ManageSchedules({}) {
  const navigate = useNavigate();
  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string;
      key: string;
      open: boolean;
    }>
  >([]);
  const [searchedText, setSearchedText] = useState("");
  const {
    formState: {errors},
    control,
    handleSubmit,
    reset,
  } = useForm<any>({
    defaultValues: {
      template: undefined,
      status: undefined,
      assignedTo: undefined,
      assignedBy: undefined,
    },
  });

  const [filters, setFilters] = useState([
    {
      label: "Template",
      key: "template",
      open: true,
    },
    {
      label: "Assigned to",
      key: "assignedTo",
      open: true,
    },
    {
      label: "Assigned by",
      key: "assignedBy",
      open: true,
    },
    {
      label: "Status",
      key: "status",
      open: true,
    },
  ]);

  const [schedules, setSchedules] = useState<Array<any>>(
    Array.from({length: 7}).map((_, i) => ({
      title: "Inspection " + i,
      assignedBy: "Saoud Nazir",
      assignedTo: "Saoud Nazir",
      status: "Active",
      nextDue: "1234",
      id: i,
    }))
  );
  const filteredSchedules = useCallback(() => {
    return schedules.filter((schedule) => {
      return schedule.title.toLowerCase().includes(searchedText.toLowerCase());
    });
  }, [searchedText]);

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col xs="auto" className="ps-0">
          <TextInput
            placeholder="Search"
            startIcon={<SearchIcon size={20} />}
            onChange={(e) => setSearchedText(e.target.value)}
          />
        </Col>
        {appliedFilters.map((af, i) => (
          <Controller
            key={i}
            control={control}
            name={af.key as any}
            render={({field: {onChange, value, name}}) => (
              <Col xs="auto">
                <FilterButton
                  open={af.open}
                  setOpen={(open) => {
                    setAppliedFilters((prev) => {
                      return [...prev.slice(0, i), {...prev[i], open}];
                    });
                  }}
                  type={name as any}
                  label={af.label}
                  defaultValue={value}
                  onChange={onChange}
                  onDone={handleSubmit(
                    (data) => {
                      // console.log({ data });
                    },
                    (error) => {
                      // console.log(error);
                    }
                  )}
                  onClear={() => {
                    reset({[name]: undefined});
                    setAppliedFilters((prev) => {
                      return [...prev.slice(0, i)];
                    });
                  }}
                />
              </Col>
            )}
          />
        ))}

        <Col xs="auto">
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            rootClose
            overlay={
              <Popover
                className="qs-dropdown-popup p-0 py-2"
                style={{minWidth: "160px"}}
              >
                {filters.map((filter, i) => (
                  <Text
                    key={i}
                    className="inspection-filter-option px-3 pointer"
                    onClick={() => {
                      setAppliedFilters([...appliedFilters, filter]);
                      document.body.click();
                    }}
                  >
                    {filter.label}
                  </Text>
                ))}
              </Popover>
            }
          >
            <Button
              variant="link"
              className="text-decoration-none fw-bold d-flex align-items-center"
            >
              <AddIcon size={20} className="me-2" /> Add filter
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="p-0">
          <ListGroup>
            <ListGroup.Item className="schedule-manage-header">
              <Row>
                <Col>
                  <Text>Title</Text>
                </Col>
                <Col>
                  <Text>Assigned by</Text>
                </Col>
                <Col>
                  <Text>Assigned to</Text>
                </Col>
                <Col>
                  <Text>Status</Text>
                </Col>
                <Col>
                  <Text>Next due</Text>
                </Col>
              </Row>
            </ListGroup.Item>
            {filteredSchedules().map(
              ({assignedBy, assignedTo, nextDue, status, title, id}, i) => (
                <ListGroup.Item
                  key={i}
                  className="schedule-manage-item elevated-item"
                  onClick={() => {
                    navigate(`/app/schedule/inspection/${id}`);
                  }}
                >
                  <Row className="align-items-center">
                    <Col>
                      <Text className="schedule-manage-item-text">{title}</Text>
                    </Col>
                    <Col>
                      <div className="schedule-manage-item-text initials">
                        {getInitials(assignedBy)}
                      </div>
                    </Col>
                    <Col>
                      <div className="schedule-manage-item-text initials">
                        {getInitials(assignedTo)}
                      </div>
                    </Col>
                    <Col>
                      <Text className="schedule-manage-item-text">
                        <Badge>{status}</Badge>
                      </Text>
                    </Col>
                    <Col>
                      <Text className="schedule-manage-item-text">
                        {nextDue}
                      </Text>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )
            )}
          </ListGroup>
          <div className="d-flex justify-content-end mt-3">
            <Pagination>
              <Pagination.First />
              <Pagination.Item>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Last />
            </Pagination>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
