import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import BusinessModule, {IBusiness} from "../api/Business";
import {localStorageKeys} from "../constant";
import {useUserProfile} from "./user";

interface BusinessHookProps extends IBusiness {
  setBusiness: Function;
  businesses: Array<IBusiness>;
}
const BusinessContext = React.createContext<BusinessHookProps>({
  setBusiness: () => {},
  businessName: "",
  id: 0,
  logoPath: "",
  branding: "",
  businesses: [],
});

export function BusinessProfileProvider({children}: any) {
  const [Business, setBusiness] = useState<IBusiness>({
    businessName: "",
    id: 0,
    logoPath: "",
    branding: "",
  });
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const user = useUserProfile();

  // useEffect(() => {
  //   BusinessModule.FetchBusinesses(
  //     (businesses) => {
  //       setBusinesses(businesses);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    if (user.businessId > 0) {
      BusinessModule.FetchBusinessById(
        user.businessId,
        (business) => {
          localStorage.setItem(
            localStorageKeys.BUSINESS,
            JSON.stringify(business)
          );
          setBusiness(business);
        },
        (error) => {
          alert("Error occured while fetching business details");
        }
      );
    }
  }, [user.businessId]);

  const navigate = useNavigate();
  useEffect(() => {
    const Business = localStorage.getItem(localStorageKeys.BUSINESS);
    if (Business) {
      setBusiness(JSON.parse(Business));
    } else {
      // navigate("/app/business-selection");
    }
  }, []);

  return (
    <BusinessContext.Provider value={{...Business, setBusiness, businesses}}>
      {children}
    </BusinessContext.Provider>
  );
}

export const useBusinessProfile = () =>
  React.useContext<BusinessHookProps>(BusinessContext);
