import { FC, useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import Card from "./droppable-item";
import { Col, ListGroup, Row } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Text from "../../../components/text/text";
import "./../template.scss";
import Template, {
  QuestionType,
  TemplateCreatePayload,
} from "../../../api/template";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import { UseFormReturn } from "react-hook-form";
export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  listItems?: QuestionType[];
  listType: string;
  onItemDelete: (index: number) => void;
  onPositionChanged: (newArray: QuestionType[]) => void;
  onAddQuestion: (index: number) => void;
  templateForm: UseFormReturn<Template.ITemplate, object>;
  pageIndex: number;
}

const DraggableContainer: FC<ContainerState> = ({
  listItems = [],
  listType,
  onItemDelete,
  onPositionChanged,
  onAddQuestion,
  templateForm,
  pageIndex,
}) => {
  {
    const [items, setItems] = useState(listItems);
    useEffect(() => {
      setItems(listItems);
    }, [listItems]);

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
      setItems((prevItems: QuestionType[]) => {
        const rearrangedArray = update(prevItems, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevItems[dragIndex] as QuestionType],
          ],
        });
        onPositionChanged(rearrangedArray);
        return rearrangedArray;
      });
    }, []);

    const renderItem = useCallback((card: QuestionType, index: number) => {
      return (
        <Card
          key={card.id}
          index={index}
          data={card}
          moveDroppableItem={moveItem}
          type={listType}
          onDelete={onItemDelete}
          onAddQuestion={onAddQuestion}
          templateForm={templateForm}
          pageIndex={pageIndex}
        />
      );
    }, []);
    return (
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <ListGroup style={{}}>
          <ListGroup.Item className="template-section-header">
            <Row>
              <Col md="12" lg="9">
                <Text fontSize="0.8rem">Question</Text>
              </Col>

              <Col xs="3" className="border-start d-none d-lg-block">
                <Text fontSize="0.8rem">Type of response</Text>
              </Col>
            </Row>
          </ListGroup.Item>
          {items.map((item, i) => renderItem(item, i))}
        </ListGroup>
      </DndProvider>
    );
  }
};

export default DraggableContainer;
