import { Button, Modal, ModalProps } from "react-bootstrap";
interface ReportTypeSelectionProps extends ModalProps {
  onSelect: (option: { id: number; text: string }) => void;
}
export default function ReportTypeSelection(props: ReportTypeSelectionProps) {
  return (
    <Modal {...props} size="sm" contentClassName="bg-white p-3">
      <Modal.Header closeButton className="p-0 border-0">
        <Modal.Title>What type of issue?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 mt-2">
        <Button
          variant="light"
          className="w-100 border my-1 fw-bold text-primary"
          onClick={() => props.onSelect({ id: 1, text: "Observation" })}
        >
          Observation
        </Button>
        <Button
          variant="light"
          className="w-100 border my-1 fw-bold text-primary"
        >
          Maintenance
        </Button>
      </Modal.Body>
    </Modal>
  );
}
