import {Fragment} from "react";
import {Image, Stack} from "react-bootstrap";
import {MdDelete} from "react-icons/md";

export default function AttachmentGallery({
  attachments,
  onDelete,
}: {
  // attachments: Array<{
  //   base64: string;
  //   name: string;
  //   type: string;
  //   id: number;
  //   url: string;
  // }>;
  attachments?: Array<string>;
  onDelete: (id: number) => void;
}) {
  return (
    <Fragment>
      <Stack direction="horizontal" className="mt-3 flex-wrap">
        {attachments?.map((attachment, i) => (
          <div key={i} className="">
            <div className="position-relative m-2 d-inline-block">
              <Image
                // src={
                //   attachment.url
                //     ? attachment.url
                //     : `data:image/png;base64,${attachment.base64}`
                // }
                src={attachment}
                style={{
                  width: "64px",
                  height: "64px",
                }}
                rounded
              />
              <div
                className="position-absolute animated-btn"
                style={{
                  top: -10,
                  right: -10,
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
                onClick={() => onDelete(i)}
              >
                <MdDelete size={20} color="red" />
              </div>
            </div>
          </div>
        ))}
      </Stack>
      {/* <Stack>
        {attachments

          .filter((attachment) => !attachment.type.includes("image"))
          .map((attachment, i) => (
            <AttachmentItem
              name={attachment.name}
              key={i}
              onDelete={() => onDelete(attachment.id)}
            />
          ))}
      </Stack> */}
    </Fragment>
  );
}
