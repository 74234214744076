import moment from "moment";
import {useCallback, useEffect, useState} from "react";
import {
  Button,
  Col,
  Container,
  FormCheck,
  Image,
  ListGroup,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {BsThreeDotsVertical} from "react-icons/bs";
import {
  MdArchive,
  MdClose,
  MdDelete,
  MdEdit,
  MdEditCalendar,
  MdOutlineBookmark,
  MdQrCode,
  MdRemoveRedEye,
  MdSettings,
} from "react-icons/md";
import {useMediaQuery} from "react-responsive";
import Template, {TemplateFilterPayload} from "../../../api/template";
import FilterButton from "../../../components/filter-button/filter-button";
import {AddIcon, SearchIcon} from "../../../components/icons/icons";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";
import {IoIosCopy, IoMdArchive, IoMdBookmarks, IoMdEye} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {TbClipboardText} from "react-icons/tb";
import {HiDuplicate} from "react-icons/hi";
import useNotifications from "../../../hooks/notification";

export default function TemplateTab({}) {
  const notifications = useNotifications();
  const navigate = useNavigate();
  const isExtraSmallDevice = useMediaQuery({query: "(max-width: 576px)"});

  const {control, handleSubmit, reset} = useForm<TemplateFilterPayload>({
    defaultValues: {
      ownedBy: [],
      availableTo: [],
    },
  });
  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string;
      key: string;
      open: boolean;
    }>
  >([]);
  const [searchedText, setSearchedText] = useState("");

  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const [showPreviewOffcanvas, setShowPreviewOffcanvas] = useState(false);

  const [checkedTemplates, setCheckedTemplates] = useState<string[]>([]);

  const [templates, setTemplates] = useState<Array<Template.FetchResponse>>([]);

  const filteredTemplates = useCallback(() => {
    return templates.filter((template) => {
      return template?.title
        ?.toLowerCase()
        ?.includes(searchedText?.toLowerCase());
    });
  }, [templates, searchedText]);

  const [tempIdToFetchDetails, setTempIdToFetchDetails] = useState<string>();

  useEffect(() => {
    Template.FetchAllTemplates(
      (temps) => {
        setTemplates(temps);
      },
      (error) => {
        notifications.showNotification(
          {
            type: "ERROR",
            manualDismiss: true,
          },
          "Unable to fetch templates",
          error
        );
      }
    );
  }, []);

  const [templateDetails, setTemplateDetails] =
    useState<Template.TemplateDetailsResponse>();
  useEffect(() => {
    if (tempIdToFetchDetails) {
      Template.FetchTemplateDetails(
        tempIdToFetchDetails,
        (details) => {
          setTemplateDetails(details);
        },
        (error) => {
          notifications.showNotification(
            {
              type: "ERROR",
            },
            "Unable to fetch template details"
          );
        }
      );
      setShowOffCanvas(true);
    }
  }, [tempIdToFetchDetails]);

  function hideOffCanvas() {
    setShowOffCanvas(false);
    setTempIdToFetchDetails(undefined);
  }

  return (
    <Container fluid="md">
      <Row className="mt-3">
        <Col>
          <Text mode="Heading">Templates</Text>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xs={{
            span: "auto",
            order: 1,
          }}
          md={{
            span: "auto",
            order: 1,
          }}
          className="d-flex flex-grow-1"
        >
          <TextInput
            placeholder="Search"
            startIcon={<SearchIcon size={20} />}
            onChange={(e) => setSearchedText(e.target.value)}
          />
        </Col>
        {appliedFilters.map((af, i) => (
          <Controller
            key={i}
            control={control}
            name={af.key as any}
            render={({field: {onChange, value, name}}) => (
              <Col
                xs={{
                  span: 12,
                  order: i + 3,
                }}
                md={{
                  span: "auto",
                  order: i + 1,
                }}
              >
                <div className="d-inline-block mt-2 mt-md-0">
                  <FilterButton
                    open={af.open}
                    setOpen={(open) => {
                      setAppliedFilters((prev) => {
                        return [...prev.slice(0, i), {...prev[i], open}];
                      });
                    }}
                    type={name as any}
                    label={af.label}
                    defaultValue={value}
                    onChange={onChange}
                    onDone={handleSubmit(
                      (data) => {
                        console.log({data});
                      },
                      (error) => {
                        console.log(error);
                      }
                    )}
                    onClear={() => {
                      reset({[name]: undefined});
                      setAppliedFilters(
                        appliedFilters.filter((f) => f.key !== af.key)
                      );
                    }}
                  />
                </div>
              </Col>
            )}
          />
        ))}
        <Col
          xs={{
            span: 12,
            order: appliedFilters.length + 3,
          }}
          md={{
            span: "auto",
            order: appliedFilters.length + 1,
          }}
        >
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            rootClose
            overlay={
              <Popover
                className="qs-dropdown-popup p-0 py-2"
                style={{minWidth: "160px"}}
              >
                {[
                  {
                    label: "Owned by",
                    key: "ownedBy",
                    open: true,
                  },
                  {
                    label: "Available to",
                    key: "availableTo",
                    open: true,
                  },
                ].map((filter, i) => (
                  <Text
                    key={i}
                    className="inspection-filter-option px-3 pointer"
                    onClick={() => {
                      setAppliedFilters([...appliedFilters, filter]);
                      document.body.click();
                    }}
                  >
                    {filter.label}
                  </Text>
                ))}
              </Popover>
            }
          >
            <Button
              variant="link"
              className="text-decoration-none fw-bold d-flex align-items-center"
            >
              <AddIcon size={20} className="me-2" /> Add filter
            </Button>
          </OverlayTrigger>
        </Col>
        <Col
          xs={{
            span: "auto",
            order: 2,
          }}
          md={{
            span: "auto",
            order: appliedFilters.length + 2,
          }}
          className="justify-content-end d-flex flex-grow-1"
        >
          <Button
            className="d-flex align-items-center"
            onClick={() => {
              navigate("/app/template/create");
            }}
          >
            <AddIcon size={18} /> Create
          </Button>
        </Col>
      </Row>

      {isExtraSmallDevice ? (
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item className="table-header-item">
                <Stack direction="horizontal">
                  <Text className="fs-normal">Template</Text>
                </Stack>
              </ListGroup.Item>
              {filteredTemplates().map((template, i) => (
                <ListGroup.Item
                  key={i}
                  className="pointer"
                  onClick={() => {
                    setTempIdToFetchDetails(template.id);
                  }}
                >
                  <Stack
                    direction="horizontal"
                    className="align-items-start"
                    key={i}
                  >
                    <div>
                      {template.imagePath && (
                        <Image
                          src={template.imagePath}
                          style={{
                            width: "1.8rem",
                            height: "1.8rem",
                          }}
                          roundedCircle
                          className="me-2"
                        />
                      )}
                    </div>
                    <Stack direction="vertical">
                      <Text fontWeight="bold">{template.title}</Text>

                      {/* <div>
                        <span
                          style={{
                            fontWeight: "400",
                          }}
                        >
                          Description:
                        </span>{" "}
                        {template.}
                      </div> */}

                      <div>
                        <span
                          style={{
                            fontWeight: "400",
                          }}
                        >
                          Last modified:
                        </span>{" "}
                        {moment().fromNow()}
                      </div>
                    </Stack>
                  </Stack>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <ListGroup className="mt-3 overflow-auto w-100">
              <ListGroup.Item className="table-header-item">
                <Row className="align-items-center flex-nowrap ">
                  <Col xs="auto">
                    <FormCheck
                      checked={
                        checkedTemplates.length === templates.length &&
                        templates.length > 0
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedTemplates(templates.map((t) => t.id ?? ""));
                        } else {
                          setCheckedTemplates([]);
                        }
                      }}
                    />
                  </Col>
                  <Col>
                    <Text className="template-item-min-width">Template</Text>
                  </Col>
                  <Col xs="auto" className="template-item-min-width">
                    <Text>Last modified</Text>
                  </Col>
                  <Col
                    xs="auto"
                    className="template-item-min-width d-none d-md-block"
                  >
                    <Text>Scheduled</Text>
                  </Col>
                  <Col xs="auto" className="template-item-min-width">
                    <Text>Access</Text>
                  </Col>
                  <Col xs="auto" className="template-item-min-width"></Col>
                  <Col xs="auto">
                    <span className="animated-btn p-2">
                      <MdSettings size={20} />
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
              {filteredTemplates().map((template, i) => (
                <ListGroup.Item
                  key={i}
                  className="elevated-item pointer"
                  onClick={() => {
                    setTempIdToFetchDetails(template.id);
                    // setShowOffCanvas(true);
                  }}
                >
                  <Row className="align-items-center flex-nowrap " key={i}>
                    <Col xs="auto">
                      <FormCheck
                        checked={checkedTemplates.includes(template?.id ?? "")}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCheckedTemplates([
                              ...checkedTemplates,
                              template?.id ?? "",
                            ]);
                          } else {
                            setCheckedTemplates(
                              checkedTemplates.filter((t) => t !== template.id)
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <Stack
                        direction="horizontal"
                        className={`template-item-min-width align-items-${
                          i % 2 === 0 ? "start" : "center"
                        }`}
                      >
                        <div>
                          {template.imagePath && (
                            <Image
                              src={template.imagePath}
                              style={{
                                width: "32px",
                                height: "32px",
                              }}
                              roundedCircle
                              className="me-2"
                            />
                          )}
                        </div>
                        <Stack
                          direction="vertical"
                          className="justify-content-center"
                        >
                          <Text>{template.title}</Text>
                          {/* {i % 2 === 0 && (
                            <div
                              // fontSize="12px"
                              // variant="light"
                              className="text-truncate flex-wrap"
                              // className="overflow-auto"
                              style={{
                                maxHeight: "100px",
                                // width: "100%",
                              }}
                            >
                              Use this daily assessment to ensure daily
                              compliance to all applicable health orders (Music,
                              Television and Film Production, Restaurant/Bars,
                              Retail Operations, Office Spaces,
                              Warehouse/Manufacturing, Construction, Hair and
                              Personal Services), OSHA Guidelines, and CDC/EPA
                              advisements.
                            </div>
                          )} */}
                        </Stack>
                      </Stack>
                    </Col>
                    <Col xs="auto" className="template-item-min-width">
                      <Text>
                        {moment.unix(Number(template.lastModified)).fromNow()}
                      </Text>
                    </Col>
                    <Col
                      xs="auto"
                      className="template-item-min-width d-none  d-md-block"
                    >
                      <Text variant="light">
                        {template.scheduled ? "Yes" : "No"}
                      </Text>
                    </Col>
                    <Col xs="auto" className="template-item-min-width">
                      <Text>{template.access}</Text>
                    </Col>
                    <Col xs="auto" className="template-item-min-width">
                      <Button
                        variant="light"
                        className="hover-btn"
                        onClick={(e) => {
                          navigate(`/app/inspections/inspect/${template.id}`);
                          e.stopPropagation();
                        }}
                      >
                        Start inspection
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <OverlayTrigger
                        rootClose
                        placement="left-start"
                        trigger="click"
                        overlay={
                          <Popover
                            className="qs-dropdown"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div
                              className="icon-text"
                              onClick={() => {
                                navigate(`/app/template/update/${template.id}`);
                              }}
                            >
                              <MdEdit size={20} />
                              Edit template
                            </div>
                            <div
                              onClick={() => {
                                notifications.showNotification(
                                  {
                                    type: "PROMPT",
                                    manualDismiss: true,
                                    prompts: [
                                      {
                                        label: "Yes",
                                        callback: () => {
                                          template.id &&
                                            Template.DeleteTemplate(
                                              template.id,
                                              (message) => {
                                                notifications.closeNotification();
                                                setTemplates((prev) =>
                                                  prev.filter(
                                                    ({id}) => id !== template.id
                                                  )
                                                );
                                              },
                                              (error) => {
                                                console.log(error);
                                              }
                                            );
                                        },
                                        variant: "success",
                                      },
                                      {
                                        label: "No",
                                        callback: () => {
                                          notifications.closeNotification();
                                        },
                                        variant: "danger",
                                      },
                                    ],
                                  },
                                  "Do you really want to delete this template?"
                                );
                              }}
                            >
                              <MdDelete size={20} color="red" />
                              Delete Template
                            </div>
                            <div
                              className="icon-text"
                              onClick={() => {
                                navigate("/app/schedule/create");
                              }}
                            >
                              <MdEditCalendar size={20} /> Schedule
                            </div>

                            <div>
                              <IoMdEye size={20} />
                              Manage Access
                            </div>
                            <div>
                              <TbClipboardText size={20} />
                              View inspections
                            </div>
                            <hr />
                            <div>
                              <HiDuplicate size={20} />
                              Duplicate template
                            </div>
                            <div>
                              <IoMdArchive size={20} />
                              Archive template
                            </div>
                            <div>
                              <MdOutlineBookmark size={20} />
                              Bookmark
                            </div>
                            <hr />
                            <div>Upload to Public Library</div>
                            <div>Get QR code</div>
                          </Popover>
                        }
                      >
                        <span
                          className="animated-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <BsThreeDotsVertical size={20} />
                        </span>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
      <Offcanvas show={showOffCanvas} onHide={hideOffCanvas} placement="end">
        <Offcanvas.Header>
          <span
            className="animated-btn"
            onClick={() => {
              hideOffCanvas();
            }}
          >
            <MdClose size={20} />
          </span>
          <Offcanvas.Title>Inspection Template</Offcanvas.Title>
          <OverlayTrigger
            rootClose
            placement="left"
            trigger="click"
            overlay={
              <Popover className="qs-dropdown">
                <div>
                  <IoIosCopy size={20} className="me-2" />
                  Duplicate
                </div>
                <div>
                  <MdArchive size={20} className="me-2" />
                  Archive
                </div>
                <div>
                  <IoMdBookmarks size={20} className="me-2" />
                  Bookmark
                </div>
                <hr />
                <div>
                  <MdQrCode size={20} className="me-2" />
                  Get QR Code
                </div>
              </Popover>
            }
          >
            <span className="animated-btn">
              <BsThreeDotsVertical size={20} />
            </span>
          </OverlayTrigger>
        </Offcanvas.Header>
        <div className="bg-white px-4 py-2">
          <Text mode="Heading">{templateDetails?.title}</Text>
          <Stack direction="horizontal">
            <Button
              className="flex-grow-1 m-1"
              size="lg"
              onClick={() => {
                navigate(`/app/inspections/inspect/${tempIdToFetchDetails}`);
              }}
            >
              Start inspection
            </Button>
            <Button
              className="m-1 bg-white text-primary"
              size="lg"
              onClick={() => {
                setShowPreviewOffcanvas(true);
              }}
            >
              <MdRemoveRedEye size={20} /> Preview
            </Button>
          </Stack>
        </div>
        <Offcanvas.Body
          style={{
            backgroundColor: "var(--bs-gray-200)",
          }}
        >
          <div className="bg-white border rounded py-2 px-3">
            <Text mode="SubHeading">Edit</Text>
            <Stack direction="horizontal" className="mt-3">
              <Stack>
                <Text fontSize="16px" fontWeight="500">
                  Template
                </Text>
                <Text fontSize="16px" variant="light">
                  {`${templateDetails?.pages} pages and ${templateDetails?.questions} questions`}
                </Text>
              </Stack>
              <div
                className="text-primary fs-6"
                style={{
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/app/template/update/${tempIdToFetchDetails}`);
                }}
              >
                Edit template
              </div>
            </Stack>

            <Stack direction="horizontal" className="mt-3">
              <Stack>
                <Text fontSize="16px" fontWeight="500">
                  Report
                </Text>
                <Text fontSize="16px" variant="light">
                  Design how the inspection report will look
                </Text>
              </Stack>
              <div
                className="text-primary fs-6"
                style={{
                  fontWeight: "500",
                }}
              >
                Edit report
              </div>
            </Stack>

            <Stack direction="horizontal" className="mt-3">
              <Stack>
                <Text fontSize="16px" fontWeight="500">
                  Access
                </Text>
                <Text fontSize="16px" variant="light">
                  {templateDetails?.access}
                </Text>
              </Stack>
              <div
                className="text-primary fs-6"
                style={{
                  fontWeight: "500",
                }}
              >
                Manage access
              </div>
            </Stack>

            {/* <Stack direction="horizontal" className="mt-3">
              <Stack>
                <Text fontSize="16px" fontWeight="500">
                  Schedule
                </Text>
                <Text fontSize="16px" variant="light">
                  Not Scheduled
                </Text>
              </Stack>
              <div
                className="text-primary fs-6"
                style={{
                  fontWeight: "500",
                }}
              >
                Schedule inspection
              </div>
            </Stack> */}
          </div>

          <div className="bg-white border rounded py-2 px-3 mt-3">
            <Text mode="SubHeading">Details</Text>

            <Stack className="mt-3">
              <Text fontSize="16px" fontWeight="500">
                Description
              </Text>
              <Text fontSize="16px" variant="light">
                {templateDetails?.description ?? "No description"}
              </Text>
            </Stack>

            {/* <Stack direction="horizontal" className="mt-3">
              <Stack>
                <Text fontSize="16px" fontWeight="500">
                  Template usage
                </Text>
                <Text fontSize="16px" variant="light">
                  { moment().fromNow()}
                </Text>
              </Stack>
              <div
                className="text-primary fs-6"
                style={{
                  fontWeight: "500",
                }}
              >
                View inspections
              </div>
            </Stack> */}

            <Stack className="mt-3">
              <Text fontSize="16px" fontWeight="500">
                Last modified by
              </Text>
              <Text fontSize="16px" variant="light">
                {`${templateDetails?.lastModifiedBy} - ${templateDetails?.lastModifiedOn}`}
              </Text>
            </Stack>
            <Stack className="mt-3">
              <Text fontSize="16px" fontWeight="500">
                Created by
              </Text>
              <Text fontSize="16px" variant="light">
                {`${templateDetails?.createdBy} - ${templateDetails?.createdOn}`}
              </Text>
            </Stack>

            <Stack className="mt-3">
              <Text fontSize="16px" fontWeight="500">
                Organization
              </Text>
              <Text fontSize="16px" variant="light">
                {templateDetails?.organization}
              </Text>
            </Stack>

            <Stack className="mt-3">
              <Text fontSize="16px" fontWeight="500">
                Template owner
              </Text>
              <Text fontSize="16px" variant="light">
                {templateDetails?.owner}
              </Text>
            </Stack>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showPreviewOffcanvas}
        placement="end"
        onHide={() => setShowPreviewOffcanvas(false)}
      >
        <Offcanvas.Header>
          <span
            className="animated-btn"
            onClick={() => {
              setShowPreviewOffcanvas(false);
            }}
          >
            <MdClose size={20} />
          </span>
          <Offcanvas.Title>Template Preview</Offcanvas.Title>
          <span></span>
        </Offcanvas.Header>
        <iframe src="/app/template/preview" />
      </Offcanvas>
    </Container>
  );
}
