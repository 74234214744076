import { ActionPriorityType } from "./actions";

export type IssueType = {
  id: string;
  name: string;
  description: string;
  createdBy: {
    name: string;
    id: string;
  };
  createdAt: string;
  updatedAt: string;
  status: string;

  assignee: {
    name: string;
    id: string;
  };

  location?: {
    id: number;
    name: number;
  };
  category: {
    id: number;
    name: number;
  };
  priority?: keyof typeof ActionPriorityType;
  dueDate?: number;
  history?: Array<{
    comment: string;
    createdBy: {
      name: string;
      id: number;
    };
    createdAt: number;
  }>;
};
export type IssueListType = {
  id: number;
  reportType: {
    id: number;
    text: string;
  };
  reportedBy: {
    id: number;
    name: string;
  };
  lastUpdated: number;
  status: "OPEN" | "RESOLVED";
  name: string;
  assignee: {
    id: number;
    name: string;
  };
};
export function IssueStatus(status: IssueListType["status"]): {
  color: string;
  text: string;
} {
  return {
    color: status === "OPEN" ? "success" : "danger",
    text: status === "OPEN" ? "Open" : "Closed",
  };
}
