import { GoIssueOpened } from "react-icons/go";
import {
  HiOutlineCalendar,
  HiOutlineCheckCircle,
  HiOutlineClipboardCheck,
  HiOutlineSpeakerphone,
} from "react-icons/hi";
import { MdOutlineAdd, MdOutlineSearch } from "react-icons/md";

export const IssueIcon = GoIssueOpened;
export const InspectionIcon = HiOutlineClipboardCheck;
export const ActionsIcon = HiOutlineCheckCircle;
export const HeadsUpIcon = HiOutlineSpeakerphone;
export const SearchIcon = MdOutlineSearch;
export const AddIcon = MdOutlineAdd;
export const ScheduleIcon = HiOutlineCalendar;
