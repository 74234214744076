import React, { useState } from "react";
import DatePicker from "react-datepicker";

export default function DateTime(
  props: React.ComponentProps<typeof DatePicker>
) {
  return (
    <DatePicker
      showTimeSelect
      dateFormat="dd/MM/yyyy h:mm aa"
      {...props}
      className="qs-calendar"
      wrapperClassName="qs-calendar-wrapper"
    />
  );
}
