import { StylesConfig } from "react-select";

const SelectTheme: StylesConfig = {
  control: (base, state) => ({
    ...base,
    // minHeight: "34px",
    // maxHeight: "34px",
    backgroundColor: "white",
    boxShadow: "none",
    borderColor: state.isFocused ? "var(--bs-primary)" : "var(--bs-gray-300)",
    "&:hover": {
      borderColor: "var(--bs-primary)",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "var(--bs-primary-light)" : "white",
    color: "var(--bs-gray-700)",
    "&:active": {
      backgroundColor: "var(--bs-primary-light)",
    },
  }),
  multiValue: (base, state) => ({
    ...base,
    backgroundColor: "var(--bs-primary-light)",
    color: "var(--bs-gray-700)",
  }),
};
export default SelectTheme;
