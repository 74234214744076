import {
  Button,
  Col,
  Container,
  FormCheck,
  Image,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdContentCopy, MdDelete, MdOutlineArrowBack } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "../../components/text/text";
import "./issue-categories.scss";
import SAMPLEQR from "./../../assets/sample-qr.png";
import TextInput from "../../components/input/input";
import { HiExternalLink } from "react-icons/hi";

export default function IssueCategories({}) {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <Container className="py-5">
      <Row>
        <Col>
          <div
            className="category_back-btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <MdOutlineArrowBack size={20} className="me-2" /> Back
          </div>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs="12" md="6">
          <Text mode="Heading">Manage issue categories</Text>
        </Col>
        <Col xs="12" md="6" className="d-md-flex justify-content-md-end">
          <Button
            onClick={() => {
              navigate("/app/issues/categories/edit");
            }}
          >
            Add/edit categories
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup className="d-none d-md-block">
            <ListGroup.Item style={{ backgroundColor: "var(--bs-gray-100)" }}>
              <Row className="align-items-center">
                <Col>
                  <Text>Category Name</Text>
                </Col>
                <Col>
                  <Text>Category access</Text>
                </Col>
                <Col>
                  <Text>Notify</Text>
                </Col>
                <Col xs="2"></Col>
                <Col xs="1"></Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row className="align-items-center">
                <Col>
                  <Text>Category Name</Text>
                </Col>
                <Col>
                  <Text>Category access</Text>
                </Col>
                <Col>
                  <Text>Notify</Text>
                </Col>
                <Col xs="2" className="justify-content-end d-flex">
                  <div
                    className="hover-btn"
                    style={{ minWidth: 110 }}
                    onClick={() => {
                      navigate("/app/issues/categories/edit/:id", {
                        state: {
                          backgroundLocation: location,
                        },
                      });
                    }}
                  >
                    Edit category
                  </div>
                </Col>
                <Col xs="1">
                  <div className="animated-btn d-inline-block">
                    <MdDelete size={20} />
                  </div>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>

          {/* Small Device Design */}
          <ListGroup className=" d-sm-block d-md-none">
            <ListGroup.Item>
              <Stack direction="horizontal">
                <span className="animated-btn">
                  <BsThreeDotsVertical size={20} />
                </span>
                <Stack>
                  <Text fontWeight="500">Category Name</Text>
                  <Text fontSize="15px">Category access: All users</Text>
                  <Text fontSize="15px">Notify: 1 group, 1 user</Text>
                </Stack>
              </Stack>
            </ListGroup.Item>

            <ListGroup.Item>
              <Stack direction="horizontal">
                <span className="animated-btn">
                  <BsThreeDotsVertical size={20} />
                </span>
                <Stack>
                  <Text fontWeight="500">Category Name</Text>
                  <Text fontSize="15px">Category access: All users</Text>
                  <Text fontSize="15px">Notify: 1 group, 1 user</Text>
                </Stack>
              </Stack>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Stack className="mt-3">
        <Text mode="Heading">Issue QR code</Text>
        <Text>
          Do you have team members who report issues but don't have QSComply
          Auditor accounts? No problem! Download and share your issue QR code so
          they can scan and report issues into QSComply Auditor for free.
        </Text>
      </Stack>
      <Row>
        <Col xs="12" md="6">
          <div className="border rounded p-3">
            <Stack direction="horizontal">
              <Image
                src={SAMPLEQR}
                style={{
                  width: 100,
                  height: 100,
                }}
              />
              <Stack direction="vertical" className="">
                <TextInput
                  disabled
                  value="https://www.google.com"
                  style={{ backgroundColor: "var(--bs-gray-100)" }}
                  endIcon={<MdContentCopy size={20} />}
                />
                <Button className="d-inline-block mt-2">
                  Download QR code
                </Button>
              </Stack>
            </Stack>
          </div>
        </Col>
        <Col xs="12" md="6" className="my-md-0 my-2">
          <div className="border rounded p-3">
            <FormCheck
              type="switch"
              style={{ transform: "scale(1.5)", display: "inline-block" }}
              className="mx-2"
            />
            <Text mode="SubHeading">Enable site selection via QR code</Text>
            <Text variant="light">
              This allows people to select sites when they report issues via QR
              code, but also means anyone who scans the QR code can see all
              sites in your QSComply Auditor team.
            </Text>
            <Text variant="primary" mode="SubHeading" className="mt-3 pointer">
              <HiExternalLink size={20} className="me-2" /> Manage sites
            </Text>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
