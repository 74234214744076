import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { SearchIcon } from "../../icons/icons";
import TextInput from "../../input/input";
import Text from "../../text/text";
import { FilterProps, OpsType } from "../type";

export default function AccessPopup({ onChange, defaultValue }: FilterProps) {
  const [filterOps, setFilterOps] = useState<OpsType>(
    defaultValue ?? {
      OBM: {
        label: "Owned by me",
        isChecked: false,
      },
      SWM: {
        label: "Shared with me",
        isChecked: false,
      },
    }
  );
  const [opSearchText, setOpSearchText] = useState<string>("");

  const filteredOps = useCallback(() => {
    const filteredOps = { ...filterOps };
    Object.keys(filteredOps).forEach((key) => {
      if (filteredOps[key].label.toLowerCase().indexOf(opSearchText) === -1) {
        delete filteredOps[key];
      }
    });
    return filteredOps;
  }, [filterOps, opSearchText]);

  useEffect(() => {
    if (filterOps) {
      onChange(filterOps);
    }
  }, [filterOps]);

  return (
    <div className="p-1">
      <TextInput
        placeholder="Search"
        startIcon={<SearchIcon size={20} />}
        wrapperClassName="mb-3"
        onChange={(e) => setOpSearchText(e.target.value)}
      />

      {Object.entries(filteredOps()).some(
        ([key, value]) => value.isChecked
      ) && (
        <Fragment>
          <Text className="text-muted" fontSize="10px">
            SELECTED FILTERS
          </Text>
          {Object.entries(filterOps)
            .filter((op) => op[1].isChecked)
            .map((op, index) => {
              return (
                <Form.Check
                  key={index}
                  label={op[1].label}
                  style={{ fontSize: 14 }}
                  className="m-1 my-2"
                  checked={op[1].isChecked}
                  onChange={(e: any) => {
                    setFilterOps({
                      ...filterOps,
                      [op[0]]: {
                        ...op[1],
                        isChecked: e.target.checked,
                      },
                    });
                  }}
                />
              );
            })}
        </Fragment>
      )}
      <Text className="text-muted" fontSize="10px">
        ALL FILTERS
      </Text>
      {Object.entries(filteredOps()).map((op, index) => (
        <Form.Check
          key={index}
          label={op[1].label}
          style={{ fontSize: 14 }}
          className="m-1 my-2"
          checked={op[1].isChecked}
          onChange={(e: any) => {
            setFilterOps({
              ...filterOps,
              [op[0]]: {
                ...op[1],
                isChecked: e.target.checked,
              },
            });
          }}
        />
      ))}
    </div>
  );
}
