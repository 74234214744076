import { Badge, Button, Container, ListGroup, Stack } from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md";
import { AddIcon } from "../../../components/icons/icons";
import Text from "../../../components/text/text";
import QuestionItem from "../components/question-item";

export default function CategoryWorkflow({}) {
  return (
    <Container className="mt-5">
      <div className="border rounded p-3">
        <Stack direction="horizontal" className="justify-content-between">
          <Text fontSize="20px" fontWeight="500">
            Category Name
          </Text>
          <Button variant="light" className="border hover-btn">
            Edit Name
          </Button>
        </Stack>
        <Text>Observation</Text>
      </div>
      <div className="border rounded p-3 mt-5">
        <Text fontSize="20px" fontWeight="500">
          Notifications
        </Text>
        <Text variant="light">
          Automatically notify the following people when reporting:
          "Observation"
        </Text>
        <hr />
        <Text>Send a notification to</Text>
        <div
          style={{ backgroundColor: "var(--bs-primary-light)" }}
          className=" px-2 rounded d-inline-flex align-items-center me-2"
        >
          <MdAccountCircle className="me-2" /> Saoud Nazir
        </div>
        <hr />
        <Button className="fw-bold hover-btn border" variant="light">
          Edit Notifications
        </Button>
      </div>
      {/* <div className="border rounded p-3 mt-5"></div> */}
      <ListGroup className="mt-5">
        <ListGroup.Item>
          <Text fontSize="20px" fontWeight="500">
            Custom questions
          </Text>
          <Text variant="light">
            Automatically ask these questions when a "Observation" issue is
            reported.
          </Text>
        </ListGroup.Item>
        <ListGroup.Item>
          <QuestionItem question="What needs to be done?" />
        </ListGroup.Item>
        <ListGroup.Item>
          <QuestionItem question="What caused it?" />
        </ListGroup.Item>
        <ListGroup.Item>
          <Button
            className="fw-bold hover-btn border d-flex align-items-center"
            variant="light"
          >
            <AddIcon size={18} className="me-2" /> Add Question
          </Button>
        </ListGroup.Item>
      </ListGroup>
    </Container>
  );
}
