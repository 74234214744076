import axios from "axios";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
// import { IUser } from "../interface";
interface IUser {
  title: string;
  token: string;
  roles: Array<string>;
  email: string;
  username: string;
  id: string;
  refreshToken: string;
  type: "Bearer";
  profileUrl: string;
  businessId: number;
}

const UserContext = React.createContext<
  IUser & {setUser?: React.Dispatch<React.SetStateAction<IUser>>}
>({
  email: "",
  setUser: () => {},
  id: "",
  roles: [],
  token: "",
  title: "",
  username: "",
  refreshToken: "",
  type: "Bearer",
  profileUrl: "",
  businessId: 0,
});

export function UserProfileProvider({children}: any) {
  const [user, setUser] = useState<IUser>({
    email: "",
    id: "",
    roles: [],
    token: "",
    title: "",
    username: "",
    refreshToken: "",
    type: "Bearer",
    businessId: 0,
    profileUrl: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    const businessId = searchParams.get("business");
    const user = localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    } else {
      if (token) {
        const baseURL = process.env.REACT_APP_API_URL;
        axios
          .get(`${baseURL}/comply-tsfm/api/auth/validateSessionToken`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(({data}) => {
            localStorage.setItem("user", JSON.stringify({...data, businessId}));
            localStorage.setItem("token", token);
            setUser(data);
            // navigate("/app/dashboard");
          })
          .catch((error) => {
            // console.log(error);
            alert("Failed to Authenticate");
            // window.location.replace(process.env.REACT_APP_TIMESHEET_URL!);
          });
      } else {
        window.location.replace(
          `${process.env.REACT_APP_TIMESHEET_URL}/?returnURL=${window.location.protocol}//${window.location.host}`
        );
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{...user, setUser}}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserProfile = () =>
  React.useContext<
    IUser & {setUser?: React.Dispatch<React.SetStateAction<IUser>>}
  >(UserContext);
