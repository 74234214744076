import { Nav, Tab } from "react-bootstrap";
import ResponsesTab from "./tabs/responses";
import TemplateTab from "./tabs/templates";

export default function Template({}) {
  return (
    <Tab.Container defaultActiveKey="templates">
      <Nav className="border-bottom">
        <Nav.Item>
          <Nav.Link eventKey="templates" className="qs-tab-item rounded-0">
            Templates
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="responses" className="qs-tab-item rounded-0">
            Responses
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link eventKey="public-library" className="qs-tab-item rounded-0">
            Public Library
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="archive" className="qs-tab-item rounded-0">
            Archive
          </Nav.Link>
        </Nav.Item> */}
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="templates">
          <TemplateTab />
        </Tab.Pane>
        <Tab.Pane eventKey="responses">
          <ResponsesTab />
        </Tab.Pane>
        {/* <Tab.Pane eventKey="public-library">
          <Button>Create Template</Button>
        </Tab.Pane>
        <Tab.Pane eventKey="archive">
          <Button>Create Template</Button>
        </Tab.Pane> */}
      </Tab.Content>
    </Tab.Container>
  );
}
