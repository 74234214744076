import {Fragment, useEffect, useState} from "react";
import {
  Button,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Stack,
} from "react-bootstrap";
import {
  Controller,
  useFieldArray,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import {IconType} from "react-icons";
import {BiSliderAlt} from "react-icons/bi";
import {FaFileSignature} from "react-icons/fa";
import {HiAnnotation} from "react-icons/hi";
import {ImCheckboxChecked} from "react-icons/im";
import {
  MdDateRange,
  MdDelete,
  MdLocationPin,
  MdOutlineDownloadDone,
  MdOutlineFormatColorText,
  MdPermMedia,
} from "react-icons/md";
import {RiArrowDownSLine, RiCheckboxMultipleFill} from "react-icons/ri";
import {TbNumbers} from "react-icons/tb";
import {useMediaQuery} from "react-responsive";
import {HexToRGBA} from "../../../api/general";
import Template, {QuestionType} from "../../../api/template";
import {TextInputWithRef} from "../../../components/input/input";
import Text from "../../../components/text/text";
import "./styles.scss";

export type ResponseType = {
  type: QuestionType["type"];
  label: string;
  Icon: IconType;
};
interface ResponseTypesProps {
  onChange: (value: ResponseType) => void;
  defaultOption: ResponseType;
  templateForm: UseFormReturn<Template.ITemplate>;
  pageIndex: number;
  questionIndex: number;
}

export const ResponseTypesArr: Array<ResponseType> = [
  {
    type: "SHORT_ANSWER",
    label: "Short Answer",
    Icon: MdOutlineFormatColorText,
  },
  {
    type: "NUMBER",
    label: "Number",
    Icon: TbNumbers,
  },
  {
    type: "CHECKBOX",
    label: "Checkbox",
    Icon: ImCheckboxChecked,
  },
  {
    Icon: MdDateRange,
    type: "DATETIME",
    label: "Date & Time",
  },
  {
    Icon: MdPermMedia,
    type: "MEDIA",
    label: "Media",
  },
  {
    Icon: BiSliderAlt,
    type: "SLIDER",
    label: "Slider",
  },
  {
    Icon: HiAnnotation,
    label: "Annotation",
    type: "ANNOTATION",
  },
  {
    Icon: FaFileSignature,
    type: "SIGNATURE",
    label: "Signature",
  },
  {
    Icon: MdLocationPin,
    type: "LOCATION",
    label: "Location",
  },
  {
    Icon: RiCheckboxMultipleFill,
    type: "MULTICHOICE_CHECKBOX",
    label: "Options (Multi-Selection)",
  },
  {
    Icon: MdOutlineDownloadDone,
    type: "MULTICHOICE_RADIO",
    label: "Options (Single-Selection)",
  },
  // {
  //   Icon: FaCommentDots,
  //   type: "INSTRUCTIONS",
  //   label: "Instructions",
  // },
];
export default function ResponseTypes({
  onChange,
  defaultOption,
  pageIndex,
  questionIndex,
  templateForm,
}: ResponseTypesProps) {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 576px)",
  });

  const [selectedtype, setSelectedtype] = useState<ResponseType>(defaultOption);
  useEffect(() => {
    onChange(selectedtype);
  }, [selectedtype]);

  useEffect(() => {
    if (defaultOption) {
      setSelectedtype(defaultOption);
    }
  }, [defaultOption]);

  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const [openCustomResponseOffcanvas, setOpenCustomResponseOffcanvas] =
    useState<boolean>(false);
  const iconProps = {
    className: "me-2",
  };
  const [openMultiOptionCanvas, setOpenMultiOptionCanvas] =
    useState<boolean>(false);

  const multipleOptions = {
    EGSPN: [
      {
        title: "Excellent",
        color: "#3dc08b",
        dummyId: Math.random().toString(),
      },
      {
        title: "Good",
        color: "#3d86c6",
        dummyId: Math.random().toString(),
      },
      {
        title: "Satisfactory",
        color: "#ff9932",
        dummyId: Math.random().toString(),
      },
      {
        title: "Poor",
        color: "#d95b5d",
        dummyId: Math.random().toString(),
      },
      {
        title: "NA",
        color: "#707070",
        dummyId: Math.random().toString(),
      },
    ],
    YNN: [
      {title: "Yes", color: "#3dc08b", dummyId: Math.random().toString()},
      {title: "No", color: "#d95b5d", dummyId: Math.random().toString()},
      {
        title: "NA",
        color: "#707070",
        dummyId: Math.random().toString(),
      },
    ],
    SAN: [
      {title: "Safe", color: "#3dc08b", dummyId: Math.random().toString()},
      {title: "At Risk", color: "#d95b5d", dummyId: Math.random().toString()},
      {
        title: "NA",
        color: "#707070",
        dummyId: Math.random().toString(),
      },
    ],
    PFN: [
      {title: "Pass", color: "#3dc08b", dummyId: Math.random().toString()},
      {title: "Fail", color: "#d95b5d", dummyId: Math.random().toString()},
      {
        title: "NA",
        color: "#707070",
        dummyId: Math.random().toString(),
      },
    ],
    CNN: [
      {
        title: "Compliant",
        color: "#3dc08b",
        dummyId: Math.random().toString(),
      },
      {
        title: "Non-Compliant",
        color: "#d95b5d",
        dummyId: Math.random().toString(),
      },
      {
        title: "NA",
        color: "#707070",
        dummyId: Math.random().toString(),
      },
    ],
  };

  function setOptionsToQuestion(
    options: Array<{title: string; color?: string}>
  ) {
    templateForm.setValue(
      `pages.${pageIndex}.questions.${questionIndex}.options`,
      options
    );

    responseFieldArr.fields.length > 0 &&
      customResponsesForm.reset({responses: []});

    setOpenMultiOptionCanvas(false);
    setOpenCustomResponseOffcanvas(false);
  }

  const customResponsesForm = useForm<{
    responses: Array<{title: string}>;
  }>({
    defaultValues: {
      responses:
        templateForm.getValues(
          `pages.${pageIndex}.questions.${questionIndex}.options`
        ) ?? [],
    },
  });

  const responseFieldArr = useFieldArray({
    control: customResponsesForm.control,
    name: "responses",
  });

  if (isSmallDevice) {
    return (
      <div>
        <Stack
          direction="horizontal"
          className="h-100 justify-content-between pointer "
          onClick={() => {
            setOpenOffcanvas(!openOffcanvas);
          }}
        >
          <Text variant="light">{selectedtype.label}</Text>
          <RiArrowDownSLine size={20} />
        </Stack>
        <Offcanvas
          show={openOffcanvas}
          onHide={() => {
            setOpenOffcanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Type of Response</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              className="no-hover"
              style={{color: "gray", fontSize: "0.8rem"}}
            >
              Other Responses
            </div>
            {ResponseTypesArr.map(({Icon, label, type}, i) => (
              <div className="response-type-option" key={i}>
                <Icon {...iconProps} /> {label}
              </div>
            ))}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  } else {
    return (
      <Fragment>
        <OverlayTrigger
          placement="auto-start"
          trigger="click"
          rootClose
          overlay={
            <Popover
              style={{
                minWidth: "500px",
              }}
              className="qs-dropdown"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <div
                className="no-hover"
                style={{color: "gray", fontSize: "0.8rem"}}
              >
                Other Responses
              </div>
              {ResponseTypesArr.map(({Icon, label, type}, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setSelectedtype({type, label, Icon});
                    if (
                      type === "MULTICHOICE_CHECKBOX" ||
                      type === "MULTICHOICE_RADIO"
                    ) {
                      setOpenMultiOptionCanvas(true);
                    }
                    document.body.click();
                  }}
                >
                  <Icon {...iconProps} /> {label}
                </div>
              ))}
            </Popover>
          }
        >
          <Stack
            direction="horizontal"
            className="h-100 justify-content-between pointer w-100"
          >
            <Stack direction="horizontal">
              <selectedtype.Icon size={20} className="me-2" />
              <Text variant="light">{selectedtype.label}</Text>
            </Stack>
            <span className="animated-btn">
              <RiArrowDownSLine size={20} />
            </span>
          </Stack>
        </OverlayTrigger>

        {/* Multipe Answer Offcanvas */}

        <Offcanvas
          show={openMultiOptionCanvas}
          onHide={() => {
            setOpenMultiOptionCanvas(false);
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Text fontWeight="bold" fontSize="1rem">
              Multiple choice responses
            </Text>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Text>Response</Text>

            <MultiOptionContainer
              options={multipleOptions.EGSPN}
              onClick={() => {
                setOptionsToQuestion(multipleOptions.EGSPN);
              }}
            />
            <MultiOptionContainer
              options={multipleOptions.YNN}
              onClick={() => {
                setOptionsToQuestion(multipleOptions.YNN);
              }}
            />
            <MultiOptionContainer
              options={multipleOptions.SAN}
              onClick={() => {
                setOptionsToQuestion(multipleOptions.SAN);
              }}
            />
            <MultiOptionContainer
              options={multipleOptions.PFN}
              onClick={() => {
                setOptionsToQuestion(multipleOptions.PFN);
              }}
            />
            <MultiOptionContainer
              options={multipleOptions.CNN}
              onClick={() => {
                setOptionsToQuestion(multipleOptions.CNN);
              }}
            />

            <Stack className="my-4">
              <Button onClick={() => setOpenCustomResponseOffcanvas(true)}>
                Create Custom Response Set
              </Button>
            </Stack>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={openCustomResponseOffcanvas}
          onHide={() => {
            setOpenCustomResponseOffcanvas(false);
            // setCustomResponses([]);
            customResponsesForm.reset({responses: []});
          }}
          placement="end"
        >
          <div style={{backgroundColor: "var(--bs-gray-300)", flex: 1}}>
            <Offcanvas.Header closeButton>
              <Text fontWeight="bold" fontSize="1rem">
                Create Response Set
              </Text>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="vertical">
                {responseFieldArr.fields.map(({title, id}, index) => (
                  <div
                    className="custom-response-input"
                    key={`custom-response-${id}`}
                  >
                    <Controller
                      control={customResponsesForm.control}
                      name={`responses.${index}.title`}
                      render={({field}) => {
                        return (
                          <TextInputWithRef
                            titleInput
                            onChange={field.onChange}
                            value={field.value}
                          />
                        );
                      }}
                    />

                    <div className="justify-content-end d-flex">
                      <div
                        className="animated-btn d-inline-block"
                        onClick={() => {
                          responseFieldArr.remove(index);
                        }}
                      >
                        <MdDelete size={20} />
                      </div>
                    </div>
                  </div>
                ))}

                <div
                  style={{fontWeight: "bold", cursor: "pointer"}}
                  className="my-2"
                  onClick={() => {
                    responseFieldArr.insert(responseFieldArr.fields.length, {
                      title: "Click to edit",
                    });
                  }}
                >
                  + Add Response
                </div>
              </Stack>
              <Stack direction="horizontal">
                <Button
                  style={{minWidth: "200px"}}
                  onClick={() => {
                    setOptionsToQuestion(
                      customResponsesForm.getValues("responses")
                    );
                  }}
                >
                  Save and close
                </Button>
                <Button
                  variant="light"
                  className="ms-2"
                  onClick={() => {
                    setOpenCustomResponseOffcanvas(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Offcanvas.Body>
          </div>
        </Offcanvas>
      </Fragment>
    );
  }
}

const MultiOptionContainer = ({
  options,
  onClick,
}: {
  options: Array<any>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => (
  <div className="py-2 border-bottom hover-btn d-flex" onClick={onClick}>
    {options.map((op, index) => (
      <MultipleOptionItem {...op} key={`option-${index}`} />
    ))}
  </div>
);
const MultipleOptionItem = ({color, title}: {title: string; color: string}) => (
  <div
    style={{
      color,
      backgroundColor: HexToRGBA(color, 0.2),
    }}
    className="multi-option-container py-1 px-2"
  >
    {title}
  </div>
);
