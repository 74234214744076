import { Fragment, useCallback, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { SearchIcon } from "../../icons/icons";
import TextInput from "../../input/input";
import Text from "../../text/text";
import { FilterProps, RemoteOpType } from "../type";

export default function GroupsPopup({ onChange, defaultValue }: FilterProps) {
  const [filterOps, setFilterOps] = useState<RemoteOpType>(
    defaultValue ?? {
      1: { name: "Group 1", isChecked: true },
      2: { name: "Group 2", isChecked: false },
    }
  );
  const [opSearchText, setOpSearchText] = useState<string>("");

  const filteredOps = useCallback(() => {
    const filteredOps: RemoteOpType = { ...filterOps };
    Object.keys(filteredOps).forEach((key) => {
      if (
        filteredOps[Number(key)].name.toLowerCase().indexOf(opSearchText) === -1
      ) {
        delete filteredOps[Number(key)];
      }
    });
    return filteredOps;
  }, [filterOps, opSearchText]);

  useEffect(() => {
    if (filterOps) {
      onChange(filterOps);
    }
  }, [filterOps]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center p-1">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className="p-1">
      <TextInput
        placeholder="Search"
        startIcon={<SearchIcon size={20} />}
        wrapperClassName="mb-3"
        onChange={(e) => setOpSearchText(e.target.value)}
      />

      {Object.entries(filteredOps()).some(
        ([key, value]) => value?.isChecked
      ) && (
        <Fragment>
          <Text className="text-muted" fontSize="10px">
            SELECTED GROUPS
          </Text>
          {Object.entries(filterOps)
            .filter((op) => op[1].isChecked)
            .map((op, index) => {
              return (
                <Form.Check
                  key={index}
                  label={op[1].name}
                  style={{ fontSize: 14 }}
                  className="m-1 my-2"
                  checked={op[1].isChecked}
                  onChange={(e: any) => {
                    setFilterOps({
                      ...filterOps,
                      [op[0]]: {
                        ...op[1],
                        isChecked: e.target.checked,
                      },
                    });
                  }}
                />
              );
            })}
        </Fragment>
      )}
      <Text className="text-muted" fontSize="10px">
        ALL GROUPS
      </Text>
      {Object.entries(filteredOps()).map((op, index) => (
        <Form.Check
          key={index}
          label={op[1].name}
          style={{ fontSize: 14 }}
          className="m-1 my-2"
          checked={op[1].isChecked}
          onChange={(e: any) => {
            setFilterOps({
              ...filterOps,
              [op[0]]: {
                ...op[1],
                isChecked: e.target.checked,
              },
            });
          }}
        />
      ))}
    </div>
  );
}
