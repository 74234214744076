import { Button, Col, Container, Row } from "react-bootstrap";
import Text from "../../../components/text/text";
import Select from "react-select";
import SelectTheme from "../../../scss/select.theme";

export default function Notifications({}) {
  return (
    <Container fluid="sm" style={{ maxWidth: "600px" }}>
      <Row className="mt-5">
        <Col>
          <Text mode="Heading">
            Who should be notified when an issue is reported?
          </Text>
        </Col>
      </Row>
      <Row className="d-md-flex d-none">
        <Col xs="12" md="3">
          <Text variant="light">Category</Text>
        </Col>
        <Col xs="12" md="9">
          <Text variant="light">
            When a new issue is reported, automatically notify
          </Text>
        </Col>
      </Row>
      {[
        {
          id: 1,
          name: "Obersavtion",
        },
        {
          id: 2,
          name: "Maintenance",
        },
        {
          id: 3,
          name: "Incident",
        },
      ].map((category, i) => (
        <Row key={category.id} className="my-3">
          <Col xs="12" md="3">
            <Text>{category.name}</Text>
          </Col>
          <Col xs="12" md="9">
            <Select styles={SelectTheme} />
          </Col>
        </Row>
      ))}
      <Row className="justify-content-md-end">
        <Col xs="12" md="auto" className="order-sm-2 order-md-1">
          <Button variant="light" className="border w-100">
            Back
          </Button>
        </Col>
        <Col xs="12" md="auto" className="order-sm-1 order-md-2">
          <Button className="w-100 my-md-0  my-2">Continue</Button>
        </Col>
      </Row>
    </Container>
  );
}
