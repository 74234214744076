import moment from "moment";
import { Fragment, useCallback, useState } from "react";
import {
  Button,
  Col,
  Container,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { GoPrimitiveDot } from "react-icons/go";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ISchedule } from "../../../api/schedule";
import FilterButton from "../../../components/filter-button/filter-button";
import {
  AddIcon,
  ScheduleIcon,
  SearchIcon,
} from "../../../components/icons/icons";
import TextInput from "../../../components/input/input";
import Text from "../../../components/text/text";

export default function MySchedules({}) {
  const navigate = useNavigate();
  const [openCanvas, setOpenCanvas] = useState(false);
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm<any>({
    defaultValues: {
      template: undefined,
    },
  });
  const [appliedFilters, setAppliedFilters] = useState<
    Array<{
      label: string;
      key: string;
      open: boolean;
    }>
  >([]);

  const [inspections, setInspections] = useState<Array<ISchedule>>(
    Array.from({ length: 7 }).map((_, i) => ({
      id: i,
      startDate: moment().set({ hour: 3 }).add(i, "days").unix().toString(),
      dueDate: moment().set({ hour: 23 }).add(i, "days").unix().toString(),
      name: `Inspection ${i}`,
      frequency: "Daily",
    }))
  );
  const [searchedText, setSearchedText] = useState("");

  const filteredSchedules = useCallback(() => {
    return inspections.filter((inspection) => {
      return inspection.name.toLowerCase().includes(searchedText.toLowerCase());
    });
  }, [searchedText]);

  return (
    <Container fluid>
      <Row className="mt-2">
        <Col xs="auto" className="ps-0">
          <TextInput
            placeholder="Search"
            startIcon={<SearchIcon size={20} />}
            onChange={(e) => setSearchedText(e.target.value)}
          />
        </Col>
        {appliedFilters.map((af, i) => (
          <Controller
            key={i}
            control={control}
            name={af.key as any}
            render={({ field: { onChange, value, name } }) => (
              <Col xs="auto">
                <FilterButton
                  open={af.open}
                  setOpen={(open) => {
                    setAppliedFilters((prev) => {
                      return [...prev.slice(0, i), { ...prev[i], open }];
                    });
                  }}
                  type={name as any}
                  label={af.label}
                  defaultValue={value}
                  onChange={onChange}
                  onDone={handleSubmit(
                    (data) => {
                      console.log({ data });
                    },
                    (error) => {
                      console.log(error);
                    }
                  )}
                  onClear={() => {
                    reset({ [name]: undefined });
                    setAppliedFilters((prev) => {
                      return [...prev.slice(0, i)];
                    });
                  }}
                />
              </Col>
            )}
          />
        ))}

        <Col xs="auto">
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            rootClose
            overlay={
              <Popover
                className="qs-dropdown-popup p-0 py-2"
                style={{ minWidth: "160px" }}
              >
                {[
                  {
                    label: "Template",
                    key: "template",
                    open: true,
                  },
                ].map((filter, i) => (
                  <Text
                    key={i}
                    className="inspection-filter-option px-3 pointer"
                    onClick={() => {
                      setAppliedFilters([...appliedFilters, filter]);
                      document.body.click();
                    }}
                  >
                    {filter.label}
                  </Text>
                ))}
              </Popover>
            }
          >
            <Button
              variant="link"
              className="text-decoration-none fw-bold d-flex align-items-center"
            >
              <AddIcon size={20} className="me-2" /> Add filter
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
      {filteredSchedules().map(
        ({ name, dueDate, frequency, id, startDate }, i) => {
          const date = moment.unix(Number(startDate));
          const due = moment.unix(Number(dueDate));
          const now = moment();
          const isAvailable = now.isBefore(due) && now.isAfter(date);

          return (
            <div
              key={i}
              onClick={() => {
                setOpenCanvas(true);
              }}
            >
              <Row className="mt-3">
                <Col className="ps-0">
                  <Text mode="SubHeading" fontWeight="600">
                    {date.format("dddd")}
                    <span className="ms-2 text-muted">
                      {date.format("DD MMM YYYY")}
                    </span>
                  </Text>
                </Col>
              </Row>
              <Row className="mt-2 mb-3 border rounded px-2 py-3  align-items-center  my-schedule-item">
                <Col xs="auto">
                  <div className="qs-rounded-icon-container">
                    <ScheduleIcon className="qs-rounded-icon" size={21} />
                  </div>
                </Col>
                <Col>
                  <Text fontWeight="500">
                    {name} - {frequency}
                  </Text>
                  <Text className="text-muted">
                    {isAvailable
                      ? "Available now"
                      : `Starts on ${date.format("DD MMM YYYY hh:mm A")}`}{" "}
                    <GoPrimitiveDot className="mx-2" size={10} /> Due by{" "}
                    {due.format("DD MMM YYYY hh:mm A")}
                  </Text>
                </Col>
              </Row>
            </div>
          );
        }
      )}
      <Row>
        <Col className="justify-content-center d-flex">
          <Text variant="light">
            Scheduled inspections assigned to you starting in the next 7 days
            will show here.
          </Text>
        </Col>
      </Row>

      <Offcanvas
        show={openCanvas}
        placement="end"
        onHide={() => setOpenCanvas(false)}
      >
        <Offcanvas.Header>
          <div
            className="pointer animated-btn"
            onClick={() => {
              setOpenCanvas(false);
            }}
          >
            <MdOutlineClose size={20} />
          </div>
          <Text mode="SubHeading" fontWeight="600">
            Scheduled Inspection
          </Text>
          <div />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="px-4 py-2">
            <Text mode="Heading">
              Sample - COVID-19 Daily Inspection by Mitti
            </Text>
            <Text variant="light">
              This is a sample inspection for COVID-19.
            </Text>

            <Text variant="light" className="mt-3">
              Schedule Window
            </Text>
            <Text mode="SubHeading">
              Starts 21 Jul 2022 9:00 AM. Due 21 Jul 2022 5:00 PM
            </Text>

            <Text variant="light" className="mt-3">
              Assigned by
            </Text>
            <Text mode="SubHeading">Saoud Nazir</Text>
          </div>
          <div className="h-100 bg-light p-4">
            <div className="bg-white border rounded px-2 py-3">
              <Text variant="light" className="">
                Assigned to
              </Text>
              <Text mode="SubHeading">Saoud Nazir</Text>
              <Button
                className="w-100 my-3"
                style={{ fontWeight: "600" }}
                onClick={() => {
                  navigate("/app/template/preview");
                }}
              >
                Start Inspection
              </Button>
              <Button
                className="w-100 border text-decoration-none"
                style={{ fontWeight: "600" }}
                variant="link"
              >
                Won't do
              </Button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
}
