import axiosInstance from "./axiosInstance";
import {Optional} from "./general";
export type QuestionType = {
  // dummyId?: string;
  // id?: string;
  // title: string;
  // isRequired?: boolean;
  // type:
  // | "SHORT_ANSWER"
  // | "NUMBER"
  // | "CHECKBOX"
  // | "DATETIME"
  // | "MEDIA"
  // | "SLIDER"
  // | "ANNOTATION"
  // | "SIGNATURE"
  // | "LOCATION"
  // | "INSTRUCTIONS"
  // | "SITE"
  // | "MULTICHOICE_RADIO"
  // | "MULTICHOICE_CHECKBOX";

  options?: Array<{
    id?: string;
    // "sequence": number,
    title: string;
    dummyId?: number;
  }>;


  id: string;
  mediaPaths: Array<string>;
  multiOptionValues: Array<{
    id: string;
  }>;
  value: any;
  notes: string;

  // When Fetch Inspection data
  type: | "SHORT_ANSWER"
  | "NUMBER"
  | "CHECKBOX"
  | "DATETIME"
  | "MEDIA"
  | "SLIDER"
  | "ANNOTATION"
  | "SIGNATURE"
  | "LOCATION"
  | "INSTRUCTIONS"
  | "SITE"
  | "MULTICHOICE_RADIO"
  | "MULTICHOICE_CHECKBOX";
  isRequired?: boolean;
  title?: string;
  dummyId?: string;
  multiOptions?: Array<{
    id: string;
    title: string;
    selected: boolean;
    dummyId?: string;
  }>;

};
export type TemplateReportPayload = {
  title: string;
  logoBase64: string;
  logoFileName: string;
  showFlaggedItems: boolean;
  showActions: boolean;
  showDisclaimer: boolean;
  showInspectionResults: boolean;
  showMediaSummary: boolean;
  showScores: boolean;
  showInstruction: boolean;
  showUnansweredQuestions: boolean;
  status: "Incomplete" | "Complete";
};
export type TemplateUpdatePayload = {
  // logoBase64: string;
  templateImage?:
  | {
    base64: string;
    fileName: string;
  }
  | string;
  title: string;
  description: string;
  pages: Array<{
    id: string;
    title: string;
    description: string;
    questions?: Array<QuestionType>;
  }>;
};

export type TemplateCreatePayload = {
  // logoBase64: string;
  id?: string;
  templateImage?: {
    base64: string;
    fileName: string;
    url: string;
  };
  title: string;
  description: string;
  pages: Array<{
    id: string;
    title: string;
    description: string;
    questions?: Array<QuestionType>;
  }>;

  // description?: string;
  // pages: Array<{
  //   description?: string;
  //   id: string;
  //   sections: Array<{
  //     description?: string;
  //     id?: string;
  //     questions: Array<QuestionType>;

  //     sequence: number;
  //     title: string;
  //   }>;
  //   sequence: number;
  //   title: string;
  // }>;
  // templateImage?: {
  //   base64: string;
  //   fileName: string;
  // };
  // title: string;
};

export type TemplateFilterPayload = {
  ownedBy: Array<number>;
  availableTo: Array<number>;
};

export type GlobalResponseSet = {
  id: number;
  name: string;
  updatedAt: number;
  responses: Array<{
    id?: number;
    text: string;
  }>;
};

declare module Template {
  interface ITemplate {
    id?: string;
    templateImage?: {
      base64: string;
      fileName: string;
      url: string;
    };
    title: string;
    template?: string;
    description: string;
    pages: Array<{
      id?: string;
      title: string;
      description: string;
      questions?: Array<QuestionType>;
      sections: Array<{
        id: string;
        questions: Array<
          | {
            id: string;
            mediaPaths: Array<string>;

            multiOptionValues: Array<{
              id: string;
            }>;
            value: any;
            notes: string;
          }
          | any
        >;
      }>;
    }>;
    mediaUniqueId: string;
  }
  type FetchResponse = {
    id: string;
    imagePath: string;
    title: string;
    lastModified: string;
    scheduled: boolean;
    access: string;
  };

  type TemplateDetailsResponse = {
    pages: number;
    sections: number;
    questions: number;
    access: string;
    description: string;
    usage: null;
    lastModifiedOn: string;
    lastModifiedBy: null;
    createdOn: string;
    createdBy: null;
    organization: null;
    owner: string;
    title: string;
  };
}

module Template {
  export function CreateTemplate(
    data: Optional<ITemplate, "id">,
    success: (template: ITemplate) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`/comply-tsfm/api/auditorTemplate`, data)
      .then(({data}) => {
        // TODO: Need to implement Create logic waiting for API to be updated
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function FetchAllTemplates(
    success: (template: Array<FetchResponse>) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`/comply-tsfm/api/auditorTemplate/`)
      .then(({data}) => {
        // TODO: Need to implement Read logic waiting for API to be updated
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function FetchTemplate(
    id: string,
    success: (template: ITemplate) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`/comply-tsfm/api/auditorTemplate/${id}`)
      .then(({data}) => {
        // TODO: Need to implement Read logic waiting for API to be updated
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function FetchTemplateDetails(
    id: string,
    success: (details: TemplateDetailsResponse) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`/comply-tsfm/api/auditorTemplate/${id}/details`)
      .then(({data}) => {
        // TODO: Need to implement Read logic waiting for API to be updated
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function UpdateTemplate(
    id: string,
    data: ITemplate,
    success: (template: ITemplate) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .put(`/comply-tsfm/api/auditorTemplate/${id}`, data)
      .then(({data}) => {
        // TODO: Need to implement Update logic waiting for API to be updated
        success(data);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }

  export function DeleteTemplate(
    id: string,

    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .delete(`/comply-tsfm/api/auditorTemplate/${id}`)
      .then(({data}) => {
        // TODO: Need to implement delete logic waiting for API to be updated
        success(data?.message);
      })
      .catch((e) => {
        error(e.response?.data?.error ?? e.response?.data?.message);
      });
  }
}

export default Template;
