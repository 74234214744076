import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { MdFeedback } from "react-icons/md";
import { AddIcon } from "../../components/icons/icons";
import Text from "../../components/text/text";
import MySchedules from "./tabs/my";
import "./schedule.scss";
import ManageSchedules from "./tabs/manage";
import MissedLateInspections from "./tabs/missed-late";
import { useNavigate, useParams } from "react-router-dom";

export default function Schedule({}) {
  const { tab } = useParams();

  const navigate = useNavigate();
  return (
    <Container fluid className=" px-3 py-4 px-md-5">
      <Row>
        <Col xs="auto">
          <Text mode="Heading">Schedule</Text>
        </Col>
        <Col className="justify-content-end d-flex">
          <Button className="text-decoration-none" variant="link">
            <MdFeedback size={18} /> Send Feedback
          </Button>
          <Button
            style={{ fontWeight: "500" }}
            className="d-flex align-items-center"
            onClick={() => {
              navigate("/app/schedule/create");
            }}
          >
            <AddIcon size={18} className="me-2" /> Schedule Inspections
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Tab.Container defaultActiveKey={tab || "my"}>
          <Row className="border-bottom pt-2 px-3">
            <Col>
              <Nav defaultActiveKey={tab || "my"}>
                <Nav.Item>
                  <Nav.Link eventKey="my" className="qs-tab-item">
                    My Schedule
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="manage" className="qs-tab-item">
                    Manage Schedule
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="late-missed" className="qs-tab-item">
                    Missed/Late Inspections
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Tab.Content className="">
            <Tab.Pane eventKey="my">
              <MySchedules />
            </Tab.Pane>
            <Tab.Pane eventKey="manage">
              <ManageSchedules />
            </Tab.Pane>
            <Tab.Pane eventKey="late-missed">
              <MissedLateInspections />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Row>
    </Container>
  );
}
