import {
  Alert,
  Button,
  Container,
  FormCheck,
  Image,
  Nav,
  Stack,
  Tab,
} from "react-bootstrap";
import Text from "../../../components/text/text";
import Select from "react-select";
import SelectTheme from "../../../scss/select.theme";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { MdDelete, MdOutlineKeyboardArrowRight } from "react-icons/md";
import "./styles.scss";
import { useRef, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import TextInput, { TextInputWithRef } from "../../../components/input/input";
import EmailSelector from "../../../components/email-selector/email-selector";
import TextAreaResize from "react-textarea-autosize";
import { Controller, UseFormReturn } from "react-hook-form";
import { TemplateReportPayload } from "../../../api/template";
import { getBase64 } from "../../../api/general";
interface TemplateLayoutSelectorProps {
  form: UseFormReturn<TemplateReportPayload, object>;
}
export default function TemplateLayoutSelector({
  form,
}: TemplateLayoutSelectorProps) {
  const [openEditLayout, setOpenEditLayout] = useState(true);
  if (openEditLayout) {
    return <EditLayout form={form} />;
  } else {
    return (
      <Container className="py-3 " style={{}}>
        <Text mode="Heading">Report layout</Text>
        <Stack className="mt-3">
          <Text variant="light">Default Layout</Text>
          <Select
            styles={SelectTheme}
            options={[
              {
                value: "Layout 1",
                label: "Layout 1",
              },
            ]}
          />
        </Stack>
        <Stack className="mt-3">
          <Text variant="light">Your Layouts</Text>
          {Array.from({ length: 5 }).map((_, i) => (
            <Stack
              key={i}
              direction="horizontal"
              className={`justify-content-between template-layout-item ${
                i === 0 ? "active" : ""
              }`}
            >
              <Stack direction="horizontal">
                <BsReverseLayoutTextWindowReverse size={20} />
                <div className="ms-2">Untitled Layout</div>
              </Stack>
              <Stack direction="horizontal">
                <div className="template-layout-item-default-badge">
                  default
                </div>
                <span className="animated-btn">
                  <MdOutlineKeyboardArrowRight size={20} />
                </span>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Container>
    );
  }
}
interface EditLayoutProps {
  form: UseFormReturn<TemplateReportPayload, object>;
}
function EditLayout({ form }: EditLayoutProps) {
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const [emailTemplate, setEmailTemplate] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const uploadedLogo = form.watch("logoBase64");

  return (
    <Container className="py-3 px-0 h-100 d-flex flex-column ">
      <Stack direction="horizontal" className="justify-content-between">
        <Stack direction="horizontal">
          <span className="animated-btn">
            <RiArrowLeftSLine size={20} />
          </span>
          <Text mode="Heading">Edit Layout</Text>
        </Stack>
        <span className="animated-btn me-2">
          <MdDelete size={20} />
        </span>
      </Stack>
      <Controller
        control={form.control}
        name="title"
        render={({ field }) => (
          <TextInputWithRef wrapperClassName="p-3" {...field} />
        )}
      />

      <div style={{ flex: 1 }} className="overflow-auto d-flex flex-column">
        <Tab.Container defaultActiveKey="display">
          <Nav className="w-100 border-bottom">
            <Nav.Item className="flex-grow-1 text-center">
              <Nav.Link className="qs-tab-item  " eventKey="display">
                Display
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="flex-grow-1 text-center">
              <Nav.Link className="qs-tab-item" eventKey="email-template">
                Email template
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className=" overflow-auto" style={{ flex: 1 }}>
            <Tab.Content style={{ flex: 1 }} className="overflow-auto">
              <Tab.Pane eventKey="display" className="overflow-auto p-3">
                <div>
                  <Text fontSize="14px" variant="light">
                    Logo
                  </Text>
                  <div className="flex-column d-flex">
                    {uploadedLogo && (
                      <div className="position-relative">
                        <Image
                          src={`data:image/png;base64,${uploadedLogo}`}
                          style={{
                            width: "5rem",
                            height: "5rem",
                            objectFit: "contain",
                          }}
                        />
                        <span
                          className="animated-btn position-absolute"
                          onClick={() => {
                            form.setValue("logoBase64", "");
                          }}
                        >
                          <MdDelete size={20} />
                        </span>
                      </div>
                    )}
                    <div>
                      <Button
                        variant="light"
                        className="d-inline-block text-primary fw-bold"
                        onClick={() => {
                          inputRef.current?.click();
                        }}
                      >
                        {uploadedLogo
                          ? "Upload a different logo"
                          : "Upload a logo"}
                      </Button>
                    </div>
                  </div>
                  <Controller
                    control={form.control}
                    name="logoBase64"
                    render={({ field }) => (
                      <input
                        hidden
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        ref={inputRef}
                        onChange={(e) => {
                          getBase64(e)
                            .then((base64) => {
                              if (base64) form.setValue("logoBase64", base64);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      />
                    )}
                  />
                </div>

                <Stack className="mt-4">
                  <Text fontSize="14px" variant="light">
                    Content
                  </Text>
                  {[
                    {
                      label: "Flagged items",
                      formKey: "showFlaggedItems",
                      onNextClick: () => {},
                    },
                    {
                      label: "Actions",
                      formKey: "showActions",
                      // onNextClick: () => {},
                    },
                    {
                      label: "Disclaimer",
                      formKey: "showDisclaimer",
                      // onNextClick: () => {},
                    },
                    {
                      label: "Inspection results",
                      formKey: "showInspectionResults",
                      // onNextClick: () => {},
                    },
                    {
                      label: "Media summary",
                      formKey: "showMediaSummary",
                      // onNextClick: () => {},
                    },
                    {
                      label: "Scores",
                      formKey: "showScores",
                      onNextClick: () => {},
                    },
                    {
                      label: "Instructions",
                      formKey: "showInstruction",
                      onNextClick: () => {},
                    },
                    {
                      label: "Unanswered questions",
                      formKey: "showUnansweredQuestions",
                      // onNextClick: () => {},
                    },
                  ].map(({ label, onNextClick, formKey }, i) => (
                    <Stack
                      key={i}
                      direction="horizontal"
                      className="justify-content-between "
                      style={{ minHeight: "40px" }}
                    >
                      <Stack
                        direction="horizontal"
                        className="align-items-center"
                      >
                        <Controller
                          control={form.control}
                          name={formKey as any}
                          render={({ field }) => (
                            <FormCheck
                              className="layout-selector-check"
                              // onChange={(e: any) => {
                              //   onCheckboxClicked(e.target.checked);
                              // }}
                              type="checkbox"
                              {...field}
                              checked={field.value}
                            />
                          )}
                        />

                        <Text className="ms-4">{label}</Text>
                      </Stack>
                      {onNextClick && (
                        <span className="animated-btn" onClick={onNextClick}>
                          <MdOutlineKeyboardArrowRight size={25} />
                        </span>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Tab.Pane>

              <Tab.Pane eventKey="email-template">
                <Text className="m-3" fontSize="14px">
                  Set up the email template when people send out the report.
                </Text>
                <div className="m-3 shadow" ref={emailContainerRef}>
                  <EmailSelector
                    placeholder="To:"
                    innerRef={emailContainerRef}
                  />
                  <EmailSelector
                    placeholder="Cc:"
                    innerRef={emailContainerRef}
                  />
                  <EmailSelector
                    placeholder="Bcc:"
                    innerRef={emailContainerRef}
                  />
                  <div className="border-bottom">
                    <Stack
                      direction="horizontal"
                      className="py-2 px-3 email-selector-container justify-content-between pointer "
                    >
                      <Text style={{ minWidth: "40px" }}>Subject:</Text>
                      <div className="flex-grow-1">
                        <TextInput
                          className="border-0"
                          defaultValue="[InspectionTitle]"
                          onChange={(e: any) => {
                            setEmailTemplate(e.target.value);
                          }}
                          value={emailTemplate}
                        />
                      </div>
                    </Stack>
                  </div>
                  <div className="border-bottom">
                    <TextAreaResize
                      className="w-100 border-0 form-control fs-6"
                      minRows={5}
                      maxRows={10}
                      style={{ resize: "none" }}
                    />
                  </div>
                </div>
                <Alert className="mt-4 mx-3 text-break">
                  The Subject and Email Body can be populated based on
                  inspection data, using the following symbols:
                  <br />
                  <span className="fw-bold">[TemplateTitle]</span> - Title of
                  the template e.g. Test Template <br />
                  <span className="fw-bold">[InspectionTitle]</span> - Title of
                  the inspection e.g. Saoud Nazir
                  <br />
                  <span className="fw-bold">[InspectionDate]</span> - Date of
                  inspection conducted e.g. 16 Aug 2022 <br />
                  <span className="fw-bold">[Score]</span> - Total score of
                  inspection e.g.80%
                </Alert>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
      <div className="w-100 border-top p-2">
        <Button className="w-100">Save</Button>
      </div>
    </Container>
  );
}
