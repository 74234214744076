import { useCallback, useState } from "react";
import { Button, Modal, ModalProps, Stack } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { UseFormReturn } from "react-hook-form";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import Text from "../text/text";

interface AttachmentModalProps extends ModalProps {
  form: UseFormReturn<any, object>;
  formKey?: string;
}
export default function AttachmentModal(props: AttachmentModalProps) {
  const [message, setMessage] = useState("");
  const uploadedFiles: Array<File> = props.form.watch("attachments");
  const onDrop = useCallback(
    (acceptedFiles: Array<File>, rejectedFiles: any) => {
      setMessage("");
      if (rejectedFiles.length > 0) {
        setMessage(
          `${rejectedFiles.length} file(s) rejected. Only PDF, PNG and JPG are allowed.`
        );
      } else {
        props.form.setValue(props.formKey ?? "attachments", [
          ...props.form.getValues(props.formKey ?? "attachments"),
          ...acceptedFiles,
        ]);
      }
    },
    []
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Modal
      {...props}
      contentClassName={`p-3 border-0 shadow ${props.contentClassName ?? ""}`}
    >
      <Modal.Title>Add Media</Modal.Title>

      <Text
        onClick={open}
        variant="primary"
        style={{
          cursor: "pointer",
        }}
        className="align-self-end"
      >
        <MdCloudUpload className="me-2" /> Add Files
      </Text>

      <div
        {...getRootProps()}
        className="my-1 rounded "
        style={{
          backgroundColor: "var(--bs-primary-light)",
          height: "200px",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <MdCloudUpload size={80} style={{ color: "var(--bs-primary)" }} />
          </div>
        ) : (
          <div className="p-1 overflow-auto h-100">
            {uploadedFiles.length > 0 ? (
              uploadedFiles.map((file, i) => (
                <div
                  className="bg-white d-inline-block p-2 rounded w-100 my-1 text-break"
                  key={i}
                >
                  <Text>
                    <span
                      className="animated-btn me-2"
                      onClick={() => {
                        props.form.setValue(props.formKey ?? "attachments", [
                          ...uploadedFiles.filter((f) => f !== file),
                        ]);
                      }}
                    >
                      <MdDelete size={20} />
                    </span>{" "}
                    {file.name}
                  </Text>
                </div>
              ))
            ) : (
              <Stack className="align-items-center h-100 justify-content-center">
                <MdCloudUpload
                  size={80}
                  style={{ color: "var(--bs-primary)" }}
                />
                <p>Drag and drop files here</p>
              </Stack>
            )}
          </div>
        )}
      </div>
      <Text variant="danger" className="text-center my-2" flexType="d-block">
        {message}
      </Text>
      <Stack direction="horizontal" className="justify-content-end">
        <Button className="mx-2">Upload</Button>
        <Button
          variant="light"
          className="border"
          onClick={() => props?.onHide && props?.onHide()}
        >
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
}
