import { Button, Col, Container, Row } from "react-bootstrap";
import { HiRefresh } from "react-icons/hi";

import { MdSettings } from "react-icons/md";
import { ActionsIcon } from "../../components/icons/icons";
import Text from "../../components/text/text";
import "./notifications.scss";

export default function Notifications() {
  return (
    <Container fluid className="bg-light notifications-wrapper">
      <Row>
        <Col>
          <Text mode="Heading">Notifications</Text>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant="link" className="text-decoration-none fw-bold">
            <HiRefresh size={20} /> Refresh
          </Button>
          <Button className="ms-4">
            <MdSettings size={20} /> Settings
          </Button>
        </Col>
      </Row>
      {Array.from({ length: 20 }).map((_, index) => (
        <div
          className="notification-item p-3 my-2 rounded d-flex justify-content-between align-items-center"
          key={index}
        >
          <div>
            <ActionsIcon className="text-primary" size={20} /> Due in 2 hours -{" "}
            <span className="fw-bold">Inspection #{index + 1}</span>
          </div>
          <div>30 Jun 2022</div>
        </div>
      ))}
    </Container>
  );
}
