import { Container, Modal, Nav, Tab } from "react-bootstrap";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CategoryAccess from "./tabs/access";
import CategoryWorkflow from "./tabs/workflow";

export default function EditSingleCategory({}) {
  const navigate = useNavigate();
  return (
    <Modal show={true} fullscreen>
      <Modal.Header>
        <Modal.Title
          className="d-flex align-items-center pointer"
          onClick={() => {
            navigate(-1);
          }}
        >
          <MdOutlineArrowBack size={20} className="me-2" /> Back
        </Modal.Title>
        <Modal.Title>Edit Category</Modal.Title>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Tab.Container defaultActiveKey="workflow">
        <div className="border-bottom d-flex justify-content-center">
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="workflow" className="qs-tab-item">
                Workflow
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="access" className="qs-tab-item">
                Access
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="workflow">
            <CategoryWorkflow />
          </Tab.Pane>
          <Tab.Pane eventKey="access">
            <CategoryAccess />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Modal>
  );
}
