import { useState } from "react";
import { Button, Col, Container, ListGroup, Row, Stack } from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md";
import Text from "../../../components/text/text";
import Select from "react-select";
import SelectTheme from "../../../scss/select.theme";

export default function CategoryAccess({}) {
  const [isEditingAccess, setIsEditingAccess] = useState(false);
  return (
    <Container className="mt-5">
      <div className="border rounded p-3">
        <Stack direction="horizontal" className="justify-content-between">
          <Text fontSize="20px" fontWeight="500">
            Category Visibility
          </Text>
          <Button variant="light" className="border hover-btn">
            Disable category
          </Button>
        </Stack>
        <Text variant="light">
          "Observation" category is enabled for anyone in your team to use.
        </Text>
      </div>
      <ListGroup className="mt-5">
        <ListGroup.Item className="py-3">
          <Text fontSize="20px" fontWeight="500">
            Access to reported issues
          </Text>
          <Text variant="light">
            Give access to the following people when reporting: "Observation"
          </Text>
        </ListGroup.Item>
        <ListGroup.Item className="py-3">
          <Row>
            <Col>
              <Text variant="light" fontSize="0.9rem">
                When reported by
              </Text>
            </Col>
            <Col>
              <Text variant="light" fontSize="0.9rem">
                "Observation" will be available to
              </Text>
            </Col>
            <Col>
              <Text variant="light" fontSize="0.9rem">
                Access level
              </Text>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item className="py-3">
          <Row>
            <Col>
              <Text fontSize="0.9rem">All Users</Text>
            </Col>
            <Col>
              <div
                style={{ backgroundColor: "var(--bs-primary-light)" }}
                className=" px-2 rounded d-inline-flex align-items-center me-2"
              >
                <MdAccountCircle className="me-2" /> Saoud Nazir
              </div>
              <div
                style={{ backgroundColor: "var(--bs-primary-light)" }}
                className=" px-2 rounded d-inline-flex align-items-center me-2"
              >
                <MdAccountCircle className="me-2" /> Saoud Nazir
              </div>
            </Col>
            <Col>
              <Text fontSize="0.9rem">Edit, View</Text>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item className="py-3">
          {isEditingAccess ? (
            <div className="d-inline-block w-25">
              <Select styles={SelectTheme} />
              <Stack direction="horizontal" className="mt-2">
                <Button
                  variant="light"
                  className="border"
                  onClick={() => {
                    setIsEditingAccess(false);
                  }}
                >
                  Cancel
                </Button>
                <Button className="ms-2">Save</Button>
              </Stack>
            </div>
          ) : (
            <Button
              className="fw-bold hover-btn border"
              variant="light"
              onClick={() => {
                setIsEditingAccess(true);
              }}
            >
              Edit Access
            </Button>
          )}
        </ListGroup.Item>
      </ListGroup>
    </Container>
  );
}
