import axios from "axios";
import {useEffect} from "react";
import {Container, Spinner, Stack} from "react-bootstrap";
import {useNavigate, useSearchParams} from "react-router-dom";
import Text from "../../components/text/text";
import {useUserProfile} from "../../hooks/user";

export default function Auth({}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const token = params.get("token");

  const businessId = params.get("business");

  const user = useUserProfile();
  useEffect(() => {
    localStorage.removeItem("token");
    if (token) {
      const baseURL = process.env.REACT_APP_API_URL;
      axios
        .get(`${baseURL}/comply-tsfm/api/auth/validateSessionToken`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({data}) => {
          localStorage.setItem("user", JSON.stringify({...data, businessId}));
          localStorage.setItem("token", token);
          user.setUser?.(data);
          navigate("/app/dashboard");
        })
        .catch((error) => {
          // console.log(error);
          alert("Failed to Authenticate");
          // window.location.replace(process.env.REACT_APP_TIMESHEET_URL!);
        });
    } else {
      window.location.replace(
        `${process.env.REACT_APP_TIMESHEET_URL}/?returnURL=${window.location.protocol}//${window.location.host}`
      );
    }
  }, []);
  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
      <Stack direction="horizontal">
        <Spinner animation="border" variant="primary" />
        <Text fontSize="2rem" className="ms-4">
          Authenticating...
        </Text>
      </Stack>
    </div>
  );
}
