import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { MdFeedback } from "react-icons/md";
import Text from "../../components/text/text";
import "./inspections.scss";
import Inspection from "./tabs/inspection";

export default function Inspections({}) {
  return (
    <Tab.Container defaultActiveKey="inspection">
      <Row className="border-bottom pt-2 px-3">
        <Col>
          <Nav defaultActiveKey="inspection">
            <Nav.Item>
              <Nav.Link eventKey="inspection" className="inspection-tab-item">
                Inspections
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="archive" className="inspection-tab-item">
                Archive
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs="auto" className="justify-content-center d-flex">
          <Nav.Item>
            <Nav.Link className="inspection-tab-item inspection-feedback-btn">
              <MdFeedback /> Send Feedback
            </Nav.Link>
          </Nav.Item>
        </Col>
      </Row>
      <Tab.Content>
        <Tab.Pane eventKey="inspection">
          <Inspection mode="Inspection" />
        </Tab.Pane>
        <Tab.Pane eventKey="archive">
          <Inspection mode="Archive" />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}
