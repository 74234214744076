import { MdLink } from "react-icons/md";
import Text from "../text/text";

export default function MenuPopupItem({
  externalLink,
  Icon,
  title,
  subTitle,
}: {
  externalLink?: string;
  title: string;
  Icon?: any;
  subTitle?: string;
}) {
  return (
    <div
      className="menu-popup-btn d-flex justify-content-between align-items-start py-2 px-3"
      onClick={() => {
        if (externalLink) {
          window.open(externalLink, "_blank");
        }
      }}
    >
      {Icon && <Icon size={22} />}
      <span className="mx-3 flex-fill" style={{ fontSize: "0.9rem" }}>
        <Text>{title}</Text>
        {subTitle && (
          <>
            <span className="fw-light" style={{ fontSize: "10px" }}>
              {subTitle}
            </span>
          </>
        )}
      </span>

      {externalLink && <MdLink size={22} />}
    </div>
  );
}
