import { AxiosError } from "axios";
import axiosInstance from "./axiosInstance";

declare module Location {
  interface ILocation {
    id: number;
    name: string;
    areas: Array<{
      id: number;
      name: string;
    }>;
  }
}

module Location {
  export function FetchLocations(
    success: (data: Array<ILocation>) => void,
    error: (error: AxiosError) => void
  ) {
    return axiosInstance
      .get("/comply-tsfm/api/location/list")
      .then(({ data }) => success(data))
      .catch(error);
  }
}
export default Location;
