import { useEffect, useState } from "react";
import {
  Button,
  FormCheck,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import { Controller, UseFormReturn } from "react-hook-form";
import { BiSliderAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCommentDots, FaFileSignature } from "react-icons/fa";
import { HiAnnotation } from "react-icons/hi";
import { ImCheckboxChecked } from "react-icons/im";
import {
  MdDateRange,
  MdLocationPin,
  MdOutlineFormatColorText,
  MdPermMedia,
} from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import { TbNumbers } from "react-icons/tb";
import { VscCircuitBoard } from "react-icons/vsc";
import { useMediaQuery } from "react-responsive";
import Template, { TemplateCreatePayload } from "../../../api/template";
import Text from "../../../components/text/text";
import { ResponseType } from "./response-types";
type TextAnswerProps = {
  text: string;
};
function TextAnswer({}) {
  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [show, setShow] = useState(false);
  const formatModal = (
    <Popover className="qs-dropdown">
      <div>Short Answer</div>
      <div>Paragraph</div>
    </Popover>
  );
  if (!isLargeScreen) {
    return (
      <>
        <Stack
          direction="horizontal"
          className=" align-items-center justify-content-between"
        >
          <Text className="p-2" fontSize="0.8rem">
            Text answer
          </Text>
          <Stack direction="horizontal">
            <span className="animated-btn">
              <RiArrowDownSLine size={25} />
            </span>
            <div className="vr" />

            <span
              className="animated-btn"
              onClick={() => {
                setShow(true);
              }}
            >
              <BsThreeDotsVertical size={25} />
            </span>
          </Stack>
        </Stack>
        <Offcanvas
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Options</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack direction="vertical">
              <Stack direction="horizontal" className="mb-2">
                <FormCheck className="me-2" />
                <Text>Required</Text>
              </Stack>

              <OverlayTrigger
                placement="auto"
                rootClose
                trigger="click"
                overlay={formatModal}
              >
                <span
                  className="pointer my-2 d-inline-block"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Text>
                    Format:{" "}
                    <span className="ms-2 text-primary text-decoration-underline">
                      Short Answer
                    </span>
                  </Text>
                </span>
              </OverlayTrigger>

              {/* <Button className="my-2">
                <VscCircuitBoard size={16} className="me-1" /> Add Logic
              </Button> */}
            </Stack>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  } else {
    return (
      <Stack direction="horizontal" className="p-2 position-relative">
        {/* <div
          className="text-primary pointer d-flex align-items-center"
          style={{ fontSize: "0.8rem", fontWeight: "500" }}
        >
          <VscCircuitBoard size={16} className="me-1" /> Add Logic
        </div> */}
        {/* <div className="vr mx-3" /> */}
        <FormCheck className="me-2" />
        <Text fontWeight="500" fontSize="0.8rem">
          Required
        </Text>
        <div className="vr mx-3" />
        <Text fontWeight="500" fontSize="0.8rem">
          Format:{" "}
        </Text>
        <OverlayTrigger
          placement="bottom"
          rootClose
          trigger="click"
          overlay={
            <Popover className="qs-dropdown">
              <div>Short Answer</div>
              <div>Paragraph</div>
            </Popover>
          }
        >
          <span>
            <Text fontSize="0.8rem" className="text-primary">
              Short Answer
            </Text>
          </span>
        </OverlayTrigger>
      </Stack>
    );
  }
}

type NumberAnswerProps = {
  number: number;
};
function NumberAnswer({}) {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const [show, setShow] = useState(false);
  const formatModal = (
    <Popover className="qs-dropdown">
      <div>Short Answer</div>
      <div>Paragraph</div>
    </Popover>
  );
  if (isSmallDevice) {
    return (
      <>
        <Stack
          direction="horizontal"
          className=" align-items-center justify-content-between"
        >
          <Text className="p-2" fontSize="0.8rem">
            Text answer
          </Text>
          <Stack direction="horizontal">
            <span className="animated-btn">
              <RiArrowDownSLine size={25} />
            </span>
            <div className="vr" />

            <span
              className="animated-btn"
              onClick={() => {
                setShow(true);
              }}
            >
              <BsThreeDotsVertical size={25} />
            </span>
          </Stack>
        </Stack>
        <Offcanvas
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Options</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack direction="vertical">
              <Stack direction="horizontal" className="mb-2">
                <FormCheck className="me-2" />
                <Text>Required</Text>
              </Stack>

              <OverlayTrigger
                placement="auto"
                rootClose
                trigger="click"
                overlay={formatModal}
              >
                <span
                  className="pointer my-2 d-inline-block"
                  onClick={(e) => {}}
                >
                  <Text>
                    Format:{" "}
                    <span className="ms-2 text-primary text-decoration-underline">
                      Number
                    </span>
                  </Text>
                </span>
              </OverlayTrigger>

              {/* <Button className="my-2">
                <VscCircuitBoard size={16} className="me-1" /> Add Logic
              </Button> */}
            </Stack>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  } else {
    return (
      <Stack direction="horizontal" className="p-2 position-relative">
        {/* <div
          className="text-primary pointer d-flex align-items-center"
          style={{ fontSize: "0.8rem", fontWeight: "500" }}
        >
          <VscCircuitBoard size={16} className="me-1" /> Add Logic
        </div> */}
        {/* <div className="vr mx-3" /> */}
        <FormCheck className="me-2" />
        <Text fontWeight="500" fontSize="0.8rem">
          Required
        </Text>
        <div className="vr mx-3" />
        <Text fontWeight="500" fontSize="0.8rem">
          Format:{" "}
        </Text>
        {/* <OverlayTrigger
          placement="bottom"
          rootClose
          trigger="click"
          overlay={
            <Popover className="qs-dropdown">
              <div>Short Answer</div>
              <div>Paragraph</div>
            </Popover>
          }
        > */}
        <span>
          <Text fontSize="0.8rem" className="text-primary">
            Number
          </Text>
        </span>
        {/* </OverlayTrigger> */}
      </Stack>
    );
  }
}

type CheckboxAnswerProps = {
  checked: boolean;
};
function CheckboxAnswer({}: CheckboxAnswerProps) {
  return;
}

// export type ResponseActionProps = {
//   SHORT_ANSWER: TextAnswerProps;
//   NUMBER: NumberAnswerProps;
//   CHECKBOX: CheckboxAnswerProps;
//   DATETIME: any;
//   MEDIA: any;
//   SLIDER: any;
//   ANNOTATION: any;
//   SIGNATURE: any;
//   LOCATION: any;
//   INSTRUCTIONS: any;
// };

export type ResponseActionProps = {
  type: string;
  onChange: (value: ResponseType) => void;
  defaultOption: ResponseType;
  templateForm?: UseFormReturn<Template.ITemplate, any>;
  templateFormControllerKey?: any;
};
const ResponseAction = ({
  type,
  defaultOption,
  onChange,
  templateForm,
  templateFormControllerKey,
}: ResponseActionProps) => {
  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [show, setShow] = useState(false);
  const formatModal = (
    <Popover className="qs-dropdown">
      <div>Short Answer</div>
      <div>Paragraph</div>
    </Popover>
  );

  const [showCanvas, setShowCanvas] = useState(false);
  const types: Array<ResponseType> = [
    {
      type: "SHORT_ANSWER",
      label: "Short Answer",
      Icon: MdOutlineFormatColorText,
    },
    {
      type: "NUMBER",
      label: "Number",
      Icon: TbNumbers,
    },
    {
      type: "CHECKBOX",
      label: "Checkbox",
      Icon: ImCheckboxChecked,
    },
    {
      Icon: MdDateRange,
      type: "DATETIME",
      label: "Date & Time",
    },
    {
      Icon: MdPermMedia,
      type: "MEDIA",
      label: "Media",
    },
    {
      Icon: BiSliderAlt,
      type: "SLIDER",
      label: "Slider",
    },
    {
      Icon: HiAnnotation,
      label: "Annotation",
      type: "ANNOTATION",
    },
    {
      Icon: FaFileSignature,
      type: "SIGNATURE",
      label: "Signature",
    },
    {
      Icon: MdLocationPin,
      type: "LOCATION",
      label: "Location",
    },
    {
      Icon: FaCommentDots,
      type: "INSTRUCTIONS",
      label: "Instructions",
    },
  ];

  const [selectedtype, setSelectedtype] = useState<ResponseType>(defaultOption);
  useEffect(() => {
    onChange(selectedtype);
  }, [selectedtype]);

  if (!isLargeScreen) {
    return (
      <>
        <Stack
          direction="horizontal"
          className=" align-items-center justify-content-between"
        >
          <Stack
            direction="horizontal"
            className="justify-content-between flex-grow-1 pointer"
            onClick={() => {
              setShowCanvas(true);
            }}
          >
            <Text className="p-2" fontSize="0.8rem">
              {selectedtype.label}
            </Text>
            <span className="animated-btn">
              <RiArrowDownSLine size={25} />
            </span>
          </Stack>
          <Stack direction="horizontal">
            <div className="vr" />

            <OverlayTrigger
              placement="bottom-end"
              rootClose
              trigger="click"
              overlay={
                <Popover className="qs-dropdown">
                  <Stack direction="horizontal">
                    <Controller
                      control={templateForm?.control}
                      name={templateFormControllerKey}
                      render={({ field }) => (
                        <FormCheck
                          className="me-2 no-padding"
                          {...field}
                          checked={field.value}
                        />
                      )}
                    />

                    <Text
                      fontWeight="500"
                      fontSize="0.8rem"
                      className="no-padding"
                    >
                      Required
                    </Text>
                  </Stack>
                </Popover>
              }
            >
              <span
                className="animated-btn"
                onClick={() => {
                  setShow(true);
                }}
              >
                <BsThreeDotsVertical size={25} />
              </span>
            </OverlayTrigger>
          </Stack>
        </Stack>
        <Offcanvas
          show={showCanvas}
          onHide={() => {
            setShowCanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Options</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <Stack direction="horizontal" className="mb-2 px-3">
              <FormCheck className="me-2" />
              <Text>Required</Text>
            </Stack>
            {types.map(({ Icon, label, type }, i) => (
              <div
                key={i}
                onClick={() => {
                  setSelectedtype({ type, label, Icon });
                  setShowCanvas(false);
                }}
                className="response-type-option"
              >
                <Icon className="me-2" /> {label}
              </div>
            ))}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  } else {
    return (
      <Stack direction="horizontal" className="p-2">
        {/* <div
          className="text-primary pointer d-flex align-items-center"
          style={{ fontSize: "0.8rem", fontWeight: "500" }}
        >
          <VscCircuitBoard size={16} className="me-1" /> Add Logic
        </div> */}
        {/* <div className="vr mx-3" /> */}
        <Controller
          control={templateForm?.control}
          name={templateFormControllerKey}
          render={({ field }) => (
            <FormCheck
              className="me-2"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              {...field}
              checked={field.value}
            />
          )}
        />
        <Text fontWeight="500" fontSize="0.8rem">
          Required
        </Text>

        {/* <div className="vr mx-3" />
        <Text fontWeight="500" fontSize="0.8rem">
          Format:{" "}
        </Text>
        <OverlayTrigger
          placement="bottom"
          rootClose
          trigger="click"
          overlay={
            <Popover className="qs-dropdown">
              <div>Short Answer</div>
              <div>Paragraph</div>
            </Popover>
          }
        >
          <span>
            <Text fontSize="0.8rem" className="text-primary">
              Short Answer
            </Text>
          </span>
        </OverlayTrigger> */}
      </Stack>
    );
  }
};

export default ResponseAction;
