import moment from "moment";
import Text from "../text/text";
import { Image } from "react-bootstrap";
import { MdPictureAsPdf } from "react-icons/md";
import { ActionItemProps } from "../../api/actions";

export default function ConversationItem({
  text,
  timestamp,
  type,
  createdBy,
  media,
}: ActionItemProps) {
  return (
    <div>
      {type !== "ACTION" && <Text variant="primary">{createdBy.name}</Text>}
      {type === "ACTION" && (
        <Text fontWeight="400" fontSize="16px">
          {text}
        </Text>
      )}
      {type === "MESSAGE" && (
        <Text
          style={{
            maxWidth: "75%",
          }}
          fontWeight="400"
          fontSize="14px"
          color="white"
          className="bg-primary p-1 px-2 rounded "
          flexType="d-inline-block"
        >
          {text}
        </Text>
      )}

      {type === "IMAGE" && (
        <Image
          src="https://picsum.photos/200/300"
          className="border"
          rounded
          style={{
            width: "150px",
            height: "150px",
            objectFit: "fill",
          }}
        />
      )}

      {type === "PDF" && (
        <div className="bg-white d-inline-block p-2 rounded w-25 text-break">
          <a href={media?.url} target="_black">
            <MdPictureAsPdf className="me-2" />
            {media?.fileName}
          </a>
        </div>
      )}

      <Text variant="light" fontSize="11px" className="mt-1">
        {moment.unix(timestamp).format("DD MMM YYYY hh:mm A")}
      </Text>
    </div>
  );
}
