import {PDFDownloadLink, PDFViewer,usePDF} from "@react-pdf/renderer";
import {Fragment, useEffect, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import Inspection from "../../api/inspections";
import Text from "../../components/text/text";
import {useBusinessProfile} from "../../hooks/business";
import useNotifications from "../../hooks/notification";
import ReportPDFPreview, {
  ReportPDF,
} from "../templates/components/report-pdf-preview";
import ReportWebPreview from "../templates/components/report-web-preview";

export default function InspectionReport({}) {
  const {id,view} = useParams();
  const notification = useNotifications();
  const inspectionForm = useForm<Inspection.FetchInspectionResponse>({
    defaultValues: {},
  });
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const {businessName, logoPath} = useBusinessProfile();
  
  
  useEffect(() => {
  
    setShowModal(true);
    if (id) {
      
      Inspection.FetchInspectionById(
        id,
        (inspection) => {
          inspectionForm.reset(inspection);
          
        },
        (error) => {
          
          notification.showNotification(
            {
              type: "ERROR",
            },
            error
          );
        }
      );
    }
  }, []);
  
  

  

    return (
      <Modal
        show={showModal}
        fullscreen
        onHide={() => {
          setShowModal(false);
          setTimeout(() => {
            navigate(-1);
          }, 500);
        }}
      >
        <Modal.Header closeButton>
          <Text fontWeight="bold" fontSize="20px">
            {inspectionForm.getValues("title")}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <PDFDownloadLink
            document={
              <ReportPDF
                inspectionReport={inspectionForm}
                businessName={businessName}
                companyLogo={logoPath}
              />
            }
            fileName={inspectionForm.getValues("title")}
          >
            {({blob, url, loading, error}) => (
              <Button>
                {loading ? "Loading document..." : "Download Report!"}
              </Button>
            )}
          </PDFDownloadLink>

          <ReportPDFPreview inspectionReport={inspectionForm} />
          
        </Modal.Body>
      </Modal>
    );
  
}
