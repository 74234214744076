import { AxiosError } from "axios";
import axiosInstance from "./axiosInstance";

export type TeamMember = {
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  email: string;
};

declare module Employee {
  interface IEmployee {
    id: number;
    phone: string;
    email: string;
    preferredTitle: string;
    position: string;
    startDate: string;
    endDate: string;
    registered: boolean;
    access: "ROLE_EMPLOYEE" | "ROLE_ADMIN" | "ROLE_EMPLOYER" | "ROLE_CLIENT";
    fullyTrained: boolean;
    profilePicture?: string;
    firstName: string;
    lastName: string;
    abn: string;
    tfn: string;
    contractor: boolean;
    contactNumber: string;
    address: string;
    dateOfBirth: string;
    contractorCompanyName?: string;
    contractorName?: string;
    contractorContactNumber?: string;
    contractorEmail?: string;
    contractorAbn?: string;
    contractorAcn?: string;
    contractorCompanyAddress?: string;
    contractorGstRegistered?: boolean;
  }
}

module Employee {
  export function FetchEmployees(
    success: (data: Array<IEmployee>) => void,
    error: (error: AxiosError) => void
  ) {
    return axiosInstance
      .get("/comply-tsfm/api/employee")
      .then(({ data }) => success(data))
      .catch(error);
  }
}
export default Employee;
