import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  MdComment,
  MdDelete,
  MdEdit,
  MdLink,
  MdOutlineArrowBack,
  MdOutlineCheck,
  MdOutlinePermMedia,
  MdPersonAddAlt1,
  MdSend,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TextInput, { TextInputWithRef } from "../../components/input/input";
import Text, { TextWithRef } from "../../components/text/text";
import useOutsideAlerter from "../../hooks/outside-detector";
import "./issues.scss";
import Select from "react-select";
import SelectTheme from "../../scss/select.theme";
import moment from "moment";
// import ActionItem from "./components/action-item";
import { ActionItemProps } from "../../api/actions";
// import AttachmentModal from "./modal/attachment-modal";
import ConversationItem from "../../components/conversation-item/conversation-item";
import AttachmentModal from "../../components/attachment-modal/attachment-modal";
import DeleteModal from "../../components/delete-modal/delete-action";
import { AddIcon } from "../../components/icons/icons";
import { IssueType } from "../../api/issues";

export default function IssueDetails({}) {
  const activityForm = useForm({
    defaultValues: {
      message: "",
      attachments: [],
    },
  });
  const navigate = useNavigate();
  const [titleInputHeight, setTitleInputHeight] = useState(0);
  const [isTitleInputEnabled, setIsTitleInputEnabled] = useState(false);
  const titleTextRef = useRef<HTMLDivElement>(null);
  const titleTextAreaRef = useRef<HTMLInputElement>(null);
  const { control, watch } = useForm({
    defaultValues: {
      title: "sdfsdfssd",
    },
  });

  const statusOps = [
    {
      label: "Open",
      value: "OPEN",
    },
    {
      label: "Resolved",
      value: "RESOLVED",
    },
  ];

  const [activity, setActivity] = useState<Array<ActionItemProps>>([
    {
      text: "Saoud Nazir created the action",
      timestamp: moment().unix(),
      type: "MESSAGE" as any,

      createdBy: {
        name: "Saoud Nazir",
        id: 1,
      },
    },
    {
      text: "Saoud Nazir created the action",
      timestamp: moment().unix(),
      type: "ACTION" as any,

      createdBy: {
        name: "Saoud Nazir",
        id: 1,
      },
    },
    {
      text: "Saoud Nazir created the action",
      timestamp: moment().unix(),
      type: "IMAGE" as any,

      createdBy: {
        name: "Saoud Nazir",
        id: 1,
      },
    },

    {
      text: "Saoud Nazir created the action",
      timestamp: moment().unix(),
      type: "PDF" as any,

      createdBy: {
        name: "Saoud Nazir",
        id: 1,
      },
      media: {
        url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        fileName: "Dummy.pdf",
      },
    },
  ]);

  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [openMoveCompleteModal, setOpenMoveCompleteModal] = useState(false);
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false);

  const detailsForm = useForm<IssueType>({
    defaultValues: {},
  });
  return (
    <Modal show={true} fullscreen>
      <AttachmentModal
        form={activityForm}
        centered
        show={openAttachmentModal}
        onHide={() => setOpenAttachmentModal(false)}
      />

      <DeleteModal
        show={openDeleteActionModal}
        centered
        onHide={() => setOpenDeleteActionModal(false)}
        actionIds={[1]}
      />
      <Modal.Header className="p-0">
        <div
          className="fs-5 action-details_header-btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <MdOutlineArrowBack size={20} /> Back
        </div>

        <div
          className="fs-5 action-details_header-btn text-muted hover"
          onClick={() => {
            setOpenDeleteActionModal(true);
          }}
        >
          <MdDelete /> Delete
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Container
          fluid
          className="h-100 border-start border-end"
          style={{ maxWidth: "75rem" }}
        >
          <Row className="h-100 flex-row-md-reverse">
            <Col
              md="8"
              className="border-end h-100 p-0"
              style={{
                backgroundColor: "var(--bs-gray-100)",
              }}
            >
              <div style={{ height: "89%" }} className="p-2 overflow-auto">
                {activity.map((item, i) => (
                  <div className="mb-4" key={i}>
                    <ConversationItem {...item} />
                  </div>
                ))}
              </div>
              <Stack
                className="border-top p-2 justify-content-between"
                style={{
                  minHeight: "11%",
                  backgroundColor: "var(--bs-gray-200)",
                }}
              >
                <Stack direction="horizontal">
                  <Button className="border d-flex align-items-center mx-1">
                    <MdPersonAddAlt1 size={18} className="me-1" /> Notify
                    someone
                  </Button>
                  <Button
                    variant="light"
                    className="border text-primary d-flex align-items-center mx-1"
                    onClick={() => {
                      setOpenMoveCompleteModal(true);
                    }}
                  >
                    <MdComment size={18} className="me-1" /> What needs to be
                    done?
                  </Button>
                  <Button
                    variant="light"
                    className="border text-primary d-flex align-items-center mx-1"
                    onClick={() => {
                      setOpenMoveCompleteModal(true);
                    }}
                  >
                    <MdComment size={18} className="me-1" /> What caused it?
                  </Button>
                  <Button
                    variant="light"
                    className="border text-primary d-flex align-items-center mx-1"
                    onClick={() => {
                      setOpenMoveCompleteModal(true);
                    }}
                  >
                    <AddIcon size={18} className="me-1" /> Start inspection
                  </Button>
                </Stack>
                <Stack direction="horizontal">
                  <div
                    onClick={() => {
                      setOpenAttachmentModal(true);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <MdOutlinePermMedia size={20} />
                  </div>
                  <Controller
                    control={activityForm.control}
                    name="message"
                    render={({ field }) => (
                      <TextInputWithRef
                        refWrapperClassName="w-100 mx-2"
                        className="fs-5"
                        placeholder="Add an update...."
                        {...field}
                      />
                    )}
                  />

                  <div>
                    <MdSend
                      size={20}
                      style={{
                        color: activityForm.watch("message")
                          ? "var(--bs-primary)"
                          : "var(--bs-gray-700)",
                      }}
                    />
                  </div>
                </Stack>
              </Stack>
            </Col>
            <Col md="4" className="h-100 ">
              <div className="p-4">
                <Stack direction="horizontal">
                  {isTitleInputEnabled ? (
                    <Controller
                      control={control}
                      name="title"
                      render={({
                        field: { onBlur, onChange, name, value },
                      }) => (
                        <TextInputWithRef
                          ref={titleTextAreaRef}
                          as="textarea"
                          style={{
                            height: titleInputHeight,
                          }}
                          className="border fs-3 fw-bold d-inline-block action-details_title_input"
                          onInput={(e: any) => {
                            setTitleInputHeight(e.currentTarget.scrollHeight);
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          name={name}
                          value={value}
                        />
                      )}
                    />
                  ) : (
                    <div
                      className="fs-3 fw-bold d-inline-block action-details_title_input"
                      onClick={() => {
                        setIsTitleInputEnabled(true);
                      }}
                    >
                      {watch("title")}
                    </div>
                  )}

                  <MdEdit
                    size={20}
                    style={{ color: "var(--bs-gray-600)" }}
                    className="ms-2"
                  />
                </Stack>
                <Stack direction="horizontal" className="mt-3">
                  {isTitleInputEnabled ? (
                    <Controller
                      control={control}
                      name="title"
                      render={({
                        field: { onBlur, onChange, name, value },
                      }) => (
                        <TextInputWithRef
                          ref={titleTextAreaRef}
                          as="textarea"
                          style={{
                            height: titleInputHeight,
                          }}
                          className="border fs-4 fw-normal d-inline-block action-details_title_input"
                          onInput={(e: any) => {
                            setTitleInputHeight(e.currentTarget.scrollHeight);
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          name={name}
                          value={value}
                        />
                      )}
                    />
                  ) : (
                    <div
                      className="fs-4 fw-normal d-inline-block action-details_title_input"
                      onClick={() => {
                        setIsTitleInputEnabled(true);
                      }}
                    >
                      {watch("title")}
                    </div>
                  )}

                  <MdEdit
                    size={20}
                    style={{ color: "var(--bs-gray-600)" }}
                    className="ms-2"
                  />
                </Stack>

                <Stack direction="vertical" className="mt-3">
                  <Text variant="light">Status</Text>
                  <Row>
                    <Col md="8" xs="12">
                      <Select
                        styles={SelectTheme}
                        options={statusOps}
                        className="w-100"
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <Button className="w-100">Resolve</Button>
                    </Col>
                  </Row>
                  <Stack direction="horizontal"></Stack>
                </Stack>
                <Stack direction="vertical" className="mt-5">
                  <Text variant="light">Details</Text>
                  <Select
                    styles={SelectTheme}
                    options={statusOps}
                    className="w-100 mt-2"
                    placeholder="Add Category"
                  />
                  <Select
                    styles={SelectTheme}
                    options={statusOps}
                    className="w-100 mt-2"
                    placeholder="Add site"
                  />
                  <Select
                    styles={SelectTheme}
                    options={statusOps}
                    className="w-100 mt-2"
                    placeholder="Assignee"
                  />
                  <Select
                    styles={SelectTheme}
                    options={statusOps}
                    className="w-100 mt-2"
                    placeholder="Priority"
                  />
                  <Select
                    styles={SelectTheme}
                    options={statusOps}
                    className="w-100 mt-2"
                  />
                  <Text variant="light" className="mt-4">
                    Created by Saoud Nazir at 27 Jul 2022 4:26 PM
                  </Text>
                </Stack>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
