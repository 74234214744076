import {
  Button,
  Col,
  Collapse,
  Container,
  Offcanvas,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from "react-bootstrap";
import { FiUserCheck } from "react-icons/fi";
import {
  MdCheckCircle,
  MdCheckCircleOutline,
  MdDeleteOutline,
  MdOutlineArrowBack,
} from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp, IoMdCloseCircle } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import Text from "../../../components/text/text";
import DeleteAction from "../../../components/delete-modal/delete-action";
import { useState } from "react";
import "./../actions.scss";
interface BottomActionBarProps {
  selections: Array<number>;
}
export default function BottomActionBar({ selections }: BottomActionBarProps) {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const isMediumDevice = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openStatusCanvas, setOpenStatusCanvas] = useState(false);
  if (isMediumDevice) {
    return (
      <div
        className={`position-absolute bg-white`}
        style={{
          bottom: 0,

          minHeight: "50px",
          left: !isMediumDevice ? "13.75rem" : "0rem",
          right: 0,
        }}
      >
        <DeleteAction
          show={openDeleteModal}
          centered
          actionIds={[5, 12]}
          onHide={() => {
            setOpenDeleteModal(false);
          }}
        />
        <div
          onClick={() => setOpenCollapse(!openCollapse)}
          className={`d-flex align-items-center fs-5 border-bottom border-top px-5 ${
            openCollapse ? "text-primary bg-white" : "text-white bg-primary"
          }`}
          style={{ height: "50px", cursor: "pointer", lineHeight: "50px" }}
        >
          {openCollapse ? (
            <IoIosArrowDown size={25} className="me-2" />
          ) : (
            <IoIosArrowUp size={25} className="me-2" />
          )}{" "}
          {selections.length} actions selected
        </div>
        <Collapse in={openCollapse}>
          <Stack>
            <Button
              variant="light"
              className="d-flex align-items-center border-bottom"
              onClick={() => {
                setOpenStatusCanvas(!openStatusCanvas);
              }}
            >
              <MdCheckCircleOutline className="me-1" size={18} />
              Change Status
            </Button>
            <Button
              variant="light"
              className="d-flex align-items-center border-bottom"
            >
              <FiUserCheck className="me-1" size={18} /> Assign to
            </Button>
            <Button
              variant="light"
              onClick={() => setOpenDeleteModal(true)}
              className="d-flex align-items-center"
            >
              <MdDeleteOutline className="me-1" size={18} /> Delete
            </Button>
          </Stack>
        </Collapse>
        <Offcanvas
          show={openStatusCanvas}
          onHide={() => setOpenStatusCanvas(false)}
          responsive="sm"
        >
          <Offcanvas.Header className="border-bottom">
            <Offcanvas.Title className="d-flex align-items-center">
              <span
                className="me-2 animated-btn p-1"
                onClick={() => {
                  setOpenStatusCanvas(false);
                }}
              >
                <MdOutlineArrowBack size={20} />
              </span>{" "}
              Change Status
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <div className="canvas-status-option">To Do</div>
            <div className="canvas-status-option">In Progress</div>
            <div className="canvas-status-option">Complete</div>
            <div className="canvas-status-option">Can't do</div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  } else {
    return (
      <div
        className="position-absolute bg-primary"
        style={{
          bottom: 0,

          height: "65px",
          left: !isMediumDevice ? "13.75rem" : "0rem",
          right: 0,
        }}
      >
        <DeleteAction
          show={openDeleteModal}
          centered
          actionIds={[5, 12]}
          onHide={() => {
            setOpenDeleteModal(false);
          }}
        />
        <Container fluid className="h-100">
          <Row className="align-items-center h-100">
            <Col xs="auto">
              <Text color="white" fontSize="16px">
                <MdCheckCircle size={25} className="me-2" /> {selections.length}{" "}
                actions selected
              </Text>
            </Col>
            <Col className="d-flex justify-content-end">
              <OverlayTrigger
                placement="top"
                trigger="click"
                rootClose
                overlay={
                  <Popover className="qs-dropdown">
                    <div>To Do</div>
                    <div>In Progress</div>
                    <div>Complete</div>
                    <div>Can't do</div>
                  </Popover>
                }
              >
                <Button variant="light" className="d-flex align-items-center">
                  <MdCheckCircleOutline className="me-1" size={18} />
                  Change Status
                </Button>
              </OverlayTrigger>
              <Button variant="light" className="mx-3">
                <FiUserCheck className="me-1" size={18} /> Assign to
              </Button>
              <Button variant="light" onClick={() => setOpenDeleteModal(true)}>
                <MdDeleteOutline className="me-1" size={18} /> Delete
              </Button>
              <Button>
                <IoMdCloseCircle size={20} color="white" />
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
