import { Button, Form, Modal, Stack } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

export default function CreateAction({}) {
  const { control, watch } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
  });
  return (
    <Modal show={false} centered size="lg" contentClassName="p-3">
      <Controller
        control={control}
        name="title"
        render={({ field }) => (
          <Form.Control
            className="fs-3"
            placeholder="Title of action"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <Form.Control
            as="textarea"
            className="fs-5 mt-3"
            placeholder="Description"
            {...field}
          />
        )}
      />

      <Stack direction="horizontal" className="justify-content-end mt-3">
        <Button
          size="lg"
          variant="link"
          className="text-decoration-none border"
        >
          Cancel
        </Button>
        <Button
          size="lg"
          className="ms-3"
          disabled={watch("title").length === 0}
        >
          Create
        </Button>
      </Stack>
    </Modal>
  );
}
