import { useState } from "react";
import DatePicker from "react-datepicker";
import { TextInputWithRef, TextInputWithRefProps } from "./input";
interface DateInputProps {
  defaultDate?: Date;
  inputProps?: TextInputWithRefProps;
  onChangeDate?: (date: number) => void;
}
export default function DateInput({
  defaultDate,
  inputProps,
  onChangeDate,
}: DateInputProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    defaultDate
  );
  const [selectedDateTimestamp, setSelectedDateTimestamp] = useState<number>();

  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => {
        setSelectedDate(date);
        if (date) {
          setSelectedDateTimestamp(date.getTime());
          onChangeDate && onChangeDate(date.getTime());
        }
      }}
      customInput={<TextInputWithRef {...inputProps} />}
      showTimeSelect
      dateFormat="dd/MM/yyyy h:mm aa"
      className="qs-calendar"
      wrapperClassName="qs-calendar-wrapper"
      popperPlacement="bottom"
    />
  );
}
