// import axios from "axios";
// const axiosInstance = axios.create({
//   baseURL: "http://localhost:8080",
// });

// export default axiosInstance;

import axiosInstance from "axios";
// import { BASE_URL } from "../constants";
axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    // const dataJSON = JSON.parse(data);
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["Content-Type"] = "application/json";
    }
  }

  return config;
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   function (error) {}
// );

export default axiosInstance;
