import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import SideNavBar from "./components/side-nav-bar/side-nav-bar";
import Dashboard from "./pages/dashboard/dashboard";
import {useMediaQuery} from "react-responsive";
import Notifications from "./pages/notifications/notifications";
import Inspections from "./pages/inspections/inspections";
import Schedule from "./pages/schedule/schedule";
import ScheduleForm from "./pages/schedule/schedule-form";
import Actions from "./pages/actions/actions";
import ActionDetails from "./pages/actions/action-details";
import {Fragment} from "react";
import Issues from "./pages/issues/issues";
import IssueDetails from "./pages/issues/issue-details";
import IssueCategories from "./pages/issue-categories/issue-categories";
import EditCategories from "./pages/issue-categories/edit-categories";
import EditSingleCategory from "./pages/issue-categories/edit-single-category";
import TemplateEditor from "./pages/templates/create/template-editor";
import Template from "./pages/templates/template";
import RenderedTemplate from "./pages/templates/rendered-template";
import Header from "./components/header/header";
import Auth from "./pages/authenticator/authenticator";
import {BusinessProfileProvider} from "./hooks/business";
import {UserProfileProvider} from "./hooks/user";
import {NotificationProvider} from "./hooks/notification";
import InspectionReport from "./pages/report/report";

export default function Router() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/app" element={<Auth />} />
        <Route path="/report/:id" element={<InspectionReport />} />

        <Route
          path="/app/*"
          element={
            <UserProfileProvider>
              <BusinessProfileProvider>
                <AuthenticatedApp />
              </BusinessProfileProvider>
            </UserProfileProvider>
          }
        />
      </Routes>
    </Fragment>
  );
}

function AuthenticatedApp({}) {
  const isMediumDevice = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const location = useLocation();
  let state = location.state as {backgroundLocation?: Location};
  return (
    <div>
      <Header />
      <NotificationProvider>
        <div
          className="main-container"
          style={{
            marginTop: 67,
          }}
        >
          {!isMediumDevice && (
            <div className="nav-side-menu">
              <SideNavBar />
            </div>
          )}
          <div
            className="content-body"
            style={{marginLeft: !isMediumDevice ? "13.75rem" : "0rem"}}
          >
            <Routes location={state?.backgroundLocation || location}>
              {/* <Route path="*" element={<Navigate to="dashboard" replace />} /> */}

              <Route path="dashboard" element={<Dashboard />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="inspections" element={<Inspections />} />
              <Route path="schedule" element={<Schedule />} />
              <Route path="schedule/:tab" element={<Schedule />} />
              <Route
                path="schedule/inspection/:id"
                element={<ScheduleForm mode="EDIT" />}
              />
              <Route
                path="schedule/create"
                element={<ScheduleForm mode="CREATE" />}
              />
              <Route path="actions" element={<Actions />} />
              <Route path="issues" element={<Issues />} />
              <Route path="issues/categories" element={<IssueCategories />} />
              <Route
                path="issues/categories/edit"
                element={<EditCategories />}
              />
              <Route path="template/create" element={<TemplateEditor />} />
              <Route
                path="template/update/:templateId"
                element={<TemplateEditor />}
              />
              <Route path="template" element={<Template />} />
              <Route
                path="template/preview"
                element={<RenderedTemplate mode="PREVIEW" />}
              />
              <Route
                path="inspections/inspect/:id"
                element={<RenderedTemplate mode="INSPECTION" />}
              />
              <Route
                path="inspections/view/:id"
                element={<RenderedTemplate mode="VIEW" />}
              />
            </Routes>
            {state?.backgroundLocation && (
              <Routes>
                <Route path="actions/:id" element={<ActionDetails />} />

                <Route path="issues/detail/:id" element={<IssueDetails />} />
                <Route
                  path="issues/categories/edit/:id"
                  element={<EditSingleCategory />}
                />
                <Route
                  path="inspections/report/:id"
                  element={<InspectionReport />}
                />
              </Routes>
            )}
          </div>
        </div>
      </NotificationProvider>
    </div>
  );
}
